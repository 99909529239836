import type { User } from '@auth0/auth0-react'

import { LocalStorageCache } from '@auth0/auth0-react'
import auth0 from 'auth0-js'
import { jwtDecode } from 'jwt-decode'
import { v4 as uuidv4 } from 'uuid'
import { create } from 'zustand'
import { devtools, persist } from 'zustand/middleware'

import responseHandler from '@/utils/responseHandler'
import config from 'App/config'
import createSelectors from 'App/store/createSelectors'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { BASE_URL } from 'App/utils/website'
import webStorage from 'App/utils/webStorage'

type LoginProps = {
  email: string
  password: string
}

export type Auth0TokenProps = {
  access_token: string
  expires_in: number
  id_token: string
  scope?: string
  refresh_token?: string
}

interface AuthState {
  isLegacyMode: boolean
  setLegacyMode: (isLegacyMode: boolean) => void

  user: User | undefined
  setUser: (token: Auth0TokenProps) => any

  setLogout: () => void

  isLoading?: boolean
  setLegacyLogin: (data: LoginProps) => void

  profile: User | undefined
  setProfile: (prfoile: User) => void

  triggerRefreshToken: () => void
}

const useAuthStore = createSelectors(
  create<AuthState>()(
    devtools(
      persist(
        (set, get) => ({
          isLegacyMode: true,
          setLegacyMode: isLegacyMode => set({ isLegacyMode }),

          isLoading: false,
          setLegacyLogin: ({ email, password }) => {
            set({ isLoading: false })

            const secret = uuidv4()

            if (secret) webStorage.setItem(LOCAL_STORAGE_KEYS.SECRET, secret)

            const auth0Client = new auth0.WebAuth({
              domai: config.auth0.domain,
              clientID: config.auth0.clientId,
              scope: 'openid profile email offline_access',
              responseType: 'token id_token',
              redirectUri: `${BASE_URL}/auth/check-in`
            })

            const loginRequest = {
              realm: 'Username-Password-Authentication',
              email,
              password,
              state: secret
            }

            return new Promise((resolve, reject) => {
              auth0Client.login(loginRequest, (error: any, result: unknown) => {
                if (error) return reject(error)

                resolve(result)
              })
            })
          },

          profile: undefined,
          setProfile: profile => set({ profile }),

          user: undefined,
          setUser: token => {
            if (!token) return

            const cache = new LocalStorageCache()
            const key = cache.allKeys().filter(key => key.includes('offline_access'))[0]
            // @ts-expect-error
            const refreshToken = cache.get(key)?.body?.refresh_token

            if (token.id_token) {
              webStorage.setItem(LOCAL_STORAGE_KEYS.JWT, token.id_token)

              const profile = jwtDecode(token.id_token)
              if (profile) {
                webStorage.setItem(LOCAL_STORAGE_KEYS.PROFILE, profile)
                set({ profile })
              }
            }

            if (refreshToken) {
              webStorage.setItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN, refreshToken)
              webStorage.setItem(
                LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY,
                new Date(Date.now() + config.auth0.logOutAfterDays * 24 * 60 * 60 * 1000)
              )
            }

            return true
          },

          setLogout: () => {
            localStorage.clear()
            webStorage.setItem(LOCAL_STORAGE_KEYS.LOGOUT, Date.now())
          },

          triggerRefreshToken: async () => {
            if (window.location.href.includes('/activate/account')) return

            const refreshToken = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
            const refreshTokenExpiry = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN_EXPIRY)

            const hasExpired = Date.now() > new Date(refreshTokenExpiry).getTime()

            if (!refreshToken || hasExpired) {
              get().setLogout()
            }

            const url = `https://${config.auth0.domain}/oauth/token`

            const data = new URLSearchParams({
              grant_type: 'refresh_token',
              client_id: config.auth0.clientId,
              refresh_token: refreshToken
            })

            try {
              const response = await fetch(url, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded'
                },
                body: data.toString()
              })

              const result = await response.json()

              get().setUser(result)

              window.location.reload()
            } catch (error) {
              responseHandler(error, 'error')
              get().setLogout()
            }
          }
        }),
        { name: 'authStore' }
      ),
      { name: 'Auth Store' }
    )
  )
)

export default useAuthStore
