import { graphql } from '@apollo/client/react/hoc'
import { Form } from 'antd'
import { Col, Row } from 'antd-v5'

import CompanyLabel from 'App/components/Company/CompanyLabel'
import CompanyAddressSelect from 'App/components/Select/AddressSelect'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'
import BOOKING_FOR_WORKFLOWS_SLIM_QUERY from 'App/containers/booking/schema/bookingForWorkflowsSlim'
import Address from './Address'

const bookingQuery = graphql(BOOKING_FOR_WORKFLOWS_SLIM_QUERY, {
  name: 'bookingQuery',
  options: props => ({
    variables: {
      uuid: props.documentCreator.bookingUuid
    }
  })
})

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 18 }
}

const DeliveryCollection = props => {
  const { booking, documentCreator, form } = props
  const { getFieldDecorator, getFieldValue } = form

  const countryAlpha3 =
    booking.type === 'IMPORT' ? booking.portDest.countryAlpha3 : booking.portOri.countryAlpha3

  const deliveryToCompany = booking.type === 'IMPORT' ? booking.consignee : booking.shipper

  return (
    <Row>
      <Col span={8}>
        <FormItem label="Pickup From" {...formItemLayout}>
          {getFieldDecorator('data.pickupCompanyUuid', {
            initialValue:
              documentCreator.data.pickupCompany && documentCreator.data.pickupCompany.uuid,
            rules: [
              {
                required: true,
                message: 'Pickup From Company is required.'
              }
            ]
          })(
            <CompanySelect
              types={booking.type === 'IMPORT' ? ['port'] : ['depot']}
              countryAlpha3={countryAlpha3}
              name={documentCreator.data.pickupCompany && documentCreator.data.pickupCompany.name}
            />
          )}
        </FormItem>
      </Col>
      <Col span={8}>
        <FormItem label="Delivery To" {...formItemLayout}>
          <CompanyLabel company={deliveryToCompany} />
        </FormItem>
        <FormItem label="Address" {...formItemLayout}>
          {getFieldDecorator('data.deliveryAddressUuid', {
            initialValue:
              documentCreator.data.deliveryAddress && documentCreator.data.deliveryAddress.uuid,
            rules: [
              {
                required: true,
                message: 'Delivery To Address is required.'
              }
            ]
          })(
            <CompanyAddressSelect
              companyUuid={deliveryToCompany.uuid}
              type="DELIVERY"
              name={
                documentCreator.data.deliveryAddress && documentCreator.data.deliveryAddress.name
              }
            />
          )}
          {getFieldValue('data.deliveryAddressUuid') && (
            <Address addressUuid={getFieldValue('data.deliveryAddressUuid')} />
          )}
        </FormItem>
      </Col>
      {booking.details.shipmentType === 'FCL' && (
        <Col span={8}>
          <FormItem label="Drop Off" {...formItemLayout}>
            {getFieldDecorator('data.dropoffCompanyUuid', {
              initialValue:
                documentCreator.data.dropoffCompany && documentCreator.data.dropoffCompany.uuid,
              rules: [
                {
                  required: true,
                  message: 'Drop Off Company is required.'
                }
              ]
            })(
              <CompanySelect
                types={booking.type === 'IMPORT' ? ['depot'] : ['port']}
                countryAlpha3={countryAlpha3}
                name={
                  documentCreator.data.dropoffCompany && documentCreator.data.dropoffCompany.name
                }
              />
            )}
          </FormItem>
        </Col>
      )}
    </Row>
  )
}

export default bookingQuery(DeliveryCollection)
