import type { GeneratedDocumentQuery } from '@/types/graphql'
import { GeneratedDocumentStatus } from '@/types/graphql'

import { Button, Col, Flex } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'

import DeleteAction from '@/components/Booking/General/DocumentGeneratorSection/TableView/DeleteAction'
import SaveButton from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalFooter/SaveButton'
import SubmitButton from '@/components/Booking/General/DocumentGeneratorSection/TableView/EditAction/DocumentCreatorModal/ModalFooter/SubmitButton'

interface ModalFooterProps {
  generatedDocument: GeneratedDocumentQuery['generatedDocument']
  onChange?: () => void
  onClose?: () => void
}

const ModalFooter = (props: ModalFooterProps) => {
  const { generatedDocument, onChange = () => {}, onClose = () => {} } = props

  if (
    isEmpty(generatedDocument) ||
    generatedDocument.status === GeneratedDocumentStatus.Void ||
    generatedDocument.status === GeneratedDocumentStatus.Cancelled ||
    generatedDocument.status === GeneratedDocumentStatus.Submitted
  ) {
    return (
      <Flex>
        <Col span={24}>
          {/* <PopulateButton generatedDocument={generatedDocument} onChange={onChange} /> */}
          <Button onClick={onClose}>Close</Button>
        </Col>
      </Flex>
    )
  }

  if (generatedDocument.status === GeneratedDocumentStatus.Draft) {
    return (
      <Flex justify="space-between">
        <Col>
          <DeleteAction generatedDocument={generatedDocument} onDelete={onClose} />
        </Col>
        <Col style={{ textAlign: 'right' }}>
          <SaveButton generatedDocument={generatedDocument} onChange={onChange} />
          <SubmitButton generatedDocument={generatedDocument} onChange={onChange} />
        </Col>
      </Flex>
    )
  }

  return null
}

export default ModalFooter
