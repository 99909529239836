import { Component } from 'react'
import { Col, Input, Row, Typography } from 'antd-v5'

class Subject extends Component {
  render() {
    const { value, onChange } = this.props

    return (
      <Row style={{ borderBottom: '1px solid rgb(230, 230, 230)' }}>
        <Col span={2}>
          <Typography.Text
            style={{ fontSize: '12.5px', fontWeight: 500, color: ' rgb(40, 45, 50)' }}
          >
            Subject :{' '}
          </Typography.Text>
        </Col>
        <Col span={22}>
          <Input style={{ border: 'none' }} value={value} onChange={onChange} />
        </Col>
      </Row>
    )
  }
}

export default Subject
