import type { SelectProps } from 'antd-v5'
import { JobStatus } from '@/types/graphql'

import { Select } from 'antd-v5'

type JobSelectProps = SelectProps & {
  jobs: any[]
}

const JobSelect = (props: JobSelectProps) => {
  const { jobs, ...selectProps } = props
  const { onChange } = selectProps

  const changeJob = (value, option) => {
    onChange?.(value, option)
  }

  const clonedJobs = jobs?.map((job, index) => {
    return {
      key: job?.uuid || index + 1,
      ...job
    }
  })

  const nonCanceledJobs = clonedJobs?.filter(cl => cl.status !== JobStatus.Cancelled)
  const options = nonCanceledJobs?.map(job => ({
    label: `${job?.jobNo || 'N/A'}
      ${job?.details?.size ? ` - ${job?.details?.size}` : ''}
      ${job?.details?.containerType ? ` - ${job?.details?.containerType}` : ''}
      ${job?.details?.uom ? ` / ${job?.details?.uom}` : ''}`,
    value: job?.uuid
  }))

  return (
    <Select
      allowClear
      onChange={changeJob}
      showSearch
      filterOption
      placeholder="Select a job"
      optionFilterProp="children"
      getPopupContainer={trigger => trigger.parentNode}
      options={options}
      {...selectProps}
    />
  )
}

export default JobSelect
