import type { FeatureFlag } from '@/types/graphql'

import { useState } from 'react'
import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { notification, Switch } from 'antd-v5'
import { pick } from 'lodash'

import { UPDATE_FEATURE_FLAG } from '@/components/Manage/FeatureFlags/schema'
import { logger } from '@/utils/logger'

type ToggleFlagProps = {
  featureFlag: FeatureFlag
}

const ToggleFlag = (props: ToggleFlagProps) => {
  const { featureFlag } = props
  const [isEnabled, setIsEnabled] = useState(!!featureFlag.isEnabled)

  const [updateFeatureFlag, { loading: updateFeatureFlagLoading }] = useMutation(
    UPDATE_FEATURE_FLAG,
    {
      onError: error => {
        logger.error('ViewFeatureFlag FeatureFlagUpdate Mutation error', error)
        notification.error({
          message: 'Error',
          description: error.message.replace('GraphQL error: ', '')
        })
      },
      onCompleted: data => {
        if (data.updateFeatureFlag?.uuid) {
          notification.success({
            message: 'Success',
            description: 'FeatureFlag has been updated successfully.'
          })

          setIsEnabled(!!data.updateFeatureFlag?.isEnabled)
        }
      }
    }
  )

  const update = async (checked: boolean) => {
    await updateFeatureFlag({
      variables: {
        input: {
          ...pick(featureFlag, ['uuid', 'name', 'description', 'baseCompanyUuids']),
          isEnabled: checked
        }
      }
    })
  }

  return (
    <Switch
      style={{ width: 55 }}
      loading={updateFeatureFlagLoading}
      checkedChildren={<CheckOutlined />}
      unCheckedChildren={<CloseOutlined />}
      checked={isEnabled}
      onChange={update}
    />
  )
}

export default ToggleFlag
