import type { Booking, DynamicField } from '@/types/graphql'
import { gql } from '@/types'
import { DynamicFieldQuery, DynamicFieldType, JobStatus } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Col, Flex, Layout, Row, Spin } from 'antd-v5'
import isEmpty from 'lodash/isEmpty'

import DocumentGeneratorSection from '@/components/Booking/General/DocumentGeneratorSection'
import { RenderFields } from '@/components/Booking/General/FieldDisplayHelper'
import JobAddAction from '@/components/Booking/General/JobAddAction'
import Schedules from '@/components/Booking/General/Schedules'
import StatTimeline from '@/components/Booking/General/StatTimeline'
import JobsAndTrips from '@/components/Booking/JobsAndTrips'
import Section from '@/components/Booking/Section'
import FeatureGuard from '@/components/Shared/FeatureGuard'
import JobsMaps from '@/components/Transport/Components/VehicleMap/JobsMaps'
import BookingJobsTable from '@/components/Workflow/Section/Extras/BookingJobsTable'
import { Brief } from '@/components/Workflow/Styled'
import { PluginComponentLocation } from '@/plugins/options'
import PluginLoader from '@/plugins/PluginLoader'
import { useBookingStore } from '@/store/booking'

type GeneralInfoProps = {
  booking: Booking
}

export const VIEW_BOOKING_TYPE_GQL = gql(`
  query bookingTypeBookingSummary($code: String) {
    bookingType(code: $code) {
      dynamicFields {
        key
        type
        query
        control
      }
      allowedJobTypes
    }
  }
`)

const GeneralInfo = ({ booking }: GeneralInfoProps) => {
  const { t } = useTranslation()

  const hideDeleted = useBookingStore.use.hideDeletedJob()
  const setHideDeleted = useBookingStore.use.setHideDeletedJob()

  const [hasDeleted, setHasDeleted] = useState(false)
  const [jobTripUuids, setJobTripUuids] = useState([])
  const [bookingData, setBookingData] = useState(booking)
  const [dynamicFields, setDynamicFields] = useState<any[]>([])

  useEffect(() => {
    if (!booking) return
    const filteredJobs = booking.jobs?.filter(job => job?.status !== JobStatus.Cancelled)
    setHasDeleted(booking.jobs?.length !== filteredJobs?.length)
    setBookingData(hideDeleted ? { ...booking, jobs: filteredJobs } : booking)
  }, [hideDeleted, booking])

  const { loading, refetch: refetchBooking } = useQuery(VIEW_BOOKING_TYPE_GQL, {
    variables: { code: booking.type },
    skip: !booking.type,
    fetchPolicy: 'cache-first',
    onCompleted: data => setDynamicFields(data?.bookingType?.dynamicFields || [])
  })

  if (loading)
    return (
      <Flex flex={1} justify="center" align="center" style={{ height: 180 }}>
        <Spin />
      </Flex>
    )

  const compulsoryFields = [
    {
      key: 'no'
    },
    {
      key: 'billTo',
      query: DynamicFieldQuery.Company
    }
  ]

  const displayDynamicFields = compulsoryFields.concat(
    dynamicFields.filter((field: DynamicField) => field.type !== DynamicFieldType.Document)
  )

  const isDelivered = !isEmpty(booking.lStat) && booking.lStat.i === 1000
  const isImEx = booking.type === 'IMPORT' || booking.type === 'EXPORT'

  return (
    <>
      {(jobTripUuids.length || booking.stat) && (
        <Layout hasSider style={{ background: 'transparent' }}>
          <Layout.Content>
            {booking.stat && Object.keys(booking.stat).length > 0 && (
              <div className="flex items-center justify-between w-full">
                <Col xs={24} sm={12}>
                  <StatTimeline stat={booking.stat} />
                  {isImEx && isDelivered && (
                    <PluginLoader
                      moduleLocations={[PluginComponentLocation.AssetsRfc]}
                      componentProps={{
                        type: booking.type,
                        bookingNo: booking.no,
                        bookingUuid: booking.uuid,
                        containerNo: booking.jobs?.[0]?.details?.containerNo,
                        isRfcSubmitted: Boolean(booking.details._isRfcSubmitted)
                      }}
                    />
                  )}
                </Col>
                <Col xs={24} sm={8}>
                  <div className="flex flex-wrap gap-10">
                    <JobsMaps
                      showScrollZoom={false}
                      jobTripUuids={jobTripUuids}
                      showNavigationControl={true}
                    />
                  </div>
                </Col>
              </div>
            )}
          </Layout.Content>
        </Layout>
      )}

      <Section>
        <Layout hasSider>
          <Layout.Content>
            <Row gutter={[16, { xs: 20, lg: 48 }]}>
              {[0, 1, 2].map(colIndex => (
                <Col key={colIndex} xs={24} sm={12} lg={8}>
                  {displayDynamicFields
                    .slice(
                      Math.floor((colIndex * displayDynamicFields.length) / 3),
                      Math.floor(((colIndex + 1) * displayDynamicFields.length) / 3)
                    )
                    .map((field: DynamicField) => RenderFields(booking, field))}
                </Col>
              ))}
            </Row>
          </Layout.Content>
        </Layout>
      </Section>

      <Section
        title={
          <div className="flex w-full space-between">
            <div className="flex items-center gap-10">
              Jobs
              <JobAddAction
                bookingUuid={booking.uuid}
                shipmentType={booking.details.shipmentType}
              />
            </div>
            <div className="flex items-center gap-10 text-right">
              <Brief onClick={() => setHideDeleted(!hideDeleted)}>
                {hasDeleted
                  ? hideDeleted
                    ? `${t('common.showDeleted')}`
                    : `${t('common.hideDeleted')}`
                  : ''}
              </Brief>
            </div>
          </div>
        }
      >
        <JobsAndTrips booking={bookingData} hideDeleted={hideDeleted} />
        {/* <JobsDocumentCreatorOverview
          booking={bookingData}
          expandAllTrips={expandTrips}
          hideDeleted={hideDeleted}
        /> */}
      </Section>

      <BookingJobsTable
        query={{}}
        booking={bookingData}
        refetchBooking={refetchBooking}
        setJobTripUuids={setJobTripUuids}
      />

      <Section title="Schedules">
        <Schedules booking={booking} />
      </Section>

      <FeatureGuard name="DOCUMENT GENERATOR">
        <DocumentGeneratorSection bookingUuid={booking?.uuid} />
      </FeatureGuard>
    </>
  )
}

export default GeneralInfo
