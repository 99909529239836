import type {
  Address as AddressType,
  Booking,
  DynamicField,
  Job,
  Maybe,
  Trip
} from '@/types/graphql'
import { DynamicFieldQuery, DynamicFieldType } from '@/types/graphql'

import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { Row, Typography } from 'antd-v5'
import { get, startCase } from 'lodash'
import moment from 'moment'

import Address from '@/components/Booking/NewBookingForm/ConfirmationSubForm/CompanyInfo/Address'
import Company from '@/components/Booking/NewBookingForm/ConfirmationSubForm/CompanyInfo/Company'
import { copyIconStyle } from '@/components/Shared/CopyIconStyle'
import { isUuid } from '@/utils/u'

const getData = (
  booking: Booking | Job | Trip | Maybe<Trip>,
  key: string,
  dynamicField: DynamicField
) => {
  if (dynamicField.type === DynamicFieldType.Date) {
    const date = get(booking?.details, key) || get(booking, key)

    if (key === 'rfcDatetime' || key === 'estmatedRfcDate') {
      return moment(date).format('DD MMM YYYY, HH:mm')
    }
    return moment(date).format('DD MMM YYYY')
  }

  if (dynamicField.query === DynamicFieldQuery.Company) {
    const value = get(booking?.details, key) || get(booking, key)
    const valueUuid = get(booking?.details, `${key}Uuid`) || get(booking, `${key}Uuid`)

    if (isUuid(value) || isUuid(valueUuid)) return <Company companyUuid={value || valueUuid} />
    if (value) return `[${value.code || ''}] ${value.name || ''}`
  }

  if (dynamicField.query === DynamicFieldQuery.Address) {
    const value = get(booking?.details, key) || get(booking, key)

    if (isUuid(value)) return <Address addressUuid={value} />

    const { name, addressStrFull } = getAddressDisplayObj(value)

    if (value) return `[${name}] ${addressStrFull}`
  }

  if (
    [DynamicFieldQuery.Place, DynamicFieldQuery.Port].includes(
      dynamicField.query as DynamicFieldQuery
    )
  ) {
    const place = get(booking?.details, key) || get(booking, key)
    return `${place?.name || ''}`
  }

  if (dynamicField.type === DynamicFieldType.Document) {
    const value: File[] = get(booking?.details, key) || get(booking, key) || []
    return value.map((file: File) => file.name).join(', ')
  }

  if (Array.isArray(booking?.[key])) return (booking[key] || []).join(', ')

  return booking?.[key] || booking?.details?.[key]
}

export const RenderFields = (
  booking: Booking | Job | Trip | Maybe<Trip>,
  dynamicField: DynamicField,
  keyPrefix: string = ''
) => {
  const data = getData(booking, dynamicField.key || '', dynamicField)

  return (
    <Row key={`${keyPrefix}-${booking?.uuid}-${dynamicField.key}-${data}`}>
      <Typography.Text className="text-black text-sm">
        {startCase(dynamicField.key || '')} {`: `}
      </Typography.Text>

      <Typography.Text
        type="secondary"
        className="text-sm"
        id={dynamicField.key as string}
        copyable={
          Boolean(data) && dynamicField.type !== DynamicFieldType.Boolean
            ? {
                text: String(data),
                icon: [copyIconStyle(CopyOutlined), copyIconStyle(CheckOutlined)]
              }
            : false
        }
      >
        {dynamicField.type !== DynamicFieldType.Boolean ? data : data ? 'Yes' : 'No'}
      </Typography.Text>
    </Row>
  )
}

const getAddressDisplayObj = (address: AddressType) => {
  const {
    address1,
    address2,
    address3,
    address4,
    district,
    postCode,
    name,
    areaCode,
    zone,
    phone
  } = address

  const addressStr = [address1, address2, address3, address4, district, postCode]
    .filter(Boolean)
    .join(', ')

  const addressStrFull = `${addressStr}(${areaCode}) [${zone}]`

  return {
    name,
    addressStr,
    addressStrFull,
    areaCode,
    zone,
    phone
  }
}

export const DisplayAddress = (address: Maybe<AddressType | string> | undefined) => {
  if (!address) return <></>
  if (isUuid(address)) return <Address addressUuid={address as string} />
  if (typeof address === 'string') return <>{address}</>

  const { name, addressStrFull, phone } = getAddressDisplayObj(address)

  return (
    <>
      <Typography.Text strong>{name}</Typography.Text>
      <br />
      <Typography.Text>
        {addressStrFull}
        <br />
        {phone && `Contact: ${phone}`}
      </Typography.Text>
    </>
  )
}
