import { Component } from 'react'
import { Col, Flex, Tooltip } from 'antd-v5'
import moment from 'moment'
import PropTypes from 'prop-types'

import Container, {
  CreatedDate,
  RequestDesc,
  RequestType,
  Status
} from '@/components/Workflow/Request/RequestItem/Container'
import RequestItemResultModal from '@/components/Workflow/Request/RequestItemResultModal'
import { requestTypes } from '@/utils/labelMap'

class RequestItem extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isModalVisible: false
    }
  }

  handleClick = () => {
    this.setState({
      isModalVisible: true
    })
  }

  handleClose = () => {
    this.setState({
      isModalVisible: false
    })
  }

  render() {
    const { request } = this.props
    const requestType = requestTypes[request.type]

    return (
      <>
        <Container onClick={this.handleClick}>
          <RequestType>{(requestType && requestType.text) || request.type}</RequestType>
          <RequestDesc>
            <Flex justify="space-around" align="center">
              <Col span={14}>
                <Status>{request.status}</Status>
              </Col>
              <Col span={10}>
                <CreatedDate>
                  <Tooltip
                    title={((<span>{request.createdAt}</span>), (<span>{request.createdAt}</span>))}
                  >
                    <span>{moment(request.createdAt).fromNow()}</span>
                  </Tooltip>
                </CreatedDate>
              </Col>
            </Flex>
          </RequestDesc>
        </Container>
        <RequestItemResultModal
          request={request}
          visible={this.state.isModalVisible}
          onClose={this.handleClose}
        />
      </>
    )
  }
}

RequestItem.propTypes = {
  request: PropTypes.object
}

export default RequestItem
