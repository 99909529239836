import { gql } from '@/types'

export default gql(`
  query address3($uuid: UUID!) {
    address(uuid: $uuid) {
      uuid
      name
      type
      address1
      address2
      address3
      address4
      postCode
      areaCode
      zone
    }
  }
`)
