import type { InputProps } from 'antd-v5'
import type { ChangeEvent } from 'react'

import { Input } from 'antd-v5'
import toUpper from 'lodash/toUpper'

const UppercaseOnlyTextField = ({ value, onChange, placeholder, ...inputProps }: InputProps) => {
  const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
    onChange?.(toUpper(e.target.value.trim()) as any)
  }

  return (
    <Input
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      autoComplete="off"
      {...inputProps}
    />
  )
}

export default UppercaseOnlyTextField
