import {
  CheckCircleOutlined,
  ClockCircleOutlined,
  ExclamationCircleOutlined
} from '@ant-design/icons'
import { List, Tooltip } from 'antd-v5'

const getVoucherIcon = voucherResult => {
  const errorMessage = voucherResult.result?.error?.message?.replace('GraphQL error: ', '')
  const status = voucherResult.result.status

  return (
    <Tooltip title={errorMessage}>
      {status === 'pending' ? (
        <ClockCircleOutlined style={{ color: 'grey' }} />
      ) : status === 'fail' ? (
        <ExclamationCircleOutlined style={{ color: 'red' }} />
      ) : (
        <CheckCircleOutlined style={{ color: 'green' }} />
      )}
    </Tooltip>
  )
}

const BulkApproveList = props => {
  const { voucherResults } = props

  return (
    <List
      size="small"
      bordered
      dataSource={voucherResults}
      renderItem={item => (
        <List.Item>
          <List.Item.Meta title={item.voucherNumber || item.uuid.substring(0, 8)} />
          {getVoucherIcon(item)}
        </List.Item>
      )}
    />
  )
}

export default BulkApproveList
