import { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { DatePicker, Form } from 'antd'
import { Button, Col, Input, Row } from 'antd-v5'
import moment from 'moment'
import { compose } from 'recompose'
import styled from 'styled-components'

import { ADD_LEG } from 'App/graphql/booking'
import EmptyAnchor from '../../EmptyAnchor'

const withGraphqlAddLeg = graphql(ADD_LEG, {
  props: ({ mutate }) => ({
    addLeg: input =>
      mutate({
        variables: {
          input
        }
      })
  })
})

const enhance = compose(withGraphqlAddLeg, Form.create())

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 6 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  style: {
    marginBottom: 0
  }
}

const StyledAddLegDiv = styled.div`
  text-align: right;
  padding: 10px 0;
`

const StyledButtonDiv = styled.div`
  text-align: right;

  button {
    margin-left: 10px;
  }
`

const StyledFormDiv = styled.div`
  background-color: lightyellow;
  border-color: orange;
  border: 1px solid yellow;
  padding: 10px 0;
`

const NewLegContent = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      status: 'none' // none, adding, loading
    }
  }

  handleAdd = () => {
    this.setState({
      status: 'adding'
    })
  }

  handleCancel = () => {
    this.setState({
      status: 'none'
    })
  }

  handleSubmit = () => {
    const { form, addLeg, onChange = () => {} } = this.props
    form.validateFields(async (err, values) => {
      if (!err) {
        try {
          this.setState({
            status: 'loading'
          })

          await addLeg(values)

          await onChange()

          form.resetFields()
          this.setState({
            status: 'none'
          })
        } catch (error) {
          console.log(error)
          this.setState({
            status: 'adding'
          })
        }
      }
    })
  }

  render() {
    const {
      form: { getFieldDecorator },
      tripUuid
    } = this.props

    return (
      <div>
        {this.state.status === 'none' && (
          <StyledAddLegDiv onClick={this.handleAdd}>
            <EmptyAnchor>Add a new leg</EmptyAnchor>
          </StyledAddLegDiv>
        )}
        {['adding', 'loading'].includes(this.state.status) && (
          <StyledFormDiv>
            <Form>
              {getFieldDecorator('tripUuid', {
                initialValue: tripUuid
              })(<Input type="hidden" />)}
              <Row>
                <Col span={12}>
                  <Form.Item label="Start date" {...formItemLayout}>
                    {getFieldDecorator('start', {
                      initialValue: moment()
                    })(
                      <DatePicker
                        placeholder="Start"
                        showTime={{ format: 'h:mm:ss a', use12Hours: true }}
                        format="YYYY-MM-DD h:mm:ss a"
                      />
                    )}
                  </Form.Item>
                  <Form.Item label="Type" {...formItemLayout}>
                    {getFieldDecorator('type', {
                      initialValue: ''
                    })(<Input placeholder="Type" />)}
                  </Form.Item>
                </Col>
                <Col span={12}>
                  <Form.Item label="Driver ID" {...formItemLayout}>
                    {getFieldDecorator('driverId', {
                      initialValue: ''
                    })(<Input placeholder="Driver ID" />)}
                  </Form.Item>
                  <Form.Item label="PM ID" {...formItemLayout}>
                    {getFieldDecorator('pmId', {
                      initialValue: ''
                    })(<Input placeholder="PM ID" />)}
                  </Form.Item>
                  <Form.Item label="Trailer ID" {...formItemLayout}>
                    {getFieldDecorator('trailerId', {
                      initialValue: ''
                    })(<Input placeholder="Trailer ID" />)}
                  </Form.Item>
                  <Row>
                    <Col span={16} push={6}>
                      <StyledButtonDiv>
                        <Button onClick={this.handleCancel}>Cancel</Button>
                        <Button
                          type="primary"
                          loading={this.state.status === 'loading'}
                          onClick={this.handleSubmit}
                        >
                          Add
                        </Button>
                      </StyledButtonDiv>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Form>
          </StyledFormDiv>
        )}
      </div>
    )
  }
}

export default enhance(NewLegContent)
