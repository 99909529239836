import { Col } from 'antd-v5'
import styled from 'styled-components'

export const EditableView = styled.div`
  .ant-input {
    height: 32px;
    line-height: 32px;
    padding: 4px 9px;
  }

  .ant-select-selection--multiple {
    min-height: 34px;
    padding-top: 1px;
    padding-bottom: 2px;

    .ant-select-selection__rendered {
      line-height: 32px;
    }
  }
`

export const ToggleActions = styled(Col)`
  text-align: right;
  padding-top: ${props => (props.top ? props.top : '0')};
  padding-bottom: 5px;

  .anticon-edit {
    font-size: 16px;
    vertical-align: -0.225em;
    cursor: pointer;

    &:hover {
      color: #1890ff;
    }
  }

  .ant-btn {
    height: 28px;
    font-size: 13.222px;
    font-weight: 500;
    line-height: normal;
    margin-left: 10px;
  }
`
