import type { YardsSelector1Query } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'
import { YardStatus } from '@/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import QuickCreateYard from '@/components/Manage/Yards/QuickCreateYard'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const YARDS_SELECTOR_QUERY = gql(`
  query yardsSelector1($input: YardQueryInput!) {
    yards(input: $input) {
      rows {
        uuid
        code
        name
      }
    }
  }
`)

type YardSelectorProps = SelectProps & {
  quickCreate?: boolean
}

const YardSelector = (props: YardSelectorProps) => {
  const { quickCreate = false, ...selectProps } = props
  const { mode, value } = selectProps

  const [yards, setYards] = useState<
    NonNullable<NonNullable<YardsSelector1Query>['yards']>['rows']
  >([])
  const [searchInput, setSearchInput] = useState('')

  const { data, error, loading, refetch } = useQuery(YARDS_SELECTOR_QUERY, {
    variables: {
      input: {
        q: (!mode && value) || searchInput || '',
        statuses: [YardStatus.Active],
        limit: 20
      }
    },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => {
    if (setYards) {
      setYards(data?.yards?.rows)
    }
  }, [data])

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        console.error(error)
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('YardSelector error', error)
    responseHandler(error, 'error')
  }

  const options = yards?.map(t => ({
    label: `${t?.code} - ${t?.name}`,
    value: t?.uuid
  }))

  return (
    <>
      <Select
        {...selectProps}
        allowClear
        filterOption={false}
        loading={loading}
        notFoundContent={loading ? 'Searching...' : 'Not found.'}
        onSearch={handleSearch}
        placeholder="Select a yard..."
        showSearch
        options={options}
      />
      {quickCreate && <QuickCreateYard />}
    </>
  )
}

export default memo(YardSelector)
