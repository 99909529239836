import type { SelectProps } from 'antd-v5'

import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import { startCase } from 'lodash'

import { ENUM_VALUES } from './EnumGql'

type EnumSelectProps = SelectProps & {
  name: string
}

const EnumSelect = (props: EnumSelectProps) => {
  const { name, ...selectProps } = props
  const { style = {}, placeholder = 'Please select' } = selectProps
  const { data, loading, error } = useQuery(ENUM_VALUES, { variables: { name } })

  const combinedStyle = {
    width: '100%',
    ...style
  }

  if (error) {
    return (
      <Select disabled placeholder="Error while loading. Please try again" style={combinedStyle} />
    )
  }

  const enums = data?.__type?.enumValues?.map((t: { name: string }) => t.name)

  const options = enums?.map(t => ({
    label: startCase(t),
    value: t
  }))

  return (
    <Select
      {...selectProps}
      loading={loading}
      allowClear
      placeholder={placeholder}
      style={combinedStyle}
      options={options}
    />
  )
}

export default EnumSelect
