import { gql } from '@/types'

import { useQuery } from '@apollo/client'
import { Skeleton } from 'antd-v5'

import TableViewData from '@/components/Manage/Companies/TableView/Data'
import { TableViewWrapper } from '@/components/Manage/Styled'
import { ComponentErrorFallback } from '@/ErrorFallback'

const TableView = props => {
  const { searchKeyword, filters, updateFilters, currentSize, updatePageSize } = props

  const { loading, error, data } = useQuery(companiesQuery, {
    variables: { query: searchKeyword, offset: currentSize * 20, limit: 20, ...filters },
    fetchPolicy: 'cache-and-network'
  })

  if (error) return <ComponentErrorFallback />

  const companies = data?.companies?.rows || []
  const pagination = data?.companies?.pageInfo || {}

  return (
    <TableViewWrapper>
      <Skeleton active loading={loading} paragraph={{ rows: 5 }}>
        <TableViewData
          items={companies}
          filters={filters}
          updateFilters={updateFilters}
          pagination={{
            total: pagination?.count,
            limit: pagination?.limit,
            offset: pagination?.offset,
            onSizeChange: size => updatePageSize(size)
          }}
        />
      </Skeleton>
    </TableViewWrapper>
  )
}

const companiesQuery = gql(`
  query companies10(
    $types: [CompanyType!]
    $countryAlpha3: LimitedString
    $query: String
    $limit: Int
    $offset: Int
    $statuses: [CompanyStatus!]
  ) {
    companies(
      types: $types
      countryAlpha3: $countryAlpha3
      query: $query
      limit: $limit
      offset: $offset
      statuses: $statuses
    ) {
      rows {
        uuid
        name
        accountUuid
        ownerUuid
        code
        status
        types
        debtorCode
        creditorCode
        country {
          name
          alpha3
        }
      }

      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export default TableView
