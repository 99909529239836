import { VoucherStatus } from '@/types/graphql'

import { Component } from 'react'
import { connect } from 'react-redux'
import find from 'lodash/find'
import { compose } from 'redux'

import ApprovalSelector from '@/components/ApprovalSelector'
import { voucherTypes } from '@/utils/labelMap'

class VoucherApprovals extends Component {
  render() {
    const {
      onApproveVoucher,
      selectedVoucher = {
        approvals: []
      },
      selectedVoucherTemplate
    } = this.props

    const approvals = selectedVoucherTemplate?.approvals || []

    return (
      <>
        {approvals.map(approval => {
          const mappedType = voucherTypes[approval] || { text: approval }
          const approvedApproval = find(
            selectedVoucher.approvals,
            item => item.type === approval && item.status === VoucherStatus.Approved
          )

          return (
            <ApprovalSelector
              key={approval}
              title={mappedType.text}
              approval={approval}
              mappedType={mappedType}
              onApprove={onApproveVoucher}
              approvedApproval={approvedApproval}
              submitting={this.props.submitting}
            />
          )
        })}
      </>
    )
  }
}

export default compose(
  connect(state => ({
    selectedVoucher: state.voucher.selectedVoucher,
    selectedVoucherTemplate: state.voucher.selectedVoucherTemplate
  }))
)(VoucherApprovals)
