import BookingActionButton from '@/components/Booking/BookingActionButton/BookingActionButton'

const buttons = {
  DRAFT: booking => [
    <BookingActionButton
      key="SUBMITTED"
      booking={booking}
      buttonText="Submit"
      newBookingStatus="SUBMITTED"
    />
  ],
  SUBMITTED: booking => [
    <BookingActionButton
      key="ACCEPTED"
      booking={booking}
      buttonText="Accept"
      newBookingStatus="ACCEPTED"
    />,
    <BookingActionButton
      key="REJECTED"
      type="danger"
      booking={booking}
      buttonText="Reject"
      newBookingStatus="REJECTED"
    />
  ],
  ACCEPTED: booking => [
    <BookingActionButton
      key="CLOSE_OPERATION"
      booking={booking}
      buttonText="Close Operation"
      newBookingStatus="CLOSE_OPERATION"
    />,
    <BookingActionButton
      key="CANCELLED"
      type="danger"
      booking={booking}
      buttonText="Cancel"
      newBookingStatus="CANCELLED"
    />,
    <BookingActionButton key="KIV" booking={booking} buttonText="KIV" newBookingStatus="KIV" />
  ],
  CLOSE_OPERATION: booking => [
    <BookingActionButton
      key="ACCEPTED"
      booking={booking}
      buttonText="Revert Operation Close"
      newBookingStatus="ACCEPTED"
    />,
    <BookingActionButton
      key="CLOSE_FINANCE"
      booking={booking}
      buttonText="Close Finance"
      newBookingStatus="CLOSE_FINANCE"
    />
  ],
  CLOSE_FINANCE: booking => [
    <BookingActionButton
      key="CLOSE_OPERATION"
      booking={booking}
      buttonText="Revert Finance Close"
      newBookingStatus="CLOSE_OPERATION"
    />
  ],
  CANCELLED: booking => [
    <BookingActionButton
      key="UNCANCEL"
      type="danger"
      booking={booking}
      buttonText="Uncancel"
      newBookingStatus="ACCEPTED"
    />
  ],
  KIV: booking => [
    <BookingActionButton
      key="ACCEPTED"
      booking={booking}
      buttonText="UnKIV"
      newBookingStatus="ACCEPTED"
    />
  ]
}

export default booking => {
  const func = buttons?.[booking?.status]
  return func?.(booking)
}
