import type { CollapseProps } from 'antd-v5'

import { forwardRef, memo, useCallback, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Button, Collapse, Divider, Flex } from 'antd-v5'
import pick from 'lodash/pick'
import moment from 'moment'
import { stringify } from 'query-string'

import DateRangePicker from 'App/components/Form/DateRangePicker'
import ViewerBaseCompanySelect from 'App/components/Select/ViewerBaseCompanySelect'
import { ListWrapper } from './index'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 5 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 15 }
  }
}

const SearchFiltersForm = forwardRef((props: any, ref: any) => {
  const { form, query, setRefreshCount } = props
  const { getFieldDecorator } = form

  const navigate = useNavigate()

  const handleSearch = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      const inputValue = {
        ...pick(values, ['baseCompanyUuids', 'transportSources']),
        startDate: values.dateRange?.from?.toISOString(),
        endDate: values.dateRange?.to?.toISOString()
      }
      const queryString = stringify(inputValue, { arrayFormat: 'comma' })

      navigate(`/transport/dashboard?${queryString}`)
      setRefreshCount((count: number) => count + 1)
    })
  }, [])

  const selectors = useMemo(
    () => [
      {
        key: 'baseCompanyUuids',
        label: 'Base Company',
        ctrlType: <ViewerBaseCompanySelect />,
        initialValue: query.baseCompanyUuids
      },
      {
        key: 'dateRange',
        label: 'Date Range',
        ctrlType: <DateRangePicker />,
        initialValue: {
          from: moment(query.startDate),
          to: moment(query.endDate)
        }
      }
    ],
    [query]
  )

  const items: CollapseProps['items'] = [
    {
      key: '1',
      label: 'Dashboard Search',
      children: (
        <Form>
          {selectors?.map(selector => (
            <Form.Item key={selector.key} label={selector.label} {...formItemLayout}>
              {getFieldDecorator(selector.key, {
                initialValue: selector.initialValue
              })(selector.ctrlType)}
            </Form.Item>
          ))}
          <Divider style={{ margin: '16px 0' }} />
          <Flex justify="end">
            <Button icon={<SearchOutlined />} type="primary" onClick={handleSearch}>
              Search
            </Button>
          </Flex>
        </Form>
      )
    }
  ]

  return (
    <ListWrapper>
      <Collapse items={items} defaultActiveKey={['1']} />
    </ListWrapper>
  )
})

export default Form.create()(memo(SearchFiltersForm))
