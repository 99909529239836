import type { TransportJob } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Alert, Button, Flex, Form } from 'antd-v5'

import { extractLegUpdateFormValues } from '@/components/Transport/Components/Modals/JobActivityModal/helper'
import JobContainerSealDisplay from '@/components/Transport/Components/Modals/JobActivityModal/JobContainerSealDisplay'
import LegFormDetails from '@/components/Transport/Components/Modals/JobActivityModal/leg-form-details'
import LegDetailsDisplay from '@/components/Transport/Components/Modals/JobActivityModal/LegDetailsDisplay'
import LegTimingsWrapper from '@/components/Transport/Components/Modals/JobActivityModal/LegTimingsWrapper'
import {
  UPDATE_LEG_MUTATION,
  UPDATE_LEG_TIMING_MUTATION
} from '@/components/Transport/Schemas/schema'
import { isActiveLeg } from '@/components/Transport/Utils/jobHelper'
import { logger } from '@/utils/logger'
import notify from '@/utils/notify'
import styles from './UpdateLegForm.module.css'

interface UpdateLegFormProps {
  isVDJM?: boolean
  leg: TransportJob | undefined | null
  legs: TransportJob[]
  onCloseForm?: any
  legsLoading?: boolean
  refetchLegs?: () => void
}

const UpdateLegForm = (props: UpdateLegFormProps) => {
  const { isVDJM, leg, legs, onCloseForm, legsLoading, refetchLegs } = props

  const { t } = useTranslation()
  const [form] = Form.useForm()

  const isDeleted: boolean = useMemo(() => !isActiveLeg(leg), [leg])

  const [editLegMode, setEditLegMode] = useState(
    !(isDeleted || (leg?.driverName && leg?.vehicleName))
  )
  const [formSuccessMsg, setFormSuccessMsg] = useState('')
  const [formError, setFormError] = useState('')

  const [updateLegMain, { loading }] = useMutation(UPDATE_LEG_MUTATION)
  const [updateLegTiming, { loading: updateLoading }] = useMutation(UPDATE_LEG_TIMING_MUTATION)

  const onSwitchEditMode = useCallback(() => {
    setEditLegMode((state: boolean) => !state)
    setFormSuccessMsg('')
    setFormError('')
  }, [])

  const onUpdateLeg = useCallback(async () => {
    try {
      const value = await form.validateFields()

      if (isDeleted) {
        return setFormError(`Leg ${leg?.sequence} is ${leg?.legStatus} and cannot be updated.`)
      }

      setFormSuccessMsg('Saving leg details...')
      setFormError('')

      const { updateLegInput, updateLegTimingInput } = extractLegUpdateFormValues(leg, value)

      setFormSuccessMsg('Saving leg details...')

      if (updateLegInput.legUuid && updateLegMain) {
        const res = await updateLegMain({ variables: { input: updateLegInput } })
        if (res?.data?.updateLeg?.success) {
          notify('Successfully updated leg.', 'success')
          onSwitchEditMode()

          const resUpdateLegTiming = await updateLegTiming({
            variables: { input: updateLegTimingInput }
          })
          if (resUpdateLegTiming?.data?.updateLegTiming?.success) {
            notify('Successfully update planStart.', 'success')
            refetchLegs?.()
          }
        }
      } else {
        setFormSuccessMsg('')
        notify('No leg selected for update.', 'warning')
      }
    } catch (error) {
      if (error instanceof Error) {
        notify(error.message, 'error')
        setFormError(error.message || JSON.stringify(error))
        logger.error('UpdateLegForm updateLegMain error', error)
        setFormSuccessMsg('')
      }
    }
  }, [form, leg, updateLegMain, updateLegTiming, refetchLegs])

  const setFieldsFromPreviousLeg = useCallback(
    (fields: string[]) => {
      if (!fields.length || !legs || legs?.length <= 1) return

      const currentLegIndex = legs.findIndex(l => l.uuid === leg?.uuid)

      if (currentLegIndex <= 0) return

      const previousLeg = legs[currentLegIndex - 1]

      fields.forEach((field: string) => {
        form.setFieldsValue({ [field]: previousLeg[field] })
      })
    },
    [legs, leg, form]
  )

  useEffect(() => {
    if (leg?.driverUuid || (leg?.driverName && leg?.driverCode)) return
    setFieldsFromPreviousLeg(['driverUuid', 'driverCode', 'driverName'])
  }, [leg, legs])

  useEffect(() => {
    if (isDeleted || (leg?.driverName && leg?.vehicleName)) {
      setEditLegMode(false)
    } else {
      setEditLegMode(true)
    }
  }, [leg])

  return (
    <div className={!isVDJM ? styles.container : styles.vdjmContainer}>
      <Flex gap={10} wrap="wrap">
        <Flex flex={1}>
          <JobContainerSealDisplay leg={leg} legsLoading={legsLoading} />
        </Flex>

        <Flex vertical flex={1}>
          {editLegMode ? (
            <LegFormDetails
              form={form}
              leg={leg}
              isVDJM={isVDJM}
              setFormError={setFormError}
              setFormSuccessMsg={setFormSuccessMsg}
            />
          ) : (
            <LegDetailsDisplay leg={leg} />
          )}

          <Flex vertical align="flex-end">
            {formSuccessMsg && (
              <Alert
                type="success"
                message={formSuccessMsg}
                style={{ fontSize: 12, width: '80%' }}
              />
            )}
            {formError && (
              <Alert type="error" message={formError} style={{ fontSize: 12, width: '80%' }} />
            )}
          </Flex>

          <Flex justify="flex-end" gap={10} className="mt-4">
            <Button
              id="edit-cancel-button-leg-form"
              disabled={isDeleted}
              loading={legsLoading}
              onClick={onSwitchEditMode}
            >
              {editLegMode ? 'Cancel' : 'Edit'}
              {!editLegMode && <EditOutlined />}
            </Button>

            {editLegMode && (
              <Button
                id="edit-leg-form-submit-button"
                onClick={onUpdateLeg}
                loading={loading || legsLoading || updateLoading}
              >
                {t('common.submit')} <CheckOutlined />
              </Button>
            )}
          </Flex>
        </Flex>

        <Flex flex={1} justify="center">
          <LegTimingsWrapper
            leg={leg}
            legs={legs}
            legsLoading={legsLoading}
            onCloseForm={onCloseForm}
            refetchLegs={refetchLegs}
            setFormError={setFormError}
            setFormSuccessMsg={setFormSuccessMsg}
          />
        </Flex>
      </Flex>
    </div>
  )
}

export default memo(UpdateLegForm)
