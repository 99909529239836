import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'

import { memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import { logger } from '@/utils/logger'
import webStorage from '@/utils/webStorage'

const VIEWER_BASE = gql(`
  query viewerForSelect($auth0UserId: String) {
    viewer(auth0UserId: $auth0UserId) {
      baseCompanies {
        uuid
        name
        shortCode
      }
    }
  }
`)

const ViewerBaseCompanySelect = (props: SelectProps) => {
  const { style = {}, placeholder = 'Select a base company...' } = props

  const { loading, error, data } = useQuery(VIEWER_BASE, {
    variables: {
      auth0UserId: webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)?.sub
    }
  })

  const combinedStyle = useMemo(
    () => ({
      width: '100%',
      ...style
    }),
    [style]
  )

  if (error) {
    logger.error('ViewerBaseCompanySelect Error.', error)
    return (
      <Select disabled placeholder="Error while loading. Please try again." style={combinedStyle} />
    )
  }

  const options = data?.viewer?.baseCompanies?.map(baseCompany => ({
    label: baseCompany?.name,
    value: baseCompany?.uuid
  }))

  return (
    <Select
      loading={loading}
      placeholder={placeholder}
      style={combinedStyle}
      notFoundContent="Not found."
      options={options}
      {...props}
    />
  )
}

export default memo(ViewerBaseCompanySelect)
