import { memo } from 'react'
import { useQuery } from '@apollo/client'
import { Col, Flex, Row, Skeleton, Spin, Typography } from 'antd-v5'

import { BASE_COMPANY_SETTING } from 'App/components/Select/DepartmentSelect'
import HelpIcon from 'App/components/Shared/HelpIcon'
import NoAccess from 'App/components/Shared/NoAccess'
import DailyTable from 'App/components/Transport/Dashboard/Components/DailyTable'
import MTDTable from 'App/components/Transport/Dashboard/Components/MTDTable'
import { getLastYrMtdDateRange } from 'App/components/Transport/Dashboard/helpers/mtd'
import { ListWrapper } from 'App/components/Transport/Dashboard/index'
import { REPORT_JOB_SUMMARIES_QUERY } from 'App/components/Transport/Dashboard/schema'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import { logger } from 'App/utils/logger'
import respHandler from 'App/utils/responseHandler'

const DashboardWrapper = memo((props: any) => {
  const { query } = props

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'dashboard',
    permission: 'index'
  })

  const input = {
    baseCompanyUuids: query.baseCompanyUuids,
    startDate: new Date(query.startDate),
    endDate: new Date(query.endDate),
    limit: 10000
  }

  const lastYrInput = {
    ...input,
    ...getLastYrMtdDateRange(input)
  }

  const { loading, error, data } = useQuery(REPORT_JOB_SUMMARIES_QUERY, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const {
    loading: lastYrLoading,
    error: lastYrErr,
    data: lastYrData
  } = useQuery(REPORT_JOB_SUMMARIES_QUERY, {
    variables: { input: lastYrInput },
    fetchPolicy: 'cache-and-network'
  })

  const {
    data: bcSettingsData,
    loading: bcSettingsLoading,
    error: bcSettingsError
  } = useQuery(BASE_COMPANY_SETTING, {
    variables: { key: 'transportDashboardTable' },
    fetchPolicy: 'cache-first'
  })

  const { hasPermission: hasConfidentialPermission } = usePermissionIsAllowed({
    resource: 'dashboard',
    permission: 'showFinancials'
  })

  if (error || lastYrErr) {
    logger.error('DashboardWrapper REPORT_JOB_SUMMARIES_QUERY error', error || lastYrErr)
    respHandler(error || lastYrErr, 'error')
  }

  if (
    bcSettingsError &&
    //@ts-ignore
    bcSettingsError?.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 404
  ) {
    logger.error('DashboardWrapper BASE_COMPANY_SETTING error', error || bcSettingsError)
    respHandler(bcSettingsError, 'error')
  }

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) {
    return <NoAccess />
  }

  return (
    <ListWrapper>
      <Row gutter={24}>
        <Col span={24}>
          <Flex align="middle">
            <Typography.Title level={4}>Dashboard</Typography.Title>
            <HelpIcon
              style={{ marginBottom: 10, marginLeft: 10 }}
              articleUrl="https://shipxhelp.freshdesk.com/en/support/solutions/articles/47001223803-overview-dashboard"
            />
          </Flex>
        </Col>
        <Col span={24} style={{ marginBottom: 32 }}>
          <Spin spinning={loading || lastYrLoading}>
            <MTDTable
              data={data}
              query={input}
              lastYrData={lastYrData}
              settings={bcSettingsData?.setting?.setting?.[0]?.value}
              hasConfidentialPermission={hasConfidentialPermission}
            />
          </Spin>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col span={24}>
          <Spin spinning={loading || bcSettingsLoading}>
            <DailyTable
              data={data}
              query={query}
              settings={bcSettingsData?.setting?.setting?.[0]?.value}
              hasConfidentialPermission={hasConfidentialPermission}
            />
          </Spin>
        </Col>
      </Row>
    </ListWrapper>
  )
})

export default DashboardWrapper
