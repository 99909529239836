import type { Booking } from '@/types/graphql'

import { useEffect, useMemo } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Button, Col, Flex, Spin, Typography } from 'antd-v5'

import NewBookingForm from '@/components/Booking/NewBookingForm'
import { USER_PREFERENCE_QUERY } from '@/components/Booking/NewBookingForm/_shared/schema'
import HelpIcon from '@/components/Shared/HelpIcon'
import NoAccess from '@/components/Shared/NoAccess'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const articleUrl =
  'https://shipxhelp.freshdesk.com/support/solutions/articles/47001201726-create-transport-booking'

type NewBookingTransportPageProps = {
  duplicateBookingObject?: Booking
}

const NewBookingTransportPage = (props: NewBookingTransportPageProps) => {
  const { duplicateBookingObject } = props

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'booking',
    permission: 'create'
  })

  const [getUserPref, { loading, data, error }] = useLazyQuery(USER_PREFERENCE_QUERY, {
    fetchPolicy: 'cache-first'
  })

  const userPreference = useMemo(
    () => data?.userPreferences?.filter(pref => pref?.key === 'TRANSPORT'),
    [data]
  )
  const bookingObject = useMemo(() => userPreference?.[0]?.value || '{}', [userPreference])
  const savedBookingObject = useMemo(() => JSON.parse(bookingObject), [bookingObject])

  useEffect(() => {
    if (hasPermission) {
      getUserPref({ variables: { type: 'NEWBOOKING', key: 'TRANSPORT' } })
    }
  }, [hasPermission])

  if (permLoading || loading) {
    return (
      <Flex justify="center" align="center" style={{ height: '80vh' }}>
        <Spin />
      </Flex>
    )
  }

  if (error) {
    logger.error('New Booking USER_PREFERENCE_QUERY Error.', error)
    responseHandler(error, 'error')
  }

  if (!hasPermission)
    return (
      <Flex justify="center" align="center" style={{ height: '80vh' }}>
        <NoAccess />
      </Flex>
    )

  return (
    <Flex justify="center" className="mt-8">
      <Col span={20}>
        <Flex justify="space-between" align="center" className="mb-4">
          <Flex gap={5} align="center">
            <Typography.Title level={1} style={{ fontWeight: 500 }}>
              New Booking
            </Typography.Title>
            <HelpIcon articleUrl={articleUrl} />
          </Flex>
          <Button href="/bookings/new/bulk">
            <Typography.Text style={{ color: 'rgb(150, 152, 154)', fontSize: 12 }}>
              Bulk Booking
            </Typography.Text>
          </Button>
        </Flex>

        <NewBookingForm duplicateBookingObject={duplicateBookingObject || savedBookingObject} />
      </Col>
    </Flex>
  )
}

export default NewBookingTransportPage
