import type { Booking, RequisitionTemplate } from '@/types/graphql'

import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { Button, Flex, Popover } from 'antd-v5'

import { requestTypes } from 'App/utils/labelMap'
import RequisitionModal from '../RequisitionModal'
import RequestButton from './RequestButton'

type RequisitionProps = {
  booking?: Booking | null
  requisitions?: { text; type; get; send }[]
}

const Requisition = ({ booking, requisitions = [] }: RequisitionProps) => {
  const [selectedRequisition, setSelectedRequisition] = useState<RequisitionTemplate | null>(null)
  const [open, setOpen] = useState(false)
  const [isPopoverOpen, setIsPopoverOpen] = useState(false)

  const toggleTemplate = ({ open: modalOpen = false, isPopoverOpen: popoverOpen = false }) => {
    setOpen(modalOpen)
    setIsPopoverOpen(popoverOpen)
  }

  const openRequisition = requisition => {
    setSelectedRequisition(requisition)
    setOpen(true)
    setIsPopoverOpen(false)
  }

  const renderAddRequest = requisitions => {
    if (requisitions && requisitions.length) {
      return (
        <>
          {requisitions.map((req, index) => {
            const requestText = (requestTypes[req.text] && requestTypes[req.text].text) || req.text
            return (
              // eslint-disable-next-line react/no-array-index-key
              <Flex key={index} className="mb-1">
                <RequestButton text={requestText} onClick={() => openRequisition(req)} />
              </Flex>
            )
          })}
        </>
      )
    }
    return null
  }

  return (
    <div className="ml-2 mb-8">
      <Popover
        arrow={false}
        trigger="click"
        title="Create Request / Notify"
        placement="right"
        content={renderAddRequest(requisitions)}
        open={isPopoverOpen}
        onOpenChange={visible => toggleTemplate({ isPopoverOpen: visible })}
      >
        {requisitions && requisitions.length > 0 && (
          <Button
            onClick={() => toggleTemplate({ isPopoverOpen: !isPopoverOpen })}
            icon={<PlusOutlined />}
            shape="circle"
          />
        )}
      </Popover>
      {open && (
        <RequisitionModal
          open={open}
          booking={booking}
          bookingUuid={booking?.uuid}
          type={selectedRequisition?.type}
          onClose={() => toggleTemplate({ open: false })}
        />
      )}
    </div>
  )
}

export default Requisition
