import type { JobPreSubmitProps } from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import type { AddJobInput, Job, Trip } from '@/types/graphql'

import { useState } from 'react'
import { PlusOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { Button, Modal, notification, Tooltip } from 'antd-v5'
import identity from 'lodash/identity'
import pick from 'lodash/pick'
import pickBy from 'lodash/pickBy'
import { v4 as uuidv4 } from 'uuid'

import JobForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import { ADD_JOB } from '@/graphql/booking'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { logger } from '@/utils/logger'

type JobAddActionProps = {
  shipmentType: any
  bookingUuid: string
}

const JobAddAction = (props: JobAddActionProps) => {
  const { bookingUuid } = props

  const [displayModal, setDisplayModal] = useState(false)
  const [jobsArray, setJobsArray] = useState([{ uuid: uuidv4(), bookingUuid }])

  const [addJobMutation] = useMutation(ADD_JOB)

  const updateJobs = jobs => setJobsArray(jobs)

  const { hasPermission } = usePermissionIsAllowed({
    resource: 'job',
    permission: 'create'
  })

  if (!hasPermission) return <></>

  const handleDisplayModal = () => setDisplayModal(true)
  const handleModalCancel = () => setDisplayModal(false)

  const handleModalOk = async () => {
    const job = jobsArray[0] as any

    const tripsInput = job?.trips?.map((trip: Trip) => {
      return {
        ...pick(trip, [
          'uuid',
          'waybillId',
          'fromCompanyUuid',
          'toCompanyUuid',
          'fromUuid',
          'toUuid',
          'remarks',
          'references',
          'details',
          'seal',
          'status',
          'type'
        ])
      }
    })

    const formattedJob: AddJobInput = {
      details: {
        ...pickBy(job.details, identity)
      },
      type: job.type,
      no: job.no,
      remarks: job.remarks,
      reference: job.reference,
      tripFormat: job.tripFormat,
      trips: tripsInput,
      uuid: uuidv4(),
      bookingUuid
    }

    try {
      await addJobMutation({ variables: { input: { ...formattedJob } } })

      notification.success({
        message: 'Submit Notice',
        description: 'Successfully updated Job'
      })

      setDisplayModal(false)
    } catch (error: any) {
      logger.error('Update Job Error', error)
      return notification.error({
        message: 'Error',
        description: error.message.replace('GraphQL error: ', '')
      })
    }
  }

  return (
    <>
      <Tooltip title="Add Job">
        <Button icon={<PlusOutlined />} shape="circle" onClick={handleDisplayModal} />
      </Tooltip>

      <Modal
        title="Add New Job"
        width={1080}
        open={displayModal}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
      >
        <Form>
          {jobsArray.map((job: Job, index: number) => (
            <JobForm
              allJobs={jobsArray as JobPreSubmitProps[]}
              key={job.uuid}
              currentJob={job as JobPreSubmitProps}
              jobIndex={index}
              updateJobs={updateJobs}
            />
          ))}
        </Form>
      </Modal>
    </>
  )
}

export default JobAddAction
