import type { SelectProps } from 'antd-v5'

import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { CATEGORY } from '@/graphql/category'
import notify from '@/utils/notify'

type CategorySelectQueryProps = SelectProps & {
  categoryId?: string | null
}

export function CategorySelectQuery({ categoryId, ...selectProps }: CategorySelectQueryProps) {
  const { data, loading, error } = useQuery(CATEGORY, {
    variables: { _id: categoryId },
    fetchPolicy: 'cache-first',
    skip: !categoryId
  })

  if (error) notify(error.message, 'error')

  const values = data?.category?.values.length ? JSON.parse(data?.category?.values) : []
  const options = values?.map(v => ({ label: v, value: v }))

  return (
    <Select
      showSearch
      loading={loading}
      options={options}
      placeholder={'Select a category..'}
      {...selectProps}
    />
  )
}
