import type { SelectProps } from 'antd-v5'
import { BookingTypeStatus } from '@/types/graphql'

import { useEffect } from 'react'
import { Select } from 'antd-v5'

type TripSelectorProps = SelectProps & {
  trips: any[]
  initialValue?
  form?
}

const TripSelector = (props: TripSelectorProps) => {
  const { form, initialValue, trips, ...selectProps } = props
  const { onChange, value, disabled = false } = selectProps

  useEffect(() => {
    if (!trips?.find(trip => trip?.uuid === initialValue)) {
      if (form)
        form.setFieldsValue({
          tripUuid: undefined
        })
    }
  }, [trips])

  const nonDeletedTrips = trips?.filter(cl => cl.status !== BookingTypeStatus.Deleted)
  const options = nonDeletedTrips?.map(trip => ({
    label: `${trip?.num}: ${trip?.from?.name} - ${trip?.to?.name}`,
    value: trip?.uuid
  }))

  return (
    <Select
      allowClear
      disabled={disabled}
      filterOption
      onChange={onChange}
      placeholder="Select a trip"
      showSearch
      value={value}
      optionFilterProp="children"
      getPopupContainer={trigger => trigger.parentNode}
      {...selectProps}
      options={options}
    />
  )
}

export default TripSelector
