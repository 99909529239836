import type { TransportZone } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { TRANSPORT_ZONES_QUERY } from '@/components/Manage/TransportZones/schema'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

type TransportZonesProps = SelectProps & {
  isRouteZones?: boolean
  zoneConvert?: any
}

export const useZonesCodeArray = () =>
  useQuery(TRANSPORT_ZONES_QUERY, {
    variables: {},
    fetchPolicy: 'cache-and-network'
  })

export const routeZones = (zoneConvert: any, transportZones: any) => {
  const zonesArray = transportZones.map((zone: TransportZone) => zone.code)
  const selectedZones = zonesArray.filter(
    (zone: string) => !Object.keys(zoneConvert).includes(zone)
  )
  const zonesArr: any = []
  for (let i = 0; i < selectedZones.length; i++) {
    const from = selectedZones[i]
    for (let j = 0; j < selectedZones.length; j++) {
      const to = selectedZones[j]
      if (zonesArr.includes(`${to}-${from}`)) continue
      zonesArr.push(`${from}-${to}`)
    }
  }
  return zonesArr
}

const TransportZonesSelect = (props: TransportZonesProps) => {
  const { zoneConvert, isRouteZones = false, ...selectProps } = props

  const {
    style = { width: '100%' },
    placeholder = 'Select a transport zone...',
    value,
    mode
  } = selectProps

  const [searchInput, setSearchInput] = useState(value)
  const [whichZone, setWhichZone] = useState<{ code: string; uuid: string }[]>([])

  const parsedSearchInput = useMemo(() => {
    if (mode == undefined) return searchInput

    return ''
  }, [mode, searchInput])

  const { data, error, loading, refetch } = useQuery(TRANSPORT_ZONES_QUERY, {
    variables: { q: parsedSearchInput },
    fetchPolicy: 'cache-and-network'
  })

  if (error) {
    logger.error('TransportZone Error: ', error)
    respHandler(error, 'error')
  }

  useEffect(() => {
    if (data?.transportZones?.rows?.length) {
      const transportZones = data.transportZones.rows

      setWhichZone(isRouteZones ? routeZones(zoneConvert, transportZones) : transportZones)
    }
  }, [data, isRouteZones, zoneConvert])

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInput(value)
      refetch()
    }, 500),
    []
  )

  const options = whichZone.map(t => ({
    label: t?.code,
    value: t?.code
  }))

  return (
    <Select
      {...props}
      allowClear
      onSearch={handleSearch}
      showSearch
      placeholder={placeholder}
      style={style}
      loading={loading}
      options={options}
    />
  )
}

export default memo(TransportZonesSelect)
