import { Form } from 'antd'
import { Col, Divider, Row } from 'antd-v5'

import LegsContent from './LegsContent'

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 }
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 }
  },
  style: {
    marginBottom: 0
  }
}

const typeTitleMap = {
  haulageDelivery: 'Delivery',
  haulageCollection: 'Collection',
  transportDelivery: 'Transport Delivery'
}

const TripContent = props => {
  const { trip, onChange } = props
  return (
    <div>
      <Divider orientation="left">{typeTitleMap[trip.type] || trip.type}</Divider>
      <Form>
        <Row>
          <Col span={12}>
            <Form.Item label="From" {...formItemLayout}>
              {(trip.from && trip.from.name) || '-'}
            </Form.Item>
            <Form.Item label="To" {...formItemLayout}>
              {(trip.to && trip.to.name) || '-'}
            </Form.Item>
          </Col>
          <Col span={12}>
            <Form.Item label="Status" {...formItemLayout}>
              {trip.status}
            </Form.Item>
          </Col>
        </Row>
      </Form>
      <LegsContent trip={trip} onChange={onChange} />
    </div>
  )
}

export default TripContent
