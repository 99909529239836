import { Input } from 'antd-v5'
import { v4 as uuidv4 } from 'uuid'

import useGlobalCompanyStore from '@/store/globalCompany'

const HiddenFieldsSubForm = ({ form }) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  return (
    <>
      {form.getFieldDecorator('uuid', { initialValue: uuidv4() })(<Input type="hidden" />)}

      {form.getFieldDecorator('companyUuid', { initialValue: selectedGlobalCompany?.uuid })(
        <Input type="hidden" />
      )}
    </>
  )
}

export default HiddenFieldsSubForm
