import { Component } from 'react'
import { graphql } from '@apollo/client/react/hoc'
import { message } from 'antd-v5'
import find from 'lodash/find'
import PropTypes from 'prop-types'
import { compose } from 'recompose'

import config from '@/config'
import {
  APPROVE_BOOKING_DOCUMENT,
  DELETE_BOOKING_DOCUMENT,
  MARK_SENT_BOOKING_DOCUMENT,
  UPDATE_BOOKING_DOCUMENT,
  VERIFY_BOOKING_DOCUMENT
} from '@/graphql/document'
import useGlobalCompanyStore from '@/store/globalCompany'
import { LOCAL_STORAGE_KEYS } from '@/utils/auth'
import respHandler, { handleWithMessage } from '@/utils/responseHandler'
import webStorage from '@/utils/webStorage'

export default WrappedComponent => {
  class WithUpload extends Component {
    constructor(props) {
      super(props)
      this.handleDocumentUpload = this.handleDocumentUpload.bind(this)
      this.handleVerifyDocument = this.handleVerifyDocument.bind(this)
      this.handleMarkSentDocument = this.handleMarkSentDocument.bind(this)
      this.handleUpdateDocument = this.handleUpdateDocument.bind(this)
      this.handleDeleteDocument = this.handleDeleteDocument.bind(this)
      this.toggleUploadStatus = this.toggleUploadStatus.bind(this)
    }

    toggleUploadStatus(status) {
      message.destroy()

      if (status === 'uploading') {
        message.loading('Uploading document, hang on...', 0)
      } else if (status === 'success') {
        message.success('Uploaded successfully.', 5)
      } else {
        message.error('Failed to upload.', 10)
      }
    }

    async handleDocumentUpload(acceptedFiles, rejectedFiles, type) {
      const selectedGlobalCompany = useGlobalCompanyStore.getState().selectedGlobalCompany
      const { bookingQuery } = this.props
      const { booking } = bookingQuery
      const uuid = booking.uuid
      const body = new FormData()

      body.append('uuid', uuid)
      body.append('type', type)

      acceptedFiles.forEach(function (acceptedFile) {
        body.append('files', acceptedFile)
      })

      try {
        respHandler('Uploading document, hang on...', 'load')
        const resp = await fetch(`${config.api.baseUrl}/booking/upload-document`, {
          method: 'POST',
          headers: {
            'base-company-uuid': selectedGlobalCompany.uuid,
            authorization: `JWT ${webStorage.getItem(LOCAL_STORAGE_KEYS.JWT)}`
          },
          body,
          progress: e => {
            console.log(`Progress: ${e.loaded / e.total}%`)
          }
        })

        const respData = await resp.json()

        if (respData.message) {
          respHandler(respData.message, 'error')
        } else {
          await bookingQuery.refetch()
          respHandler('Uploaded successfully.', 'success')
        }
      } catch (err) {
        console.log(err)
        respHandler('something went wrong', 'error')
      }
    }

    handleApproveDocument = async (uuid, type) => {
      if (uuid && type) {
        try {
          const { bookingQuery } = this.props
          const { booking } = bookingQuery

          await this.props.approveDocument({
            uuid,
            type
          })

          const refetchedData = await bookingQuery.refetch(booking.uuid)
          const updatedBooking = find(
            refetchedData.data.booking.bookingDocuments,
            doc => doc.uuid === uuid
          )

          return handleWithMessage(updatedBooking, 'success', true)
        } catch (error) {
          console.log(error)
          return handleWithMessage(error, 'fatal')
        }
      }
    }

    async handleVerifyDocument(uuid) {
      if (uuid) {
        try {
          const { bookingQuery } = this.props
          const { booking } = bookingQuery

          await this.props.verifyDocument(uuid)
          await bookingQuery.refetch(booking.uuid)

          return handleWithMessage('success', 'success', true)
        } catch (error) {
          console.log(error)
          return handleWithMessage(error, 'fatal')
        }
      }
    }

    async handleMarkSentDocument(variables) {
      if (variables.uuid) {
        try {
          const { bookingQuery } = this.props
          const { booking } = bookingQuery

          await this.props.markSentDocument(variables)
          await bookingQuery.refetch(booking.uuid)

          return handleWithMessage('success', 'success', true)
        } catch (error) {
          console.log(error)
          return handleWithMessage(error, 'fatal')
        }
      }
    }

    async handleUpdateDocument(variables) {
      if (variables.uuid) {
        try {
          const { bookingQuery, updateDocument } = this.props
          const { booking } = bookingQuery

          await updateDocument(variables)
          await bookingQuery.refetch(booking.uuid)

          return handleWithMessage('success', 'success', true)
        } catch (error) {
          console.log(error)
          return handleWithMessage(error, 'fatal')
        }
      }
    }

    async handleDeleteDocument(uuid) {
      if (uuid) {
        try {
          const { bookingQuery } = this.props
          const { booking } = bookingQuery

          await this.props.deleteDocument(uuid)
          await bookingQuery.refetch(booking.uuid)

          return handleWithMessage('success', 'success', true)
        } catch (error) {
          console.log(error)
          return handleWithMessage(error, 'fatal')
        }
      }
    }

    render() {
      const actions = {
        handleDocumentUpload: this.handleDocumentUpload,
        handleVerifyDocument: this.handleVerifyDocument,
        handleDeleteDocument: this.handleDeleteDocument,
        handleApproveDocument: this.handleApproveDocument,
        handleMarkSentDocument: this.handleMarkSentDocument,
        handleUpdateDocument: this.handleUpdateDocument
      }

      return <WrappedComponent uploadActions={actions} {...this.props} />
    }
  }

  WithUpload.propTypes = {
    verifyDocument: PropTypes.func,
    deleteDocument: PropTypes.func,
    approveDocument: PropTypes.func,
    markSentDocument: PropTypes.func,
    updateDocument: PropTypes.func
  }

  return compose(
    graphql(VERIFY_BOOKING_DOCUMENT, {
      props: ({ mutate }) => ({
        verifyDocument: uuid =>
          mutate({
            variables: { uuid }
          })
      })
    }),
    graphql(APPROVE_BOOKING_DOCUMENT, {
      props: ({ mutate }) => ({
        approveDocument: params =>
          mutate({
            variables: params
          })
      })
    }),
    graphql(MARK_SENT_BOOKING_DOCUMENT, {
      props: ({ mutate }) => ({
        markSentDocument: variables =>
          mutate({
            variables
          })
      })
    }),
    graphql(UPDATE_BOOKING_DOCUMENT, {
      props: ({ mutate }) => ({
        updateDocument: variables =>
          mutate({
            variables
          })
      })
    }),
    graphql(DELETE_BOOKING_DOCUMENT, {
      props: ({ mutate }) => ({
        deleteDocument: uuid =>
          mutate({
            variables: { uuid }
          })
      })
    })
  )(WithUpload)
}
