import type { DocumentNode } from '@apollo/client'
import type { SelectProps } from 'antd-v5'

import { useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

type SelectWithQueryProps = SelectProps & {
  options?: string[] | { value: string; label: string }[]
  query: DocumentNode
  variables: any
  getData: (data: any) => any[]
  valueFormatter?: (value: any) => any
  labelFormatter?: (value: any) => string
}

const SelectWithQuery = (props: SelectWithQueryProps) => {
  const { query, variables, getData, valueFormatter, labelFormatter, ...rest } = props
  const { data, loading } = useQuery(query, {
    variables: variables,
    skip: !variables
  })

  const options = useMemo(() => {
    if (props.options && props.options.length && !query) {
      return props.options
    }

    return getData(data) || []
  }, [data, props.options])

  const fallBackMapper = (item, key) =>
    typeof item === 'string' ? item : item[key] || JSON.stringify(item)
  const valueMapper = item =>
    valueFormatter ? valueFormatter(item) : fallBackMapper(item, 'value')
  const labelMapper = item =>
    labelFormatter ? labelFormatter(item) : fallBackMapper(item, 'label')

  const selectOptions = options.map(item => ({
    label: labelMapper(item),
    value: valueMapper(item)
  }))

  return (
    <Select
      allowClear
      showSearch
      placeholder="Select a value..."
      {...rest}
      loading={loading}
      options={selectOptions}
    />
  )
}

export default SelectWithQuery
