import type { HTMLAttributes, PropsWithChildren } from 'react'

import { Col, Form, Row } from 'antd'
import { Button } from 'antd-v5'
import styled from 'styled-components'

export const StyledHeader = styled.div`
  border-bottom: 1px solid rgb(235, 235, 235);

  .fa {
    font-size: 1.1rem;
    margin-left: 5px;
    vertical-align: middle;
  }

  .anticon-close {
    font-size: 16px;
    cursor: pointer;
  }
`

export const HeaderTitle = styled.div`
  font-size: 16px;
  font-weight: 600;
  color: black;
  line-height: normal;
`

export const HeaderDescription = styled.div`
  font-size: 11.5px;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  color: rgb(160, 161, 162);
`

export const TextWrapper = styled.div`
  padding-left: 0;
`

export const ViewWrapper = styled.div`
  padding: 10px 20px;

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0;
    margin-top: 50px;

    .ant-divider-inner-text {
      text-transform: uppercase;
      letter-spacing: 1px;
      font-weight: bold;
      font-size: 14px;
    }
  }
`

export const ViewSection = styled(Row)<{ top?; bottom?; left? }>`
  padding: ${props => (props.top ? props.top : '10px')} 0
    ${props => (props.bottom ? props.bottom : '0px')} ${props => (props.left ? props.left : '0px')};
  margin-right: 0 !important;
  margin-left: 0 !important;
`

export const SectionTitle = styled.div<PropsWithChildren<{ borderless?: boolean }>>`
  font-size: 13.5px;
  font-weight: 500;
  color: black;
  line-height: 32px;
  letter-spacing: 0.3px;
  border-bottom: ${props => (props.borderless ? 'none' : '1px solid rgb(235, 235, 235)')};

  .ant-btn {
    margin-left: 10px;
  }
`

export const FieldColRow = styled(Row)`
  .ant-form-item-label label {
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.3px;
  }

  .ant-input {
    padding: 4px 8px;
  }

  .ant-select-search {
    input {
      font-size: 13px;
      font-weight: 500;
      color: rgb(50, 50, 50);
    }
  }
`

export const MultiRow = styled.div`
  padding: 4px 0;
  width: 200px;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
`

export const NoItemsFound = styled.div<PropsWithChildren<{ borderedTop?: boolean; align? }>>`
  text-align: ${props => props.align || 'center'};
  padding: 20px 0;
  font-size: 13px;
  font-weight: 500;
  color: rgb(140, 140, 140);
  letter-spacing: 0.3px;
  ${props => (props.borderedTop ? 'border-top: 1px solid rgb(235, 235, 235);' : '')}
`

export const TableWrapper = styled.div`
  .ant-table-thead > tr > th,
  .ant-table-tbody > tr > td {
    padding: 0;
    height: 30px;
    line-height: 17px;
    word-break: normal;
  }

  .ant-table-thead > tr > th {
    border-bottom: 1px solid rgb(230, 231, 232);
    background: white;
    font-size: 11.5px;
    letter-spacing: 0.3px;
    color: rgb(140, 140, 140);
    text-transform: uppercase;
    font-weight: 500;
  }

  .ant-table-tbody > tr.ant-table-row-selected td {
    background: white;
  }

  .ant-table-pagination.ant-pagination {
    margin: 8px 0 0;
  }

  .anticon {
    font-size: 16px;
  }
`

export const FieldsAction = styled.div`
  text-align: right;
  padding: 0 45px 10px 0;
`

export const StyledText = styled.span<
  PropsWithChildren<{
    isNetOff?: boolean
    muted?: boolean
    negative?: boolean
    toRight?: boolean
  }>
>`
  font-size: 12px;
  font-weight: 600;
  ${props => props.isNetOff && 'background: #bae7ff;'}
  color: ${props =>
    props.muted
      ? 'rgba(0, 0, 0, 0.3)'
      : props.negative
        ? 'rgba(255, 0, 0, 0.9)'
        : 'rgba(0, 0, 0, 0.9)'};
  text-align: ${props => (props.toRight ? 'right' : 'left')};
  line-height: normal;
  letter-spacing: 0.1px;
  white-space: nowrap;
`

export const StyledField = styled.span<PropsWithChildren<unknown>>`
  padding: 0;
`

export const StyledAnchorField = styled.a<
  PropsWithChildren<{ noPadding?: boolean; toRight?: boolean; target?: string; href: string }>
>`
  padding: 0 ${props => (props.noPadding ? '0px' : '4px')};
  font-size: 12px;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.1px;
  text-align: ${props => (props.toRight ? 'right' : 'left')};
`

export const ErrorSpan = styled.span`
  margin-right: 10px;
`

export const StyledDash = styled.span`
  font-weight: 400;
  color: rgb(140, 140, 140);
`

export const LoadingPanel = styled.div<{ noPadding?: boolean }>`
  padding: ${props => (props.noPadding ? '0' : '10px 15px')};
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  color: rgb(140, 140, 140);
`

export const FieldsWrapper = styled.div<PropsWithChildren<HTMLAttributes<HTMLDivElement>>>`
  padding-bottom: 10px;

  textarea {
    margin-bottom: 5px;
  }
`

export const ActionWrapper = styled.div`
  display: flex;
  align-items: center;

  .anticon-delete {
    color: rgb(160, 160, 160);
  }

  .anticon-check-circle {
    color: rgb(190, 190, 190);
  }
`

export const StyledButton = styled(Button)`
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: pointer;
  color: #1890ff;
`

export const StyledEditableRow = styled(Row)`
  .ant-form-item-label {
    label {
      font-size: 12.5px;
    }
  }

  .ant-divider-horizontal.ant-divider-with-text {
    margin: 0;
    margin: 25px 0 10px;

    .ant-divider-inner-text {
      font-size: 12px;
    }
  }

  .ant-input {
    padding: 4px 8px;
  }
`

export const EditAction = styled.div`
  margin-top: 30px;
  text-align: right;

  .ant-btn {
    margin-left: 5px;
  }
`

export const StyledAnchor = styled.a<PropsWithChildren<HTMLAttributes<HTMLAnchorElement>>>`
  margin: 0 5px;
`

export const InnerSection = styled(Row)`
  padding: 0 15px;
  margin-bottom: 20px;
`

export const DisplayOnlyLabel = styled.div`
  font-size: 13px;
  font-weight: 500;
  color: black;
`

export const CreditNoteLabel = styled.span`
  font-weight: bold;
`

const highlightStyle = `
  background: rgb(242, 244, 246);
`

export const Summary = {
  SummaryWrapper: styled(Row)`
    padding: 10px 0;
  `,
  SummaryItem: styled(Row)<{ state? }>`
    padding: 2px 0;
    ${props => props.state === 'highlight' && highlightStyle}
  `,
  SummaryLabels: styled(Col)`
    text-align: right;
  `,
  SummaryValues: styled(Col)`
    text-align: right;
  `,
  LabelItem: styled.div`
    font-size: 0.9em;
    font-weight: 500;
    color: black;
  `,
  ValueItem: styled.div`
    font-size: 0.9em;
    font-weight: 500;
    padding-right: 5px;
  `,
  ItemCurrency: styled.span`
    font-size: 0.85em;
    font-weight: 600;
    color: rgb(170, 170, 170);
    padding-right: 5px;
  `
}

export const DeletedItem = styled.div`
  text-align: left;
  padding: 0 5px;

  .anticon {
    color: rgb(219, 21, 21);
  }
`

export const TimeForm = styled(Form)`
  .ant-form-item {
    margin-bottom: 5px;
  }

  .ant-form-item-label {
    line-height: normal;
  }

  .ant-form-item-control {
    line-height: normal;
  }
  .ant-form-item {
    margin-bottom: 0px;
  }
  .ant-btn {
    height: 9px;
  }
`
