import type { TripSummary } from 'App/types/graphql'

import { memo, useCallback, useMemo, useState } from 'react'
import { DownloadOutlined } from '@ant-design/icons'
import { Radio } from 'antd'
import { Button, Col, Divider, Flex, Row, Typography } from 'antd-v5'
import { cloneDeep, groupBy } from 'lodash'
import styled from 'styled-components'

import SharedSelector from 'App/components/Select/SharedSelector'
import { useZonesCodeArray } from 'App/components/Select/TransportZonesSelect'
import { LOCAL_STORAGE_KEYS } from 'App/utils/auth'
import { logger } from 'App/utils/logger'
import responseHandler from 'App/utils/responseHandler'
import webStorage from 'App/utils/webStorage'
import { filterFormLabelStyle } from '../Jobs/JobsSummaryTable/Filter'
import { exportTripSummary, formatTripSummaryForTable } from './helpers'
import InlandDistributionTable from './InlandDistributionTable'
import TripSummaryTable from './TripSummaryTable'

const ListWrapper = styled.div`
  min-width: 800px;
  margin: auto;
  padding: 16px;
  overflow-x: auto;
`

export enum ViewOptions {
  DAILY = 'Daily',
  WEEKLY = 'Weekly',
  MONTHLY = 'Monthly',
  // ZONE = 'Zone/Base Co',
  INLAND = 'Inland Distribution'
}

export const optionsArray: Array<string> = Object.values(ViewOptions)

const TripSummaryTableWrapper = memo((props: any) => {
  const { query, data, bcData, loading, graphType, zoneConvert, onGraphTypeChange } = props

  const [selectedZones, setSelectedZones] = useState<string[]>([])
  const [viewBy, setViewBy] = useState<ViewOptions>(ViewOptions.MONTHLY)
  const [selectedDepts, setSelectedDepts] = useState<Array<string>>(
    webStorage.getItem(LOCAL_STORAGE_KEYS.EMPTY_TRIPS_TABLE_DEPARTMENTS) || []
  )

  const finalQuery = useMemo(() => query, [query])

  const onSelectedZoneChange = useCallback((e: any) => {
    setSelectedZones(e)
  }, [])

  const { data: zoneData, error: zoneError } = useZonesCodeArray()

  if (zoneError) {
    logger.error('TripSummaryTableWrapper TRANSPORT_ZONES_QUERY error', zoneError)
    responseHandler(zoneError, 'error')
  }

  const zones = useMemo(() => {
    if (zoneData?.transportZones?.rows?.length) {
      return zoneData.transportZones.rows.map((zone: any) => zone.code)
    }
    return []
  }, [zoneData])

  const onDeptChange = useCallback((departments: Array<string>) => {
    const newArray = departments?.length ? departments : []
    setSelectedDepts(newArray)
    webStorage.setItem(LOCAL_STORAGE_KEYS.EMPTY_TRIPS_TABLE_DEPARTMENTS, newArray)
  }, [])

  const onRadioBtnChange = useCallback((e: any) => {
    e.preventDefault()
    setViewBy(e?.target?.value)
  }, [])

  const clonedData = useMemo(() => cloneDeep(data), [data])

  const groupedBC: any = useMemo(() => groupBy(clonedData, d => d?.baseCompanyUuid), [clonedData])

  const departments = useMemo(
    () =>
      clonedData
        ?.map((sum: TripSummary) => sum.dept)
        .reduce((depts: string[], current: object) => {
          const keys = Object.keys(current)
          if (!keys?.length) {
            return depts
          }

          keys.forEach((key: string) => {
            if (!depts.includes(key)) {
              depts.push(key)
            }
          })

          return depts
        }, [])
        .sort((a: string, b: string) => a.localeCompare(b)),
    [clonedData]
  )

  const rtnTables = useMemo(
    () =>
      groupedBC &&
      Object.keys(groupedBC)?.map((baseCompanyUuid: string, index: number) => {
        const formattedTripSummaries = formatTripSummaryForTable(
          groupedBC?.[baseCompanyUuid],
          finalQuery,
          zones,
          { viewBy, zoneConvert, selectedZones, selectedDepts }
        )
        const foundBc = bcData?.viewer?.baseCompanies.find(
          (bc: any) => bc.uuid === groupedBC?.[baseCompanyUuid]?.[index]?.baseCompanyUuid
        )

        return (
          //@ts-ignore
          <ListWrapper key={groupedBC?.[baseCompanyUuid]?.[index]?.baseCompanyUuid}>
            <Divider>{foundBc?.name}</Divider>
            <Row key={`row${index}`} style={{ display: 'flex', flexDirection: 'row' }}>
              <Col key={`col${index}`} style={{ paddingRight: '30px' }}>
                <TripSummaryTable
                  // @ts-ignore
                  tableData={formattedTripSummaries}
                  query={finalQuery}
                  loading={loading}
                  viewBy={viewBy}
                />
              </Col>
            </Row>
          </ListWrapper>
        )
      }),
    [groupedBC, viewBy, bcData, selectedDepts, zones]
  )

  const onExport = useCallback(() => {
    exportTripSummary(clonedData, bcData?.viewer?.baseCompanies, finalQuery, zones, {
      viewBy,
      selectedDepts
    })
  }, [clonedData, bcData, finalQuery, viewBy, selectedDepts])

  const renderSwitch = (opt: ViewOptions) => {
    switch (opt) {
      case ViewOptions.INLAND:
        // @ts-ignore
        return <InlandDistributionTable query={query} />
      default:
        return rtnTables.map((tbl: any) => <div key={tbl.key}>{tbl}</div>)
    }
  }

  return (
    <>
      <Row type="flex" justify="center" align="middle" gutter={24}>
        <Col span={5}>
          <Radio.Group defaultValue={graphType} buttonStyle="solid">
            <Radio.Button value="divergent" onChange={onGraphTypeChange}>
              Divergent
            </Radio.Button>
            <Radio.Button value="bar" onChange={onGraphTypeChange}>
              Bar Chart
            </Radio.Button>
            <Radio.Button value="table" onChange={onGraphTypeChange}>
              Table
            </Radio.Button>
          </Radio.Group>
        </Col>

        <Col span={8}>
          <Flex justify="center" align="center">
            <Radio.Group defaultValue={viewBy} buttonStyle="solid">
              {optionsArray.map((option: string) => (
                <Radio.Button key={option} value={option} onChange={onRadioBtnChange}>
                  {option}
                </Radio.Button>
              ))}
            </Radio.Group>
          </Flex>
        </Col>

        <Col span={8}>
          <Row gutter={24}>
            <Col span={5}>
              <Typography.Paragraph style={filterFormLabelStyle}>Department:</Typography.Paragraph>
            </Col>
            <Col span={19}>
              <SharedSelector
                mode="multiple"
                style={{ width: '100%' }}
                loading={loading}
                value={selectedDepts}
                onChange={onDeptChange}
                dataArray={departments}
                allowClear={true}
                showSearch={true}
                filterOption={true}
                placeholder="Select department(s)..."
              />
            </Col>
          </Row>
        </Col>

        <Col span={3} style={{ textAlign: 'right' }}>
          {viewBy !== ViewOptions.INLAND && (
            <Button icon={<DownloadOutlined />} loading={loading} onClick={onExport}>
              Export Data
            </Button>
          )}
        </Col>
      </Row>

      <Flex justify="center" align="center" style={{ paddingTop: '20px' }}>
        <Col span={6} offset={6}>
          {/* <Select mode='multiple'
            placeholder='Select Department'
          >
          </Select> */}
        </Col>
        <Col span={6}>
          {/* <TransportZonesSelect
            onChange={onSelectedZoneChange}
            value={selectedZones}
            mode='multiple'
          /> */}
        </Col>
      </Flex>
      {renderSwitch(viewBy)}
    </>
  )
})

export default TripSummaryTableWrapper
