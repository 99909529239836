import type { DocumentNode, QueryResult } from '@apollo/client'
import type { TableColumnType } from 'antd-v5'
import type { ColumnsType, ColumnType } from 'antd-v5/es/table'
import type { Key } from 'react'
import { ActiveStatus } from '@/types/graphql'

import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Pagination, Result, Skeleton, Table, Typography } from 'antd-v5'
import camelCase from 'lodash/camelCase'
import kebabCase from 'lodash/kebabCase'
import startCase from 'lodash/startCase'

import PageHeader from '@/components/Manage/PageHeader'
import TableActions from '@/components/Manage/Shared/CrudType/TableView/Actions'
import StatusFilter from '@/components/Manage/Shared/CrudType/TableView/StatusFilter'
import HelpIcon from '@/components/Shared/HelpIcon'
import NoAccess from '@/components/Shared/NoAccess'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { logger } from '@/utils/logger'
import notify from '@/utils/notify'

type BaseRecordType = {
  uuid?: string
  _id?: string
  code?: string | null
  name?: string | null
  description?: string | null
  sorting?: number
  status?: ActiveStatus | null
} | null

type CrudTypesTableViewProps<T extends BaseRecordType> = {
  gqlQuery: DocumentNode
  crudType: string
  gqlResponse?: QueryResult
  defaultQuery?: Record<string, object>
  isInputNested?: boolean
  articleUrl?: string
  tableColumns?: TableColumnType<T>[]
  title?: string
  createLink?: string
  overrideKey?: string
}

const CrudTypesTableView = <T extends BaseRecordType>(props: CrudTypesTableViewProps<T>) => {
  const {
    gqlQuery,
    crudType,
    gqlResponse,
    defaultQuery = {},
    isInputNested,
    articleUrl,
    tableColumns,
    title,
    createLink,
    overrideKey
  } = props

  const dataKey = `${crudType}s`
  const crudName = startCase(title || camelCase(dataKey))
  const createUrl = createLink || `/manage/${kebabCase(crudType)}s/create`

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: `${crudType}`,
    permission: 'index'
  })

  const defaultTableColumns: ColumnsType<T> = [
    {
      title: 'Code',
      dataIndex: 'code',
      key: 'code',
      render: (code, record: T) => {
        return (
          <Typography.Link href={`/manage/${kebabCase(dataKey)}/${record?.uuid}`}>
            {code}
          </Typography.Link>
        )
      }
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (name, record: T) => (
        <Typography.Link href={`/manage/${kebabCase(dataKey)}/${record?.uuid}`}>
          {name}
        </Typography.Link>
      )
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
      render: (description: string, record: T) => (
        <Typography.Link href={`/manage/${kebabCase(dataKey)}/${record?.uuid}`}>
          {description}
        </Typography.Link>
      )
    },
    {
      title: 'Sorting',
      dataIndex: 'sorting',
      key: 'sorting',
      render: (sorting, record: T) => (
        <Typography.Link href={`/manage/${kebabCase(dataKey)}/${record?.uuid}`}>
          {sorting}
        </Typography.Link>
      )
    },
    {
      ...(StatusFilter({
        status: ActiveStatus
      }) as ColumnType<T>)
    }
  ]

  const [tableData, setTableData] = useState<T[]>([])
  const [searchKeyword, setSearchKeyword] = useState('')
  const [pagination, setPagination] = useState({ limit: 20, offset: 0 })

  const queryObj = {
    q: searchKeyword,
    statuses: [ActiveStatus.Active, ActiveStatus.Deleted],
    ...defaultQuery,
    ...pagination
  }

  const internalGqlResponse = useQuery(gqlQuery, {
    variables: isInputNested ? { input: queryObj } : queryObj,
    skip: !!gqlResponse,
    fetchPolicy: 'cache-and-network'
  })

  const { data, loading, error, refetch } = gqlResponse || internalGqlResponse

  const pageInfo = data?.[overrideKey || dataKey]?.pageInfo

  const onPaginationChange = useCallback((current: number, pageSize: number) => {
    setPagination({ limit: pageSize, offset: (current || 1) - 1 })
  }, [])

  useEffect(() => {
    setTableData(data?.[overrideKey || dataKey]?.rows)
  }, [data, overrideKey, dataKey])

  if (error) {
    logger.error(`CrudTypeTableView ${crudName} error`, error)
    if (error instanceof Error) notify(error.message, 'error')
    return <Result status="warning" title={`Failed to load ${crudName}`} />
  }

  if (!hasPermission && !permLoading) {
    return <NoAccess />
  }

  return (
    <Flex vertical>
      <Flex align="center">
        <PageHeader title={crudName} />
        {articleUrl && (
          <HelpIcon style={{ marginTop: 20, marginLeft: 10 }} articleUrl={articleUrl} />
        )}
      </Flex>
      <Flex vertical gap="large">
        <TableActions
          refetch={refetch}
          createUrl={createUrl}
          exportSelectedEntity={overrideKey || dataKey}
          setSearchKeyword={setSearchKeyword}
        />
        <Flex vertical gap="large">
          <Skeleton loading={permLoading}>
            <Table
              size="small"
              loading={loading}
              pagination={false}
              dataSource={tableData}
              rowKey={record => (record?.uuid || record?._id) as Key}
              columns={tableColumns || defaultTableColumns}
            />
            <Flex justify="flex-end">
              <Pagination
                size="small"
                showSizeChanger
                total={pageInfo?.count || 0}
                onChange={onPaginationChange}
                pageSize={pageInfo?.limit || 20}
                current={(pageInfo?.offset || 0) + 1}
                onShowSizeChange={onPaginationChange}
                pageSizeOptions={['10', '20', '50', '100']}
                showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} items`}
              />
            </Flex>
          </Skeleton>
        </Flex>
      </Flex>
    </Flex>
  )
}

export default CrudTypesTableView
