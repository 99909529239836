import React, { Component } from 'react'
import { connect } from 'react-redux'
import { DatePicker, Form, Select } from 'antd'
import { Button, Col, Input } from 'antd-v5'
import moment from 'moment'
import { bindActionCreators, compose } from 'redux'

import * as voucherActions from 'App/states/reducers/voucher'
import handleResponse from 'App/utils/responseHandler'
import { EditAction, StyledEditableRow } from '../../Styled'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 10 },
  wrapperCol: { span: 14 }
}

const defaultRequiredMessage = 'Field required.'

class EditableFields extends Component {
  handleSubmit = () => {
    this.props.form.validateFields(async (err, values) => {
      if (err) return

      handleResponse('Create payment, hang on...', 'load')

      const { createPayment, togglePaymentView } = this.props

      const resp = await createPayment(values)

      if (resp.success) {
        handleResponse('Payment created', 'success')
        togglePaymentView(false)
        this.props.form.resetFields()
      } else {
        handleResponse(resp.error, 'error')
      }
    })
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { onCancel, paymentTransactionTypes, selectedPayment } = this.props

    const fields = [
      {
        label: 'Reference No.',
        value: 'reference',
        attributes: {
          rules: [
            {
              required: true,
              message: defaultRequiredMessage
            }
          ],
          initialValue: selectedPayment && selectedPayment.referenceNumber
        },
        input: <Input autoComplete="off" />
      },
      {
        label: 'Payment Amount',
        value: 'amount',
        attributes: {
          rules: [
            {
              required: true,
              message: defaultRequiredMessage
            }
          ],
          initialValue: selectedPayment && selectedPayment.amount
        },
        input: <Input autoComplete="off" type="number" />
      },
      {
        label: 'Transaction Type',
        value: 'transactionType',
        attributes: {
          initialValue: selectedPayment && selectedPayment.transactionType,
          rules: [
            {
              required: true,
              message: defaultRequiredMessage
            }
          ]
        },
        input: (
          <Select getPopupContainer={trigger => trigger.parentNode}>
            {paymentTransactionTypes.map(transType => (
              <Select.Option key={transType.value} value={transType.value}>
                {transType.text}
              </Select.Option>
            ))}
          </Select>
        )
      },
      {
        label: 'Payment Date',
        value: 'paymentDate',
        attributes: {
          initialValue: selectedPayment && moment(selectedPayment.paymentDate)
        },
        input: (
          <DatePicker
            format="DD/MM/YYYY"
            placeholder="Select a date and time"
            style={{ width: '100%' }}
            getCalendarContainer={trigger => trigger.parentNode}
          />
        )
      },
      {
        label: 'Remarks',
        value: 'remarks',
        attributes: {
          initialValue: selectedPayment && selectedPayment.taxPercentage
        },
        input: <Input autoComplete="off" />
      }
    ]

    return (
      <React.Fragment>
        <Form>
          <StyledEditableRow>
            {fields.map(field => {
              return (
                <Col key={field.label} span={24}>
                  <FormItem label={field.label} {...formItemLayout}>
                    {getFieldDecorator(field.value, field.attributes)(field.input)}
                  </FormItem>
                </Col>
              )
            })}
          </StyledEditableRow>
          <EditAction>
            <Button onClick={onCancel}>Cancel</Button>
            <Button type="primary" onClick={this.handleSubmit}>
              Create
            </Button>
          </EditAction>
        </Form>
      </React.Fragment>
    )
  }
}

export default compose(
  Form.create(),
  connect(
    state => ({
      paymentTransactionTypes: state.voucher.paymentTransactionTypes,
      selectedVoucher: state.voucher.selectedVoucher,
      selectableDocuments: state.voucher.selectableDocuments
    }),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  )
)(EditableFields)
