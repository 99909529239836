import { Col, Row } from 'antd-v5'

const LabelValue = props => {
  const { label, value, labelSpan = 8 } = props

  return (
    <Row>
      <Col span={labelSpan}>{label}:</Col>
      <Col span={24 - labelSpan}>{value}</Col>
    </Row>
  )
}

export default LabelValue
