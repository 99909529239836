import { Component } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Mutation } from '@apollo/client/react/components'
import { Form } from 'antd'
import { Button, Col, Flex, Input, Select } from 'antd-v5'

import { Loader } from '../Styled'
import { ToggleActions } from './Styled'

const inputs = {
  text: <Input autoComplete="off" spellCheck="false" />,
  tags: <Select mode="tags" />
}

class MutationView extends Component {
  renderEditableActions = loading => {
    if (!loading) {
      return (
        <ToggleActions top="8px">
          <Button onClick={this.props.handleToggleMode}>Cancel</Button>
          <Button type="primary" htmlType="submit">
            Update
          </Button>
        </ToggleActions>
      )
    }

    return (
      <ToggleActions top="16px">
        <Loader>
          <LoadingOutlined /> Updating...
        </Loader>
      </ToggleActions>
    )
  }

  render() {
    const { getFieldDecorator } = this.props.form
    const { mutationProps, fieldName, value, type = 'text' } = this.props

    const input = inputs[type]

    return (
      <Mutation {...mutationProps} onCompleted={this.props.handleCompleted}>
        {(onMutate, { loading }) => {
          return (
            <Form onSubmit={e => this.props.handleMutation(e, onMutate)}>
              <Flex align="center">
                <Col span={24}>
                  {getFieldDecorator(fieldName, {
                    initialValue: value || (type === 'tags' ? [] : null),
                    rules: [
                      {
                        required: true,
                        message: 'Field required.'
                      }
                    ]
                  })(input)}
                </Col>
                <Col span={24}>{this.renderEditableActions(loading)}</Col>
              </Flex>
            </Form>
          )
        }}
      </Mutation>
    )
  }
}

export default MutationView
