import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'
import { DocumentGeneratorTemplateFieldQuery } from '@/types/graphql'

import { memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { camelCaseToSpace } from '@/utils/u'

type Props = SelectProps & {
  id?: string
  enumName: string
  include?: string[]
  exclude?: string[]
  camelCaseToNormal?: boolean
}

export const ENUM_QUERY = gql(`
  query enumForSelector($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`)

const EnumSelector = (props: Props) => {
  const { id, include, exclude, enumName, camelCaseToNormal, ...selectProps } = props

  const { loading, error, data } = useQuery(ENUM_QUERY, {
    variables: { name: enumName },
    fetchPolicy: 'cache-first'
  })

  const customValues = useMemo(() => DocumentGeneratorTemplateFieldQuery.CustomValues, [])

  const enums = useMemo(() => {
    let enumValues = data?.__type?.enumValues || []

    if (include) enumValues = enumValues.filter(t => include.includes(t.name))

    if (exclude) enumValues = enumValues.filter(t => !exclude.includes(t.name))

    return enumValues
      .map(t => {
        if (camelCaseToNormal) return { value: t.name, label: camelCaseToSpace(t.name) }
        return { value: t.name, label: t.name }
      })
      .sort((a, b) => (a.value === customValues ? -1 : b.value === customValues ? 1 : 0))
  }, [camelCaseToNormal, data?.__type?.enumValues, customValues, include, exclude])

  if (error) {
    logger.error(`EnumSelector ${enumName} error`, error)
    responseHandler(error, 'error')
  }

  const options = enums?.map(({ label, value }) => ({ label, value }))

  return (
    <Select
      id={id ?? `${enumName}-selector`}
      loading={loading}
      options={options}
      filterOption={false}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      {...selectProps}
    />
  )
}

export default memo(EnumSelector)
