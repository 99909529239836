import { Component } from 'react'
import { connect } from 'react-redux'
import { ArrowDownOutlined, InteractionOutlined } from '@ant-design/icons'
import { gql } from '@apollo/client'
import { Mutation } from '@apollo/client/react/components'
import { Dropdown, Form } from 'antd'
import { Button, Col, notification, Row, Tooltip } from 'antd-v5'
import filter from 'lodash/filter'
import isEmpty from 'lodash/isEmpty'
import uniqBy from 'lodash/uniqBy'
import { compose, withState } from 'recompose'
import { bindActionCreators } from 'redux'

import BookingSelect from 'App/components/Select/BookingSelect'
import JobSelect from 'App/components/Select/NoQuery/JobSelect'
import voucherDetails from 'App/containers/voucher/fragment/voucher'
import * as voucherActions from 'App/states/reducers/voucher'
import handleResponse from 'App/utils/responseHandler'
import { ArrowPanel, NotItemsFound, RolloverTitle, StyledMenu } from './Styled'

class Rollover extends Component {
  handleSubmit = rolloverVoucher => {
    this.props.form.validateFields((err, values) => {
      if (err) return

      const { selectedVoucher } = this.props

      try {
        rolloverVoucher({
          variables: {
            uuid: selectedVoucher.uuid,
            ...values
          }
        })
      } catch (error) {
        console.log(error)
      }
    })
  }

  handleToggleRollover = () => this.props.toggleRollover(!this.props.showRollover)

  handleError = error => {
    handleResponse(error, 'error')
  }

  renderOverlay = () => {
    const { showRollover } = this.props
    const { getFieldDecorator } = this.props.form

    if (!showRollover) {
      return <div />
    }

    const { selectedVoucher } = this.props

    const voucherItemsWithJob = filter(selectedVoucher.voucherItems, vi => !isEmpty(vi.job))
    const jobs = voucherItemsWithJob && voucherItemsWithJob.map(vi => vi.job)

    const uniqueJobs = (jobs && uniqBy(jobs, 'uuid')) || null

    if (!uniqueJobs || !uniqueJobs.length) {
      return (
        <StyledMenu>
          <NotItemsFound>
            No jobs available to rollover.
            <Button onClick={this.handleToggleRollover}>Cancel</Button>
          </NotItemsFound>
        </StyledMenu>
      )
    }

    return (
      <StyledMenu>
        <Mutation
          mutation={voucherRollover}
          onError={this.handleError}
          onCompleted={this.handleRollovered}
        >
          {(rolloverVoucher, { loading }) => (
            <Form onSubmit={() => this.handleSubmit(rolloverVoucher)}>
              <RolloverTitle>Rollover jobs</RolloverTitle>
              {getFieldDecorator('jobUuid', {
                rules: [
                  {
                    required: true,
                    message: 'Job is required.'
                  }
                ]
              })(<JobSelect jobs={uniqueJobs || []} />)}
              <ArrowPanel>
                <ArrowDownOutlined />
              </ArrowPanel>
              {getFieldDecorator('bookingUuid', {
                rules: [
                  {
                    required: true,
                    message: 'Select a booking to rollover.'
                  }
                ]
              })(<BookingSelect useIcon={false} />)}
              <Row gutter={14}>
                <Col span={12}>
                  <Button onClick={this.handleToggleRollover}>Cancel</Button>
                </Col>
                <Col span={12}>
                  <Button type="primary" loading={loading} htmlType="submit">
                    {loading ? 'Hang on...' : 'Rollover'}
                  </Button>
                </Col>
              </Row>
            </Form>
          )}
        </Mutation>
      </StyledMenu>
    )
  }

  handleRollovered = async result => {
    notification.success({
      message: 'Rollover Success.',
      description: 'Voucher and jobs have been rollover successfully.'
    })

    const { updateSelectedVoucher, toggleLoader, loadRequiredData, bookingQuery } = this.props

    this.handleToggleRollover()
    updateSelectedVoucher(result.rolloverVoucher)
    bookingQuery.refetch()
    toggleLoader(true)
    loadRequiredData(result.rolloverVoucher)
  }

  render() {
    const { showRollover } = this.props

    return (
      <Dropdown
        trigger={['click']}
        visible={showRollover}
        overlay={this.renderOverlay()}
        placement="bottomRight"
        getPopupContainer={trigger => trigger.parentNode}
      >
        <Tooltip title="Rollover">
          <InteractionOutlined
            onClick={this.handleToggleRollover}
            style={{ padding: 10, fontSize: 18 }}
          />
        </Tooltip>
      </Dropdown>
    )
  }
}

const voucherRollover = gql`
  mutation rolloverVoucher($uuid: UUID!, $jobUuid: UUID!, $bookingUuid: UUID!) {
    rolloverVoucher(uuid: $uuid, jobUuid: $jobUuid, bookingUuid: $bookingUuid) {
      ...VoucherDetails
    }
  }

  ${voucherDetails}
`

export default compose(
  Form.create(),
  withState('showRollover', 'toggleRollover', false),
  connect(
    () => ({}),
    dispatch => ({
      dispatch,
      ...bindActionCreators(
        {
          ...voucherActions
        },
        dispatch
      )
    })
  )
)(Rollover)
