import type { SelectProps } from 'antd-v5'

import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import COUNTRIES_QUERY from './schema'

type CountrySelectProps = SelectProps & {
  useAlpha3?: boolean
}

const CountrySelect = (props: CountrySelectProps) => {
  const { useAlpha3 = false, ...selectProps } = props

  const { loading, data } = useQuery(COUNTRIES_QUERY, {
    variables: { limit: 500 }
  })

  const countries = data?.countries?.rows || []

  const options = countries.map(country => ({
    label: country?.name,
    value: useAlpha3 ? country?.alpha3 : country?.alpha2
  }))

  return (
    <Select
      showSearch
      allowClear
      filterOption
      style={{ width: '100%' }}
      optionFilterProp="children"
      placeholder="Select a country..."
      notFoundContent={loading ? 'Searching...' : 'No items found.'}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      options={options}
      {...selectProps}
    />
  )
}

export default CountrySelect
