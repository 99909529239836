import type { WrappedFormUtils } from 'antd/lib/form/Form'
import type { ReactNode } from 'react'

import { useTranslation } from 'react-i18next'
import { Form } from 'antd'
import { Button, Col, Divider } from 'antd-v5'

import { EditAction, StyledEditableRow } from '../Styled'

const FormItem = Form.Item
const formItemLayout = {
  labelCol: { span: 7 },
  wrapperCol: { span: 17 }
}

type EditableFormProps = {
  fields: {
    useDivider?: boolean
    title?: string
    cols: {
      label: string
      nonSubmitField?: boolean
      input: ReactNode | JSX.Element
      value?: string
      attributes: any
    }[]
  }[]
  onCancel: () => void
  handleSubmit: () => void
  getFieldDecorator: WrappedFormUtils['getFieldDecorator']
}

const EditableForm = ({ fields, getFieldDecorator, handleSubmit, onCancel }: EditableFormProps) => {
  const { t } = useTranslation()

  return (
    <Form style={{ minHeight: '420px', overflowX: 'hidden', overflowY: 'auto' }}>
      {fields.map((field, rowIndex) => {
        return (
          // eslint-disable-next-line react/no-array-index-key
          <StyledEditableRow key={rowIndex}>
            {field.useDivider && <Divider>{field.title}</Divider>}

            {field.cols.map((col, colIndex) => {
              return (
                // eslint-disable-next-line react/no-array-index-key
                <Col key={colIndex} span={24}>
                  <FormItem label={col.label} {...formItemLayout}>
                    {col.nonSubmitField
                      ? col.input
                      : getFieldDecorator(col.value as never, col.attributes)(col.input)}
                  </FormItem>
                </Col>
              )
            })}
          </StyledEditableRow>
        )
      })}
      <EditAction>
        <Button onClick={onCancel}>{t('common.cancel')}</Button>
        <Button type="primary" onClick={handleSubmit}>
          {t('common.update')}
        </Button>
      </EditAction>
    </Form>
  )
}

export default EditableForm
