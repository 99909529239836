import { gql } from '@/types'

export const ENUM_VALUES = gql(`
  query getEnum($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`)
