import type { SelectProps } from 'antd-v5'
import type { DocumentNode } from 'graphql'
import {
  BillingUnitStatus,
  BookingTypeStatus,
  ContainerGradeStatus,
  ContainerTypeStatus,
  JobOptionStatus,
  JobTypeStatus,
  TransportJobTypeStatus,
  VolumeUnitStatus,
  WeightUnitStatus
} from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import { identity, isEqual } from 'lodash'
import debounce from 'lodash/debounce'

import { logger } from '@/utils/logger'

const defaultDisplayOptions = {
  label: 'name',
  key: 'code',
  value: 'code'
}

type DisplayOption<T extends string> = {
  label: string
  key: string
  value: string
  statuses: T[]
}

const lookup: any = {
  billingUnits: {
    statuses: [BillingUnitStatus.Activated]
  },
  bookingTypes: {
    statuses: [BookingTypeStatus.Active]
  },
  jobTypes: {
    statuses: [JobTypeStatus.Active]
  },
  transportJobTypes: {
    statuses: [TransportJobTypeStatus.Active]
  },
  containerTypes: {
    statuses: [ContainerTypeStatus.Active]
  },
  containerGrades: {
    statuses: [ContainerGradeStatus.Active]
  },
  weightUnits: {
    statuses: [WeightUnitStatus.Active]
  },
  volumeUnits: {
    statuses: [VolumeUnitStatus.Active]
  },
  jobOptions: {
    statuses: [JobOptionStatus.Active]
  }
}

const getDisplayOptions = (options: any, type: string): any => {
  return {
    ...defaultDisplayOptions,
    ...lookup[type],
    ...options
  }
}

export type DynamicTransportSelectProps = SelectProps & {
  query: DocumentNode
  type: string
  displayOptions?: DisplayOption<any>
  selectOptions?: string[] | undefined
  searchText?: string
  queryOnMount?: boolean
  defaultActiveFirstOption?: boolean
  queryVariables?: any
  filterFunc?: any
}

const DynamicTransportSelect = (props: DynamicTransportSelectProps) => {
  const {
    query,
    type,
    searchText = '',
    displayOptions,
    queryOnMount = false,
    defaultActiveFirstOption = false,
    selectOptions,
    queryVariables,
    filterFunc = identity,
    ...rest
  } = props

  const { value, onChange, style } = props
  const { label, value: dataValue, key, statuses } = { ...getDisplayOptions(displayOptions, type) }

  const [rows, setRows] = useState([])
  const [searchInput, setSearchInput] = useState('')
  const prevQueryParams = useRef()

  const params = useMemo(
    () => ({
      q: searchInput || '',
      limit: 20,
      statuses
    }),
    [value, searchInput]
  )

  const [getData, { data, error, loading }] = useLazyQuery(query, {
    variables: params,
    fetchPolicy: 'cache-first'
  })

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        getData({ variables: { ...params, q: value } })
      } catch (error) {
        logger.error(`Dynamic Transport Select ${type} error when searching`, error)
      }
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount || value) getData({ ...params, ...queryVariables })
  }, [])

  useEffect(() => {
    if (!defaultActiveFirstOption) return
    if (!rows?.length) return

    const firstValue = rows[0]?.[dataValue]

    if ((rows.length === 1 && value !== firstValue) || (rows.length > 1 && !value)) {
      onChange?.(firstValue)
    }
  }, [value, rows.length, defaultActiveFirstOption, dataValue])

  useEffect(() => {
    if (isEqual(prevQueryParams.current, queryVariables)) return

    prevQueryParams.current = queryVariables

    getData({ variables: { ...params, ...queryVariables } })
  }, [queryVariables])

  useEffect(() => {
    let items = data?.[type]?.rows || []

    if (!items?.length) return

    items = selectOptions?.length
      ? items.filter((row: any) => selectOptions.includes(row[key]))
      : items

    items = filterFunc ? items.filter(filterFunc) : items

    setRows(items)
  }, [data, filterFunc, selectOptions, type, key])

  if (error) {
    //@ts-ignore
    if (error.graphQLErrors?.[0]?.extensions?.exception?.statusCode !== 404) {
      logger.error(`Dynamic Transport Select ${type} error`, error)
    }
  }

  const fetchOnFocus = useCallback(() => {
    if (queryOnMount) return
    getData()
  }, [queryOnMount])

  return (
    <Select
      {...rest}
      id={`form-${type}-selector`}
      allowClear
      showSearch
      loading={loading}
      filterOption={false}
      onFocus={fetchOnFocus}
      onSearch={handleSearch}
      placeholder={searchText}
      style={{ width: '100%', ...style }}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
    >
      {rows.map((type: any) => (
        <Select.Option key={type[key]} value={type[dataValue]}>
          {type[label]}
        </Select.Option>
      ))}
    </Select>
  )
}

export default memo(DynamicTransportSelect)
