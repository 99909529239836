import type { CostItem } from '@/types/graphql'
import type { ColumnsType } from 'antd-v5/es/table'
import { VoucherStatus } from '@/types/graphql'

import { blue } from '@ant-design/colors'
import { ArrowDownOutlined, MinusCircleOutlined } from '@ant-design/icons'
import { sortCostItems } from '@shipx/formula2'
import { Badge, Button, Flex } from 'antd-v5'
import filter from 'lodash/filter'
import includes from 'lodash/includes'
import numeral from 'numeral'

import withCostItems from '@/components/Voucher/CostItems/Container'
import CostItemsTableView from '@/components/Voucher/CostItems/CostItemsTableView'
import EditCostItemAction from '@/components/Voucher/CostItems/EditCostItemAction'
import VoucherCostItemUsage from '@/components/Voucher/CostItems/VoucherCostItemUsage'
import CustomText from '@/components/Voucher/CustomText'
import { ActionWrapper, DeletedItem, MultiRow, StyledField } from '@/components/Voucher/Styled'
import UnitSizeContainer from '@/components/Voucher/UnitSizeContainer'

const oprColNames = [
  'description',
  'unitSize',
  'quantity',
  'sell',
  'cost',
  'sellTotal',
  'costTotal',
  'estimatedProfit'
]

const finColNames = [
  'description',
  'unitSize',
  'quantity',
  'sellTotal',
  'costTotal',
  'ar',
  'ap',
  'accrualCost',
  'accrualRevenue',
  'shortBill',
  'estimatedProfit',
  'grossProfit'
]

const VoucherCostItems = props => {
  const {
    getError,
    costItemsView,
    voucherBooking,
    selectedVoucher,
    showDeletedCostItems,
    selectedGlobalCompany,
    onCostItemAdded,
    compact,
    costsheetBookings,
    updateCostsheetBookings,
    handleSelectAll,
    onCostItemEdited = () => {},
    onSelectSingleCostItem = () => {}
  } = props

  const handleSelectAllVoucher = dataSource => () => handleSelectAll?.(dataSource)

  const handleSelectSingleCostItem = costItem => async () =>
    await onSelectSingleCostItem(voucherBooking, costItem)

  const handleCostItemEdited = costItem => newCostItemValues => {
    const bookingUuid = costItem.bookingUuid

    if (bookingUuid) {
      const updatedBookings =
        costsheetBookings &&
        costsheetBookings.map(booking => {
          const updatedBooking = { ...booking }

          updatedBooking.costItems = booking.costItems?.map(ci => {
            if (ci.uuid === newCostItemValues.uuid) {
              return {
                ...ci,
                ...newCostItemValues
              }
            } else {
              return { ...ci }
            }
          })

          return updatedBooking
        })

      updateCostsheetBookings(updatedBookings)

      onCostItemEdited(bookingUuid)
    }
  }

  const handleCostItemDeleted = costItem => deletedCostItem => {
    const bookingUuid = costItem.bookingUuid

    if (bookingUuid) {
      const updatedBookings =
        costsheetBookings &&
        costsheetBookings.map(booking => {
          const updatedBooking = { ...booking }

          updatedBooking.costItems =
            booking.costItems &&
            booking.costItems.filter(ci => {
              return ci.uuid !== deletedCostItem.uuid
            })

          return updatedBooking
        })

      updateCostsheetBookings(updatedBookings)

      onCostItemEdited(bookingUuid)
    }
  }

  const { voucherItems = [] } = selectedVoucher
  const voucherStatus = selectedVoucher.status
  const voucherBookingCostItems = voucherBooking?.costItems || []
  const sgcCompany = selectedGlobalCompany

  let sortedCostItems = sortCostItems(voucherBookingCostItems)

  if (!showDeletedCostItems) {
    sortedCostItems = filter(voucherBookingCostItems, vbci => !vbci.isDeleted)
  }

  const dataSource = sortedCostItems.map((item: CostItem) => {
    return {
      key: item.uuid,
      details: item,
      isNetOff:
        numeral(item.grossProfit).value() !== 0 &&
        numeral(item.grossProfit).subtract(item.estimatedProfit).value() === 0
    }
  })

  const columns: ColumnsType<any> = []

  if (!includes([VoucherStatus.Deleted], voucherStatus)) {
    columns.push({
      title: () => {
        if (voucherStatus === 'NEW') {
          return (
            <Flex justify="center">
              <Button
                ghost
                onClick={handleSelectAllVoucher(dataSource)}
                icon={<ArrowDownOutlined style={{ color: blue.primary }} />}
              />
            </Flex>
          )
        }

        return null
      },
      key: 'action',
      width: '60px',
      render: (text, record, index) => {
        if (record.details.isDeleted) {
          return (
            <DeletedItem>
              <MinusCircleOutlined />
            </DeletedItem>
          )
        }

        const relatedVoucherItems = filter(
          voucherItems,
          ({ costItem, isDeleted }) => costItem.uuid === record.details.uuid && !isDeleted
        )

        return (
          <ActionWrapper>
            <EditCostItemAction
              index={index}
              costItem={record.details}
              onCostItemEdited={handleCostItemEdited(record.details)}
              onCostItemDeleted={handleCostItemDeleted(record.details)}
            />

            {['NEW', VoucherStatus.Draft].includes(selectedVoucher.status) && (
              <VoucherCostItemUsage
                index={index}
                onSelectSingleCostItem={handleSelectSingleCostItem(record.details)}
              />
            )}

            <Badge count={relatedVoucherItems.length} style={{ backgroundColor: '#1890ff' }} />
          </ActionWrapper>
        )
      }
    })
  }

  const colsLookup = {
    description: {
      title: <StyledField>Description</StyledField>,
      dataIndex: 'description',
      key: 'description',
      render: (text, record) => {
        const desc = record.details?.description

        return (
          <MultiRow>
            <CustomText text={record.details?.chargeItem?.code} />{' '}
            <CustomText text={record.details?.chargeItem?.name} />
            <br />
            {desc && <CustomText text={desc} muted />}
          </MultiRow>
        )
      }
    },
    unitSize: {
      title: <StyledField>Unit/Size</StyledField>,
      key: 'unitSize',
      render: (text, record) => (
        <UnitSizeContainer size={record.details?.size} unit={record.details?.unit} />
      )
    },
    quantity: {
      title: <StyledField>QTY</StyledField>,
      dataIndex: 'quantity',
      key: 'quantity',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details?.quantity}
          toRight
          error={getError(record.details.errors, 'quantity')}
        />
      )
    },
    sell: {
      title: <StyledField>Sell</StyledField>,
      dataIndex: 'sell',
      key: 'sell',
      align: 'right',
      render: (text, record) => {
        const showCurrency = sgcCompany?.currency.uuid !== record.details.sellCurrency?.uuid
        const currency = record.details.sellCurrency?.code || 'N/A'
        const baseRate = record.details.sellBaseRate
        const exchangeRate = numeral(record.details.sellExchangeRate).value()

        if (baseRate) {
          return (
            <>
              {showCurrency && <CustomText text={`${currency} `} muted noPadding />}
              <CustomText text={baseRate} isNumeric noPadding />{' '}
              {exchangeRate !== 1 && (
                <>
                  <CustomText text="@" muted />
                  <CustomText text={exchangeRate} format="(0,0.0000)" isNumeric muted noPadding />
                </>
              )}
            </>
          )
        } else {
          return (
            <>
              <CustomText text={baseRate} isNumeric />
            </>
          )
        }
      }
    },
    cost: {
      title: <StyledField>Cost</StyledField>,
      dataIndex: 'cost',
      key: 'cost',
      align: 'right',
      render: (text, record) => {
        const showCurrency = sgcCompany?.currency?.uuid !== record.details.costCurrency?.uuid
        const currency = record.details.costCurrency?.code || 'N/A'
        const baseRate = record.details.costBaseRate
        const exchangeRate = numeral(record.details.costExchangeRate).value()

        if (baseRate) {
          return (
            <>
              {showCurrency && <CustomText text={`${currency} `} muted noPadding />}
              <CustomText text={baseRate} isNumeric noPadding />{' '}
              {exchangeRate !== 1 && (
                <>
                  <CustomText text="@" muted />
                  <CustomText text={exchangeRate} format="(0,0.0000)" isNumeric muted noPadding />
                </>
              )}
            </>
          )
        }

        return <CustomText text="-" />
      }
    },
    sellTotal: {
      title: <StyledField>Sell Total</StyledField>,
      dataIndex: 'sellTotal',
      key: 'sellTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.sellTotal} isNumeric toRight />
    },
    costTotal: {
      title: <StyledField>Cost Total</StyledField>,
      dataIndex: 'costTotal',
      key: 'costTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.details.costTotal} isNumeric toRight />
    },
    ar: {
      title: <StyledField>AR</StyledField>,
      dataIndex: 'ar',
      key: 'ar',
      align: 'right',
      render: (text, record) => {
        const accReceivable = numeral(record.details.accountReceivable).value()
        const accReceivableDraft = numeral(record.details.accountReceivableDraft).value()

        return (
          <>
            {accReceivable === 0 && accReceivableDraft === 0 && <CustomText text="-" noPadding />}
            {accReceivable !== 0 && <CustomText text={accReceivable} isNumeric toRight />}
            {accReceivableDraft !== 0 && (
              <>
                {accReceivable !== 0 && <br />}
                <CustomText text={accReceivableDraft} isNumeric toRight muted noPadding />
              </>
            )}
          </>
        )
      }
    },
    ap: {
      title: <StyledField>AP</StyledField>,
      dataIndex: 'ap',
      key: 'ap',
      align: 'right',
      render: (text, record) => {
        const accPayable = numeral(record.details.accountPayable).value()
        const accPayableDraft = numeral(record.details.accountPayableDraft).value()

        return (
          <>
            {accPayable === 0 && accPayableDraft === 0 && <CustomText text="-" noPadding />}
            {accPayable !== 0 && <CustomText text={accPayable} isNumeric toRight />}
            {accPayableDraft !== 0 && (
              <>
                {accPayable !== 0 && <br />}
                <CustomText text={accPayableDraft} isNumeric toRight muted noPadding />
              </>
            )}
          </>
        )
      }
    },
    accrualCost: {
      title: <StyledField>AP ACC</StyledField>,
      dataIndex: 'accrualCost',
      key: 'accrualCost',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details.chargeItem?.accrualExpenseCode?.code}
          isNumeric
          toRight
          error={getError(record.details.errors, 'accrualCost')}
        />
      )
    },
    accrualRevenue: {
      title: <StyledField>AR ACC</StyledField>,
      dataIndex: 'accrualExpenseCode',
      key: 'accrualExpenseCode',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details.chargeItem?.accrualRevenueCode?.code}
          isNumeric
          toRight
          error={getError(record.details.errors, 'accrualExpenseCode')}
        />
      )
    },
    shortBill: {
      title: <StyledField>Short Bill</StyledField>,
      dataIndex: 'shortBill',
      key: 'shortBill',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details.shortBill}
          isNumeric
          toRight
          isNetOff={record.isNetOff}
          error={getError(record.details.errors, 'shortBill')}
        />
      )
    },
    estimatedProfit: {
      title: <StyledField>Est Profit</StyledField>,
      dataIndex: 'estimatedProfit',
      key: 'estimatedProfit',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details.estimatedProfit}
          isNumeric
          toRight
          isNetOff={record.isNetOff}
          error={getError(record.details.errors, 'estimatedProfit')}
        />
      )
    },
    grossProfit: {
      title: <StyledField>Gross Profit</StyledField>,
      dataIndex: 'grossProfit',
      key: 'grossProfit',
      align: 'right',
      render: (text, record) => (
        <CustomText
          text={record.details.grossProfit}
          isNumeric
          toRight
          isNetOff={record.isNetOff}
          error={getError(record.details.errors, 'grossProfit')}
        />
      )
    }
  }

  if (costItemsView === 'operation') {
    oprColNames.forEach(n => columns.push(colsLookup[n]))
  } else if (costItemsView === 'finance') {
    finColNames.forEach(n => columns.push(colsLookup[n]))
  }

  return (
    <CostItemsTableView
      columns={columns}
      dataSource={dataSource}
      voucherBooking={voucherBooking}
      voucherBookingCostItems={voucherBookingCostItems}
      onCostItemAdded={onCostItemAdded}
      compact={compact}
    />
  )
}

export default withCostItems(VoucherCostItems)
