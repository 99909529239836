import type { EmployeeRolesQuery } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { RoleStatus } from '@/types/graphql'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { EMPLOYEE_ROLES } from './gqlRoles'

type EmployeeRoleSelectProps = SelectProps & {
  query?: any
  noTags?: boolean
  tags?: string[]
}

const EmployeeRoleSelect = (props: EmployeeRoleSelectProps) => {
  const { query, noTags, tags, ...selectProps } = props
  const { onChange, style = { width: '100%' } } = selectProps

  const [roles, setRoles] = useState<NonNullable<NonNullable<EmployeeRolesQuery>['roles']>['rows']>(
    []
  )
  const [q, setQ] = useState()

  const queryInput = useMemo(() => query || { limit: 20 }, [query])
  const input = useMemo(
    () => ({
      ...queryInput,
      statuses: [RoleStatus.Active],
      q,
      noTags,
      tags
    }),
    [queryInput, q, noTags, tags]
  )

  const { data, error, loading, refetch } = useQuery(EMPLOYEE_ROLES, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleChange = useCallback(value => {
    onChange?.(value)
  }, [])

  const onSearch = useCallback(
    debounce(value => {
      setQ(value)
      refetch()
    }, 500),
    []
  )

  useEffect(() => {
    if (setRoles) {
      setRoles(data?.roles?.rows)
    }
  }, [data])

  if (error) {
    logger.error('EmployeeRoleSelect gqlRoles error', error)
    responseHandler(error, 'error')
  }

  const options = roles?.map(role => ({
    label: role?.name,
    value: role?.code
  }))

  return (
    <Select
      {...selectProps}
      allowClear
      showSearch
      style={style}
      loading={loading}
      onSearch={onSearch}
      filterOption={false}
      onChange={handleChange}
      placeholder="Search a role(s)..."
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      options={options}
    />
  )
}

export default memo(EmployeeRoleSelect)
