import { gql } from '@/types'

export default gql(`
  query countriesSelect1($query: String, $limit: Int) {
    countries(query: $query, limit: $limit) {
      rows {
        name
        alpha2
        alpha3
      }
    }
  }
`)
