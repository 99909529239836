import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@apollo/client'
import { Button, Form, Input, Popconfirm, Popover } from 'antd-v5'

import { BASIC_BOOKING_SLIM_QUERY } from '@/containers/booking/schema/basicBookingQuerySlim'
import { CHANGE_BOOKING_STATUS_SLIM } from '@/graphql/booking'
import { logger } from '@/utils/logger'
import respHandler from '@/utils/responseHandler'

const BookingActionButton = ({ type, booking, buttonText, newBookingStatus }) => {
  const [isLoading, setIsLoading] = useState(false)

  const { t } = useTranslation()

  const [showCancelBooking, setShowCancelBooking] = useState(false)

  const [changeBookingStatus] = useMutation(CHANGE_BOOKING_STATUS_SLIM)

  const onBookingAction = async ({ remarks }: any) => {
    if (buttonText === 'Reject' || buttonText === 'Cancel') {
      if (!remarks?.length) return respHandler('Please enter a description.', 'error')
    }
    setIsLoading(true)
    respHandler('Updating booking, hang on...', 'load')

    try {
      const res = await changeBookingStatus({
        variables: {
          uuid: booking.uuid,
          status: newBookingStatus,
          remarks
        },
        refetchQueries: [
          {
            query: BASIC_BOOKING_SLIM_QUERY,
            variables: {
              uuid: booking.uuid
            }
          }
        ]
      })

      if (res?.data?.changeBookingStatus?.uuid) {
        respHandler('Updated successfully.', 'success')
      }
    } catch (error) {
      logger.error('BookingActionButton changeBookingStatus error', error)
      respHandler(error, 'error')
    } finally {
      setIsLoading(false)
    }
  }

  const content = (
    <Form name="cancelBookingForm" autoComplete="off" onFinish={onBookingAction}>
      <Form.Item
        label="Why:"
        name="remarks"
        labelAlign="left"
        rules={[{ required: true, message: 'Description is required.' }]}
      >
        <Input.TextArea rows={4} style={{ width: '100%' }} />
      </Form.Item>

      <div className="flex justify-end gap-10 mt-4">
        <Button onClick={() => setShowCancelBooking(false)}>{t('common.cancel')}</Button>
        <Button type="primary" htmlType="submit" loading={isLoading} disabled={isLoading}>
          {t('common.yes')}
        </Button>
      </div>
    </Form>
  )

  if (buttonText === 'Uncancel') {
    return (
      <Popover
        arrow={false}
        trigger="click"
        content={content}
        placement="bottom"
        open={showCancelBooking}
        title="Uncancel this booking?"
        styles={{ root: { width: 200 } }}
        onOpenChange={visible => setShowCancelBooking(visible)}
      >
        <Button type={type} ghost loading={isLoading} disabled={isLoading}>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  if (buttonText === 'Reject' || buttonText === 'Cancel') {
    return (
      <Popover
        arrow={false}
        trigger="click"
        content={content}
        placement="bottom"
        open={showCancelBooking}
        styles={{ root: { width: 200 } }}
        onOpenChange={visible => setShowCancelBooking(visible)}
        title={buttonText === 'Cancel' ? 'Cancel this booking?' : 'Reject this booking?'}
      >
        <Button type={type} ghost loading={isLoading} disabled={isLoading}>
          {buttonText}
        </Button>
      </Popover>
    )
  }

  if (['KIV', 'UnKIV'].includes(buttonText)) {
    return (
      <Button type={type} loading={isLoading} disabled={isLoading} onClick={onBookingAction}>
        {buttonText}
      </Button>
    )
  }

  return (
    <Popconfirm
      arrow={false}
      okType={type}
      placement="bottom"
      okText={t('common.yes')}
      cancelText={t('common.no')}
      title={t('instructions.areYouSure')}
      onConfirm={onBookingAction}
    >
      <Button
        id="confirm-accept-button"
        danger={type === 'danger'}
        type={type}
        loading={isLoading}
        disabled={isLoading}
      >
        {buttonText}
      </Button>
    </Popconfirm>
  )
}

export default BookingActionButton
