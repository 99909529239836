import { EntityMiscellaneousFieldTags } from '@/types/graphql'

import { Popover, Typography } from 'antd-v5'
import { startCase } from 'lodash'
import moment from 'moment'

export const getEntitySelectorColor = (entity: any) => {
  const dates = entity.miscFieldTypes
    .filter(f => f.tags.includes(EntityMiscellaneousFieldTags.IsExp))
    .map(f => entity.miscFields?.[f.key])
    .filter(Boolean)
  const hasExpired = dates.some(date => moment(date).isBefore(moment(), 'day'))
  const hasExpiringSoon = dates.some(date =>
    moment(date).isBetween(moment(), moment().add(21, 'days'), 'day', '[]')
  )
  const color = hasExpired ? 'red' : hasExpiringSoon ? '#FFC000' : 'inherit'
  return { color }
}

export const getLabelExpiry = (entity: any) => {
  const dates = entity.miscFieldTypes
    .filter(f => f.tags.includes(EntityMiscellaneousFieldTags.IsExp))
    .map(f => ({
      key: f.key,
      value: entity.miscFields?.[f.key]
    }))

  const content = (
    <div>
      <Typography.Text strong>Expiry Dates</Typography.Text>
      <ul className="pl-2 m-0">
        {dates.map(date => (
          <li key={date.key}>
            <Typography.Text>
              <strong>{startCase(date.key)}: </strong>
              {date.value ? moment(date.value).format('DD/MM/YYYY') : 'N/A'}
            </Typography.Text>
          </li>
        ))}
      </ul>
    </div>
  )

  return (
    <Popover placement="rightTop" arrow={false} content={content}>
      <Typography.Text style={getEntitySelectorColor(entity)}>
        {entity?.code} - {entity?.registration}
      </Typography.Text>
    </Popover>
  )
}
