import type { User } from '@/types/graphql'

import { memo } from 'react'
import { Avatar, Tooltip } from 'antd-v5'

type UserIconProps = {
  user: User
}

const UserIcon = ({ user }: UserIconProps) => {
  return (
    <Tooltip title={`${user.nickname} <${user.email}>`}>
      <Avatar src={user.picture ?? ''} size={16} style={{ marginLeft: 10 }} />
    </Tooltip>
  )
}

export default memo(UserIcon)
