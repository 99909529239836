import { gql } from '@/types'

export default gql(`
  query company5($uuid: UUID!) {
    company(uuid: $uuid) {
      uuid
      code
      name
    }
  }
`)
