import type { JobEs } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'

import { memo, useCallback, useEffect, useMemo, useState } from 'react'
import { useLazyQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'
import { isUuid } from '@/utils/u'

type TransportJobSelectorProps = SelectProps & {
  bookingUuids?: string[]
  jobUuids?: (string | undefined)[]
  queryOnMount?: boolean
}

export const JOBS_SELECTOR_QUERY = gql(`
  query jobsSelectEs($input: JobsSearchInput) {
    jobsSearchJson(input: $input) {
      rows
    }
  }
`)

const TransportJobSelector = (props: TransportJobSelectorProps) => {
  const { bookingUuids, jobUuids, queryOnMount = false, ...selectProps } = props
  const { value, mode } = selectProps

  const [jobs, setJobs] = useState<JobEs[]>([])
  const [searchInput, setSearchInput] = useState('')

  const input = useMemo(
    () => ({
      q: (!mode && !isUuid(value) && value) || searchInput || '',
      filter: {
        jobUuids:
          !searchInput && jobUuids?.filter(Boolean)?.length ? jobUuids?.filter(Boolean) : null,
        bookingUuid: bookingUuids?.filter(Boolean)?.length ? bookingUuids?.filter(Boolean) : null
      },
      limit: 20
    }),
    [mode, jobUuids, value, searchInput, bookingUuids]
  )

  const [getJobs, { data, error, loading }] = useLazyQuery(JOBS_SELECTOR_QUERY, {
    variables: { input },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce((value: string) => {
      setSearchInput(value)
      getJobs()
    }, 500),
    []
  )

  useEffect(() => {
    if (queryOnMount || value) {
      getJobs()
    }
  }, [queryOnMount, value, getJobs])

  useEffect(() => {
    if (setJobs) {
      setJobs(data?.jobsSearchJson?.rows)
    }
  }, [data])

  if (error) {
    logger.error('TransportJobSelector TRANSPORT_JOBS_SELECTOR_QUERY error', error)
    responseHandler(error, 'error')
  }

  const fetchOnFocus = () => {
    if (queryOnMount) return
    getJobs()
  }

  const options = jobs?.map(j => ({
    label: [j.jobNo, j.no].filter(Boolean).join(' - '),
    value: j.uuid
  }))

  return (
    <Select
      id={'jobUuid-selector'}
      allowClear
      filterOption={false}
      loading={loading}
      mode={mode}
      onFocus={fetchOnFocus}
      notFoundContent={loading ? 'Searching...' : 'Not found.'}
      onSearch={handleSearch}
      placeholder="Search a job here..."
      showSearch
      value={value}
      options={options}
      {...selectProps}
    />
  )
}

export default memo(TransportJobSelector)
