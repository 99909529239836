import type { Maybe, TransportJob } from '@/types/graphql'

import { memo, useCallback, useMemo } from 'react'
import { Flex, Typography } from 'antd-v5'

interface LegDetailsDisplayProps {
  leg?: TransportJob | null
}

const LegDetailsDisplay = ({ leg }: LegDetailsDisplayProps) => {
  const mergeValues = useCallback(
    (array: Array<Maybe<string> | Maybe<number> | undefined>): string => {
      return array?.filter((elem: Maybe<string> | Maybe<number> | undefined) => elem)?.join(' - ')
    },
    []
  )

  const legDetails = useMemo(
    () => [
      {
        label: 'Leg',
        value: mergeValues([leg?.sequence, leg?.legStatus])
      },
      {
        label: 'Transporter',
        value: leg?.transportName
      },
      {
        label: 'Source',
        value: leg?.transportSource
      },
      {
        label: 'Driver',
        value: mergeValues([leg?.driverCode, leg?.driverName])
      },
      {
        label: 'Assistants',
        value: leg?.assistants?.map(assistant => mergeValues([assistant?.code, assistant?.name]))
      },
      {
        label: 'Vehicle',
        value: mergeValues([leg?.vehicleCode, leg?.vehicleName])
      },
      {
        label: 'Trailer',
        value: mergeValues([leg?.trailerCode, leg?.trailerName])
      },
      {
        label: 'Remarks',
        value: leg?.remarks
      }
    ],
    [leg]
  )

  return (
    <Flex vertical>
      {legDetails.map(detail => (
        <Flex align="center" key={detail.label}>
          <Flex flex={0.4} justify="end" className="pr-2">
            <Typography.Text strong type="secondary">{`${detail.label} : `}</Typography.Text>
          </Flex>
          <Flex flex={0.6}>
            <Typography.Text strong id={detail.label as string}>
              {detail.value ?? '-'}
            </Typography.Text>
          </Flex>
        </Flex>
      ))}
    </Flex>
  )
}

export default memo(LegDetailsDisplay)
