import type { SelectProps } from 'antd-v5'

import { useEffect } from 'react'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'

import { BOOKING_COST_ITEMS } from '@/components/Select/BookingSelect/schema'

type CostItemSelectProps = SelectProps & {
  bookingUuid: string
  autoSelect?: boolean
}

const CostItemSelect = (props: CostItemSelectProps) => {
  const { autoSelect = true, bookingUuid, ...selectProps } = props
  const { placeholder = 'Search cost item...', onChange, value } = selectProps

  const { data, loading } = useQuery(BOOKING_COST_ITEMS, {
    variables: { uuid: bookingUuid }
  })

  useEffect(() => {
    const costItems = data?.booking?.costItems ?? []

    if (autoSelect && costItems.length === 1) onChange?.(costItems[0]?.uuid, costItems[0]?.uuid)
    if (costItems.length === 0 && value) onChange?.(null as any, null as any)
  }, [data, autoSelect])

  const options = data?.booking?.costItems?.map(costItem => ({
    label: [costItem?.chargeItem?.code, costItem?.chargeItem?.description, costItem?.sellRate]
      .filter(Boolean)
      .join(' - '),
    value: costItem?.uuid
  }))

  return <Select {...props} loading={loading} placeholder={placeholder} options={options} />
}

export default CostItemSelect
