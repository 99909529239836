import { Component } from 'react'
import { Button, Popconfirm } from 'antd-v5'

class ConfirmationControl extends Component {
  render() {
    const { icon, title, okText, onConfirm, cancelText, buttonType, buttonText } = this.props

    return (
      <Popconfirm
        arrow={false}
        title={title}
        okText={okText}
        okType={buttonType}
        cancelText={cancelText}
        onConfirm={onConfirm}
      >
        <Button danger={buttonType === 'danger'} type={buttonType} icon={icon}>
          {buttonText}
        </Button>
      </Popconfirm>
    )
  }
}

export default ConfirmationControl
