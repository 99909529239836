import { gql } from '@/types'

export const UPDATE_TRIPS_MUTATION = gql(`
  mutation updateTrips($input: UpdateTripsInput) {
    updateTrips(input: $input) {
      success
    }
  }
`)

export const BASIC_BOOKING_OPTIMISED_ROT = gql(`
  query basicBookingOtimisedRot($uuid: UUID, $no: String) {
    booking(uuid: $uuid, no: $no) {
      assignees {
        uuid
        type
        user {
          name
          uuid
          nickname
          familyName
          email
          picture
        }
      }
      createdAt
      uuid
      no
      status
      stat
      lStat
      remarks
      details
      quotation {
        quotationNo
        uuid
      }
      companies {
        name
        uuid
        code
      }
      addresses {
        uuid
        name
        address1
        address2
        address3
        address4
        city
        district
        postCode
        areaCode
        zone
        countryAlpha3
      }
      places {
        placeId
        name
        country {
          name
          alpha2
          alpha3
        }
      }
      state {
        key
        value
      }
      type
      isCloseOperation
      isCloseFinance
      billTo {
        uuid
        name
        code
        debtorTerm
        creditorTerm
      }
      permissions {
        canImpLinerOverrideIncompleteDocs
        canImpLinerOverrideDoubleVoucher
        canImpLinerOverrideDoubleInvoice
      }
      commodities
      jobs {
        bookingUuid
        uuid
        no
        jobNo
        status
        remarks
        details
        trips {
          uuid
          sequence
        }
      }
      serviceTypes
      tags
      processFlow {
        type
        sections {
          name
          type
          display
          steps {
            key
            helpText
            isComplete
            isCurrentStep
            permission {
              canApprove
              canReject
            }
          }
        }
      }
      quotation {
        uuid
        quotationNo
      }
      chronologies {
        date
        type
        jobUuid
        tripUuid
      }
    }
  }
`)
