import type { DynamicField } from '@/types/graphql'
import {
  DynamicFieldControl,
  DynamicFieldQuery,
  DynamicFieldTag,
  DynamicFieldType
} from '@/types/graphql'

import { forwardRef, useEffect } from 'react'
import { DatePicker } from 'antd'
import { Checkbox, Input, Typography } from 'antd-v5'
import { Marked } from 'marked'

import { CategorySelectQuery } from '@/components/Booking/DynamicField/CategorySelectQuery'
import DynamicInputText from '@/components/Booking/DynamicField/DynamicInputText'
import DynamicFieldSelector from '@/components/Booking/DynamicField/FieldSelector'
import {
  DYNAMIC_FIELD_DATE_FORMAT,
  getDynamicFieldInitialValue,
  getDynamicFieldLookupKey,
  hasDynamicFieldTag
} from '@/components/Booking/DynamicField/helper'
import PlaceSelector from '@/components/Booking/DynamicField/PlaceSelector'
import PortPlaceSelector from '@/components/Booking/DynamicField/PortPlaceSelector'
import UploadDocument from '@/components/Booking/DynamicField/UploadDocument'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import EnumSelector from '@/components/Shared/EnumSelector'
import TagsInput from '@/components/Shared/TagsInput/TagsInput'

type BookingDynamicFieldProps = {
  form: any
  mode: FormMode
  dynamicField: DynamicField | null
  duplicateBookingObject?: any
  emptyDate?: boolean
}

const marked = new Marked()

marked.use({
  gfm: true,
  breaks: true,
  renderer: {
    link({ tokens, href }) {
      const text = this.parser.parseInline(tokens)
      return `<a target="_blank" href="${href}">${text}</a>`
    }
  }
})

const BookingDynamicField = forwardRef((props: BookingDynamicFieldProps, ref) => {
  const { form, duplicateBookingObject, dynamicField, mode, emptyDate } = props

  useEffect(() => {
    if (dynamicField?.query !== DynamicFieldQuery.Quotation) return
    if (!form.getFieldValue('billToUuid')) return
  }, [form, dynamicField])

  const createMarkup = (content: string) => ({ __html: marked.parse(content) })

  const renderField = (renderProps: DynamicField | null) => {
    const { key, query: queryType, type, customValues } = renderProps || {}

    const defaultRule = {
      required: dynamicField?.control === DynamicFieldControl.Required,
      message: `${key} is required`
    }

    const fieldKey = getDynamicFieldLookupKey(key || '', mode, type, duplicateBookingObject)

    switch (type) {
      case DynamicFieldType.Category: {
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule]
        })(<CategorySelectQuery categoryId={renderProps?.categoryId} />)
      }
      case DynamicFieldType.Selector:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          (() => {
            switch (queryType) {
              case DynamicFieldQuery.Place:
                return <PlaceSelector />
              case DynamicFieldQuery.Port:
                return <PortPlaceSelector mode={mode} />
              case DynamicFieldQuery.Enums:
                return (
                  <EnumSelector
                    placeholder="Select a value..."
                    enumName={dynamicField?.enumName as string}
                    mode={
                      hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiple)
                        ? 'multiple'
                        : undefined
                    }
                  />
                )
              default: {
                if (queryType === DynamicFieldQuery.CustomValues && !customValues) {
                  return <TagsInput placeholder={`Enter ${key}`} />
                }
                return (
                  <DynamicFieldSelector
                    dynamicField={dynamicField}
                    autoSelectFirst={queryType === DynamicFieldQuery.Quotation}
                  />
                )
              }
            }
          })()
        )

      case DynamicFieldType.Date:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode,
            emptyDate
          )
        })(
          <DatePicker
            id="dynamic-field-date-picker"
            showTime
            format={DYNAMIC_FIELD_DATE_FORMAT}
            disabled={mode === FormMode.Edit}
            placeholder="Select a date and time"
            // @ts-ignore
            getCalendarContainer={trigger => trigger.parentNode}
          />
        )

      case DynamicFieldType.Document:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          <UploadDocument
            fieldKey={fieldKey}
            data={form.getFieldValue(fieldKey)}
            setFieldsValue={form.setFieldsValue}
            isUploadable={mode !== FormMode.Edit}
            handleDocumentUpload={(acceptedFiles: File) => acceptedFiles}
          />
        )

      case DynamicFieldType.Boolean:
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          ),
          valuePropName: 'checked'
        })(
          <Checkbox>
            <Typography.Text dangerouslySetInnerHTML={createMarkup(customValues || '')} />
          </Checkbox>
        )

      default: {
        return form.getFieldDecorator(fieldKey, {
          rules: [defaultRule],
          initialValue: getDynamicFieldInitialValue(
            fieldKey,
            dynamicField,
            duplicateBookingObject,
            mode
          )
        })(
          hasDynamicFieldTag(dynamicField, DynamicFieldTag.Multiline) ? (
            <Input.TextArea rows={4} autoComplete="off" placeholder={`Enter ${key}`} />
          ) : (
            <DynamicInputText
              isCapitalize={hasDynamicFieldTag(dynamicField, DynamicFieldTag.Capitalize)}
            />
          )
        )
      }
    }
  }

  return renderField(dynamicField)
})

export default BookingDynamicField
