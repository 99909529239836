import type { EventsProps } from '@/components/Shared/Events'

import { memo, useMemo } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, List, Spin } from 'antd-v5'
import orderBy from 'lodash/orderBy'

import EventListItem from '@/components/Shared/Events/EventListItem'
import { NoEvents } from '@/components/Shared/Events/Styled'
import NoAccess from '@/components/Shared/NoAccess'
import { ErrorWrapper } from '@/components/TableView/Styled'
import config from '@/config'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { EVENTS_ETC } from '@/pages/manage/logs/schema'
import { logger } from '@/utils/logger'

const EventList = memo((props: EventsProps) => {
  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'event',
    permission: 'view'
  })

  const { loading, error, data } = useQuery(props.gql || EVENTS_ETC, {
    variables: props.variables,
    fetchPolicy: 'cache-first',
    skip: !hasPermission || !props.variables
  })

  const events = useMemo(
    () =>
      (data?.booking?.events || data?.eventsEtc?.rows || []).map(e => ({
        ...e,
        time: e.time || e.createdAt,
        event: e.event || e.action,
        model: e.model || props.variables?.input?.model
      })),
    [data]
  )
  const sortedEvents = useMemo(() => orderBy(events, e => e.time, 'desc'), [events])

  if (permLoading || loading) {
    return (
      <Flex align="center" justify="center" style={{ height: 200 }}>
        <Spin />
      </Flex>
    )
  }

  if (!hasPermission) return <NoAccess />

  if (error) {
    logger.error('EventList Error.', error)
    return <ErrorWrapper>{config.anErrorOccurredPleaseTryAgainLater}</ErrorWrapper>
  }

  if (!events?.length) return <NoEvents>No events</NoEvents>

  return <List dataSource={sortedEvents} renderItem={event => <EventListItem event={event} />} />
})

export default EventList
