import { gql } from '@/types'

export const EMPLOYEE_ROLES = gql(`
  query employeeRoles($input: RolesQuery) {
    roles(input: $input) {
      rows {
        uuid
        name
        code
        status
        updatedAt
        createdAt
        editBy {
          name
          email
        }
        editByUuid
      }
    }
  }
`)
