import type { Job, TransportJob, UpdateJobInput } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'

import { useCallback, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { CheckOutlined, EditOutlined } from '@ant-design/icons'
import { useMutation, useQuery } from '@apollo/client'
import { Form } from 'antd'
import { Alert, Button, Col, Flex, Spin } from 'antd-v5'

import {
  QUERY_JOB,
  UPDATE_TRIP
} from '@/components/Transport/Components/Modals/JobActivityModal/job-trip-schema'
import JobFormDetails from '@/components/Transport/Components/Modals/JobActivityModal/JobFormDetails'
import { UPDATE_JOB } from '@/graphql/jobs'
import useJobTypes from '@/hooks/useJobTypes'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

interface LegFormDisplayProps {
  form: WrappedFormUtils
  value?: any
  leg?: TransportJob | null
  legsLoading: boolean | undefined
}

const JobContainerSealDisplay = ({ value, form, leg, legsLoading }: LegFormDisplayProps) => {
  const { t } = useTranslation()
  const { requestJobTypes, jobTypes } = useJobTypes()

  const [updateJobMutation, { loading: loadingJob }] = useMutation(UPDATE_JOB)
  const [updateTripMutation, { loading: loadingTrip }] = useMutation(UPDATE_TRIP)
  const {
    error,
    loading,
    refetch,
    data: jobResults
  } = useQuery(QUERY_JOB, {
    skip: !leg?.jobUuid,
    variables: { uuid: leg?.jobUuid },
    fetchPolicy: 'cache-first'
  })

  const [job, setJob] = useState<Job>({})
  const [locLeg, setLocLeg] = useState<any>(leg)
  const [editJobMode, setEditJobMode] = useState(true)
  const [jobFormError, setJobFormError] = useState('')
  const [dynamicFields, setDynamicFields] = useState<any>()
  const [jobFormSuccessMsg, setJobFormSuccessMsg] = useState('')
  const [tripDynamicFields, setTripDynamicFields] = useState<any>()

  useEffect(() => {
    setDynamicFields(jobTypes[0]?.dynamicFields?.map(i => i?.key) || [])
    setTripDynamicFields(jobTypes[0]?.tripDynamicFields?.map(i => i?.key) || [])
  }, [jobTypes])

  useEffect(() => {
    if (jobResults?.job) {
      setJob(jobResults.job)
    }
  }, [jobResults])

  const onSwitchEditJobMode = useCallback(() => {
    setEditJobMode((state: boolean) => !state)
    setJobFormSuccessMsg('')
    setJobFormError('')
  }, [])

  const onUpdateJob = useCallback(() => {
    form.validateFields(async (err: any, values: any) => {
      if (err) return

      const jobDetails = {}
      const tripDetails = {}
      dynamicFields?.forEach(key => {
        if (values?.job?.details && key in values.job.details) {
          jobDetails[key] = values.job.details[key]
        }
      })

      tripDynamicFields?.forEach(key => {
        if (values?.trip?.details && key in values.trip.details) {
          tripDetails[key] = values?.trip.details[key]
        }
      })
      try {
        setJobFormSuccessMsg('')
        setJobFormError('')

        const updateObj: UpdateJobInput = {
          uuid: leg?.jobUuid,
          details: jobDetails
        }
        setJobFormSuccessMsg('Saving job details...')

        const jobRes = await updateJobMutation({ variables: { input: updateObj } })
        await updateTripMutation({
          variables: {
            input: {
              uuid: leg?.tripUuid,
              details: tripDetails
            }
          }
        })

        if (jobRes?.data?.updateJob?.uuid) {
          refetch()
          onSwitchEditJobMode()

          responseHandler('Successfully updated job.', 'success')
        } else {
          setJobFormSuccessMsg('')
          responseHandler('No job selected for update.', 'warning')
        }

        setLocLeg({ ...locLeg, tripSeal: values?.trip?.seal })
      } catch (error: unknown) {
        responseHandler(error, 'error')
        logger.error('UpdateLegForm updateJobMutation error', error)
        setJobFormSuccessMsg('')
        if (error instanceof Error) {
          setJobFormError(error.message)
        } else {
          setJobFormError(JSON.stringify(error))
        }
      }
    })
  }, [form, leg, updateJobMutation])

  useEffect(() => {
    if (!job.type) return
    requestJobTypes({ codes: [job.type] })
  }, [job.type])

  if (loading || loadingJob || loadingTrip) return <Spin />

  if (error) return <Alert message={error.message} type="error" />

  return (
    <Col xs={24} xl={24} span={8}>
      <JobFormDetails job={job} leg={locLeg} editMode={editJobMode} value={value} form={form} />

      <Flex vertical align="flex-end">
        {jobFormSuccessMsg && (
          <Alert
            type="success"
            message={jobFormSuccessMsg}
            style={{ fontSize: 12, width: '80%' }}
          />
        )}
        {jobFormError && (
          <Alert type="error" message={jobFormError} style={{ fontSize: 12, width: '80%' }} />
        )}
      </Flex>

      <Flex justify="flex-end" gap={10}>
        <Button loading={legsLoading} onClick={onSwitchEditJobMode}>
          {editJobMode ? t('common.cancel') : t('common.edit')}
          {!editJobMode && <EditOutlined />}
        </Button>

        {editJobMode && (
          <Button loading={loadingJob} onClick={onUpdateJob}>
            {t('common.submit')} <CheckOutlined />
          </Button>
        )}
      </Flex>
    </Col>
  )
}

export default Form.create<LegFormDisplayProps>()(JobContainerSealDisplay)
