import { BOOKING_TYPES_QUERY } from '@/components/Manage/BookingTypes/schema'
import CrudTypesTableView from '@/components/Manage/Shared/CrudType/TableView'

const BookingTypesTableView = () => (
  <CrudTypesTableView
    gqlQuery={BOOKING_TYPES_QUERY}
    crudType="bookingType"
    articleUrl="https://shipxhelp.freshdesk.com/support/solutions/articles/47001259855-booking-type"
  />
)

export default BookingTypesTableView
