import { gql } from '@/types'

export const USER_PREFERENCE_QUERY = gql(`
  query userPreferences1($type: String!, $key: String!) {
    userPreferences(type: $type, key: $key) {
      type
      key
      value
    }
  }
`)

export const UPDATE_USER_PREFERENCE_MUTATION = gql(`
  mutation updateUserPreference($type: String!, $key: String!, $value: String!) {
    updateUserPreference(type: $type, key: $key, value: $value) {
      type
      key
      value
    }
  }
`)

export const DELETE_USER_PREFERENCE_MUTATION = gql(`
  mutation deleteUserPreference($type: String!, $key: String!) {
    deleteUserPreference(type: $type, key: $key) {
      success
    }
  }
`)
