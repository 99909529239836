import { memo } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { Flex } from 'antd-v5'

const SignOff = ({ message }: { message: string }) => {
  return (
    <Flex vertical gap={10} align="center" justify="center" className="h-screen">
      <div className="load-text">{message || 'Signing off...'}</div>
      <LoadingOutlined />
    </Flex>
  )
}

export default memo(SignOff)
