import type { TransportJob } from 'App/types/graphql'

import { memo, useMemo } from 'react'
import { CheckOutlined, CopyOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Col, Row, Typography } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'

import { copyIconStyle } from '@/components/Shared/CopyIconStyle'
import { isActiveLeg, mergeAddressFull } from '@/components/Transport/Utils/jobHelper'

type UpdateJobFormPropsType = {
  form?: any
  handleModalOk?: any
  trip?: any
  leg?: any
  updateJobLoading?: boolean
}

const UpdateJobForm = ({ trip, leg }: UpdateJobFormPropsType) => {
  const transportTypeSize = useMemo(
    () => [leg?.transportType, leg?.size].filter((a: string) => a),
    [leg]
  )

  const clonedLegs = useMemo(() => cloneDeep(trip), [trip])
  const firstActiveLeg = useMemo(
    () => clonedLegs?.find((leg: TransportJob) => isActiveLeg(leg)),
    [clonedLegs]
  )
  const fromNameZone = useMemo(
    () =>
      `${firstActiveLeg?.from?.name}${
        firstActiveLeg?.from?.zone ? ' (' + firstActiveLeg?.from?.zone + ')' : ''
      }`,
    [firstActiveLeg]
  )
  const fromAddress = useMemo(() => mergeAddressFull(firstActiveLeg?.from), [firstActiveLeg])

  const legsFromLatest = useMemo(
    () => clonedLegs?.sort((a: any, b: any) => b.sequence - a.sequence),
    [clonedLegs]
  )
  const lastActiveLeg = useMemo(
    () => legsFromLatest?.find((leg: TransportJob) => isActiveLeg(leg)),
    [legsFromLatest]
  )
  const toNameZone = useMemo(
    () =>
      `${lastActiveLeg?.to?.name}${
        lastActiveLeg?.to?.zone ? ' (' + lastActiveLeg?.to?.zone + ')' : ''
      }`,
    [lastActiveLeg]
  )
  const toAddress = useMemo(() => mergeAddressFull(lastActiveLeg?.to), [lastActiveLeg])

  return (
    <Form>
      <Row align="middle" gutter={24}>
        <FieldName name="Job No :" value={leg?.jobNo} copyable />
        <FieldName name="Bill To :" value={leg?.billToName} copyable />
      </Row>

      <Row justify="space-between" align="middle" gutter={24}>
        <FieldName
          name="Shipper Ref :"
          value={leg?.shipperRef || '-'}
          copyable={!!leg?.shipperRef}
        />
        <FieldName
          name="Type :"
          value={transportTypeSize?.join('/') || '-'}
          copyable={!!leg?.transportType}
        />
      </Row>

      <Row justify="space-between" align="middle" gutter={24}>
        <FieldName name="From :" value={fromNameZone} copyable />
        <FieldName name="To :" value={toNameZone} copyable />
      </Row>

      <Row justify="space-between" align="middle" gutter={24}>
        <FieldName name="" value={fromAddress} copyable />
        <FieldName name="" value={toAddress} copyable />
      </Row>
    </Form>
  )
}

export default UpdateJobForm

export const FieldName = memo(({ name, value, copyable }: any) => {
  const copyableConfig = copyable
    ? {
        text: value,
        icon: [copyIconStyle(CopyOutlined), copyIconStyle(CheckOutlined)]
      }
    : undefined

  return (
    <Col xs={24} sm={12}>
      <Row>
        <Col xs={8} sm={5}>
          <Typography.Text strong>{name}</Typography.Text>
        </Col>
        <Col xs={16} sm={19}>
          <Typography.Text type="secondary" copyable={copyableConfig}>
            {value}
          </Typography.Text>
        </Col>
      </Row>
    </Col>
  )
})
