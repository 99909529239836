import { Component } from 'react'
import { DatePicker } from 'antd'
import { Col, Flex } from 'antd-v5'

const disabledStartDate = endValue => startValue => {
  if (!startValue || !endValue) {
    return false
  }
  return startValue.valueOf() > endValue.valueOf()
}

const disabledEndDate = startValue => endValue => {
  if (!endValue || !startValue) {
    return false
  }
  return endValue.valueOf() <= startValue.valueOf()
}

// Compared to Ant Design default RangePicker component,
// this component allows you to select only
// either one start date, end date, or both.
//
// Usage: the component accepts the following value:
// {
//   from: moment,
//   to: moment
// }
// Upon value change, it will call onChange
// with the above value object structure.
class DateRangePicker extends Component {
  handleChange = typeDate => date => {
    const { value, onChange } = this.props

    if (onChange) {
      onChange({
        ...value,
        [typeDate]: date
      })
    }
  }

  render() {
    const { value } = this.props

    return (
      <Flex gap={8} justify="start">
        <Col>
          <DatePicker
            placeholder="Start date"
            disabledDate={disabledStartDate(value?.to)}
            value={value?.from}
            onChange={this.handleChange('from')}
          />
        </Col>
        <Col>-</Col>
        <Col>
          <DatePicker
            placeholder="End date"
            disabledDate={disabledEndDate(value?.from)}
            value={value?.to}
            onChange={this.handleChange('to')}
          />
        </Col>
      </Flex>
    )
  }
}

export default DateRangePicker
