import type { NoticeType } from 'antd-v5/es/message/interface'

import { message, notification } from 'antd-v5'

import auth, { LOCAL_STORAGE_KEYS, namespace } from './auth'
import { handleFatal } from './responseHandler'
import webStorage from './webStorage'

type NoticeTypeWithLoad = NoticeType | 'load' | 'close'

export default (
  response: { title: string; description?: string | null } | string,
  type: NoticeTypeWithLoad,
  duration?: number
) => {
  message.destroy()

  switch (type) {
    case 'success':
      notification.success({
        message: typeof response === 'string' ? response : response.title,
        description: typeof response !== 'string' && response.description,
        duration: duration ?? 5,
        key: typeof response === 'string' ? response : response.title,
        style: { margin: 0 }
      })
      break

    case 'error':
      {
        const refreshToken = webStorage.getItem(LOCAL_STORAGE_KEYS.REFRESH_TOKEN)
        const profile = webStorage.getItem(LOCAL_STORAGE_KEYS.PROFILE)

        if (
          refreshToken &&
          (auth.hasLoggedOutError(typeof response === 'string' ? response : response.title) ||
            auth.jwtIsExpiring()) &&
          (profile.baseCompanyUuids?.length ||
            profile[namespace]?.app_metadata?.baseCompanyUuids?.length)
        ) {
          // auth.useRefreshToken() should have been triggered from initApollo
          message.loading('Re-logging in, please wait...', 0)
        } else {
          console.error(response)
          const error = handleFatal(typeof response === 'string' ? response : response.title)
          notification.error({
            message: typeof response === 'string' ? response : response.title,
            description: (typeof response !== 'string' && response.description) || error.message,
            duration: duration || 10,
            key: typeof response === 'string' ? response : response.title || error.message,
            style: { margin: 0 }
          })
        }
      }
      break

    case 'loading':
    case 'load':
      message.loading(typeof response === 'string' ? response : response.title, duration)

      break
    case 'warning':
      notification.warning({
        message: typeof response === 'string' ? response : response.title,
        description: typeof response !== 'string' && response.description,
        duration: duration || 5,
        key: typeof response === 'string' ? response : response.title,
        style: { margin: 0 }
      })
      break

    case 'info':
      notification.info({
        message: typeof response === 'string' ? response : response.title,
        description: typeof response !== 'string' && response.description,
        duration: duration || 5,
        key: typeof response === 'string' ? response : response.title,
        style: { margin: 0 }
      })
      break
  }
}
