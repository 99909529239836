import type { JobPreSubmitProps } from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import type { Job, UpdateJobInput } from '@/types/graphql'
import { JobStatus, TripStatus } from '@/types/graphql'

import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { EditOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Form } from 'antd'
import { Button, Col, Divider, Modal, notification, Row } from 'antd-v5'
import pick from 'lodash/pick'
import uniq from 'lodash/uniq'

import { getTrimmedDetails } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import JobForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm/JobsForm/JobForm'
import { FormMode } from '@/components/Manage/Shared/CrudType/Form'
import RolloverAction from '@/components/Workflow/RolloverAction'
import { withBooking } from '@/contexts/booking'
import { UPDATE_JOB } from '@/graphql/jobs'
import useJobTypes from '@/hooks/useJobTypes'
import { useBookingStore } from '@/store/booking'
import { logger } from '@/utils/logger'
import JobDeleteAction from './JobDeleteAction'
import JobUndeleteAction from './JobUndeleteAction'
import { UPDATE_TRIPS_MUTATION } from './schema'

interface JobEditActionProps {
  job: Job
  data: any
}

const JobEditAction = ({ job, data }: JobEditActionProps) => {
  const { t } = useTranslation()

  const [jobUuid] = useState(job.uuid)
  const [jobsArray, setJobsArray] = useState([job])
  const [hasDeleted, setHasDeleted] = useState(false)
  const [displayModal, setDisplayModal] = useState(false)

  const { requestJobTypes, jobTypes } = useJobTypes()

  const hideDeletedTrip = useBookingStore.use.hideDeletedTrip()
  const setHideDeletedTrip = useBookingStore.use.setHideDeletedTrip()

  const [updateJobMutation] = useMutation(UPDATE_JOB)
  const [updateTripsMutation] = useMutation(UPDATE_TRIPS_MUTATION)

  const handleModalCancel = () => setDisplayModal(false)
  const handleDisplayModal = () => setDisplayModal(true)

  const handleModalOk = async () => {
    const job = jobsArray[0]
    const tripsInput = job?.trips?.map(trip => {
      return {
        ...pick(trip, [
          'uuid',
          'fromUuid',
          'toUuid',
          'remarks',
          'references',
          'seal',
          'status',
          'type',
          'details'
        ])
      }
    })

    // validate tripsInput
    const invalidTrips = tripsInput
      ?.map((trip, index) => {
        if (!trip.fromUuid || !trip.toUuid) return index + 1 // Assuming trip number is its index + 1
        return null
      })
      .filter(Boolean)

    if (invalidTrips?.length) {
      return notification.error({
        message: 'Validation Error',
        description: `Trip #${invalidTrips.join(', #')} must have both from and to.`
      })
    }

    const formattedJob: UpdateJobInput = {
      details: getTrimmedDetails(
        'job',
        job.details,
        jobTypes.find(j => j.code === job.type)
      ),
      no: job.no,
      remarks: job.remarks,
      reference: job.reference,
      uuid: jobUuid,
      type: job.type,
      tripFormat: job.tripFormat
    }

    try {
      await updateJobMutation({ variables: { input: { ...formattedJob } } })

      await updateTripsMutation({
        variables: {
          input: {
            jobUuid,
            trips: tripsInput
          }
        }
      })

      data.refetch()

      notification.success({
        message: 'Submit Notice',
        description: 'Successfully updated Job'
      })

      setDisplayModal(false)
    } catch (error: any) {
      logger.error('Update Job Error', error)
      return notification.error({
        message: 'Error',
        description: error.message.replace('GraphQL error: ', '')
      })
    }
  }

  useEffect(() => {
    const jobTypesKeys = uniq(jobsArray.map(job => job.type)).filter(Boolean) as string[]

    if (!jobTypesKeys.length) return

    requestJobTypes({ codes: jobTypesKeys })
  }, [jobsArray]) // dont add requestJobTypes as a dependency, it will cause infinite loop

  useEffect(() => {
    if (!job) return

    const filtered = job.trips?.filter(
      trip => ![TripStatus.Deleted, TripStatus.Cancelled].includes(trip?.status as TripStatus)
    )
    setHasDeleted(job.trips?.length === filtered?.length ? false : true)
    setJobsArray(hideDeletedTrip ? [job] : [{ ...job, trips: filtered }])
  }, [hideDeletedTrip, job])

  const updateJobs = (jobs: Job[]) => setJobsArray(jobs)

  const ModalTitle = (
    <>
      <div className="flex justify-between items-center">
        Edit Job
        <div className="px-8">
          {hasDeleted && (
            <Button type="link" onClick={() => setHideDeletedTrip(!hideDeletedTrip)}>
              {hideDeletedTrip ? t('common.hideDeleted') : t('common.showDeleted')}
            </Button>
          )}
          <RolloverAction job={job} />
        </div>
      </div>
      <Divider />
    </>
  )

  const ModalFooter = (
    <Row>
      <Col span={6} style={{ textAlign: 'left' }}>
        {job?.status === JobStatus.Cancelled ? (
          <JobUndeleteAction job={job} />
        ) : (
          <JobDeleteAction job={job} />
        )}
      </Col>

      <Col span={18}>
        <Button onClick={handleModalCancel}>Cancel</Button>
        <Button type="primary" onClick={handleModalOk}>
          {t('common.update')}
        </Button>
      </Col>
    </Row>
  )

  return (
    <>
      <Button size="small" icon={<EditOutlined />} onClick={handleDisplayModal} />

      <div onClick={e => e.stopPropagation()}>
        <Modal
          centered
          width={'90%'}
          open={displayModal}
          title={ModalTitle}
          footer={ModalFooter}
          onCancel={handleModalCancel}
        >
          <Form>
            {jobsArray.map((job, index) => (
              <JobForm
                jobIndex={index}
                mode={FormMode.Edit}
                updateJobs={updateJobs}
                key={job.uuid || index}
                currentJob={job as JobPreSubmitProps}
                allJobs={jobsArray as JobPreSubmitProps[]}
              />
            ))}
          </Form>
        </Modal>
      </div>
    </>
  )
}

export default withBooking(JobEditAction)
