import type { Json2CsvOptions } from 'json-2-csv'

import { logger } from '@/utils/logger'
import { getGqlResponse, mapFromCsv } from '../helpers'

const sampleData = [{ zzz: '' }]

const remarks =
  '*Note: Please first export to update. The required fields are: code, name, model, options'

const getDataGql = /* GraphQL */ `
  query categoriesIE($limit: Int, $offset: Int, $q: String) {
    categories(limit: $limit, offset: $offset, q: $q) {
      rows {
        _id
        code
        name
        description
        sort
        status
        model
        options
        values
      }
    }
  }
`

const categoryHelper = {
  queryName: 'categories',

  getExportData: async selectedGlobalCompany => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      return results?.data?.categories?.rows?.map((row, i) => ({ no: i + 1, ...row, zzz: '' }))
    } catch (error) {
      logger.error('categoryHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (selectedGlobalCompany, data) => {},

  mapFromCsv,
  sampleData,
  remarks,
  tableColumns: [
    { title: 'no.', dataIndex: 'key' },
    { title: '_id', dataIndex: '_id' },
    { title: 'code', dataIndex: 'code' },
    { title: 'name', dataIndex: 'name' },
    { title: 'description', dataIndex: 'description' },
    { title: 'sort', dataIndex: 'sort' },
    { title: 'status', dataIndex: 'status' },
    { title: 'model', dataIndex: 'model' },
    { title: 'options', dataIndex: 'options' },
    { title: 'values', dataIndex: 'values' }
  ],

  options: {
    emptyFieldValue: '',
    expandedArrayObjects: false
  } as Json2CsvOptions
}

export default categoryHelper
