import { Form } from 'antd'
import { Input } from 'antd-v5'

import BookingSelect from '@/components/Select/BookingSelect'

const FormItem = Form.Item

const formItemLayout = {
  labelCol: { span: 6 },
  wrapperCol: { span: 14 }
}

const RolloverForm = ({ form: { getFieldDecorator }, job }) => {
  return (
    <Form>
      {getFieldDecorator('jobUuid', {
        initialValue: job.uuid
      })(<Input type="hidden" />)}

      <p>Enter an existing booking UUID in the text box below to rollover to.</p>

      <FormItem label="Booking UUID" {...formItemLayout}>
        {getFieldDecorator('bookingUuid', { initialValue: '' })(
          <BookingSelect useIcon filterOption={false} />
        )}
      </FormItem>
    </Form>
  )
}

export default RolloverForm
