import { useState } from 'react'
import { useQuery } from '@apollo/client'
import { Button, Checkbox, Col, Flex } from 'antd-v5'

import { ROLES_QUERY } from 'App/components/Manage/Roles/TableView/schema'
import { FilterWrapper } from './Styled'

type RolesFilterProps = {
  filter: any
  setFilter: (filter: any) => void
}
const RolesFilter = ({ filter, setFilter }: RolesFilterProps) => {
  const [selectedValues, setSelectedValues] = useState<string[]>([...(filter?.roles || '')])
  const { data } = useQuery(ROLES_QUERY, {
    variables: {
      input: {}
    }
  })

  const handleChange = values => {
    setSelectedValues(values)
  }

  const handleApply = () => {
    setFilter({
      ...filter,
      roles: selectedValues
    })
  }

  const handleReset = () => setFilter({ ...filter, roles: [] })

  if (!data) return <div style={{ padding: 5 }}>Loading...</div>

  return (
    <FilterWrapper>
      <Checkbox.Group style={{ width: 300 }} onChange={handleChange}>
        <Flex vertical gap={6}>
          {data.roles?.rows?.map(role => {
            return (
              <Col span={24} key={role.code}>
                <Checkbox value={role.code}>{role.name}</Checkbox>
              </Col>
            )
          })}
        </Flex>
      </Checkbox.Group>

      <Flex flex={1} gap={10} style={{ marginTop: 20 }}>
        <Button type="primary" style={{ flex: 1 }} onClick={handleApply}>
          Apply
        </Button>
        <Button style={{ flex: 1 }} onClick={handleReset}>
          Reset
        </Button>
      </Flex>
    </FilterWrapper>
  )
}

export default RolesFilter
