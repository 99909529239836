import { Component } from 'react'
import { connect } from 'react-redux'
import { graphql } from '@apollo/client/react/hoc'
import filter from 'lodash/filter'
import findIndex from 'lodash/findIndex'
import { withState } from 'recompose'
import { bindActionCreators, compose } from 'redux'
import { v4 as uuidv4 } from 'uuid'

import useGlobalCompanyStore from '@/store/globalCompany'
import Schema from 'App/containers/booking/schema'
import { withBooking } from 'App/contexts/booking'
import * as voucherActions from 'App/states/reducers/voucher'
import { voucherTypes } from 'App/utils/labelMap'

const fullBookingQuery = graphql(Schema.BOOKING_QUERY, {
  name: 'fullBookingQuery',
  options: props => ({
    variables: {
      uuid: props.booking.uuid
    }
  })
})

export default WrappedComponent => {
  class WithVouchers extends Component {
    getSelectableDocuments = sectionVoucher => {
      const { fullBookingQuery } = this.props
      const { booking } = fullBookingQuery

      const nonDeletedDocs = filter(
        booking?.bookingDocuments,
        doc =>
          findIndex(
            sectionVoucher.bookingDocuments,
            secDoc => secDoc === doc.type && doc.status !== 'DELETED'
          ) !== -1
      )

      const verifiedDocs = filter(nonDeletedDocs, doc => doc.status === 'VERIFIED')

      return verifiedDocs
    }

    getTermPaymentType = voucher => {
      const isAp = voucher.transactionType === 'ACCPAY'

      const term = isAp ? voucher?.vendor?.creditorTerm || 0 : voucher?.customer?.debtorTerm || 0
      const paymentType = term === 0 ? 'CASH' : 'CREDIT'

      return { term, paymentType }
    }

    handleCreateClick = (voucher, section) => {
      const selectedGlobalCompany = useGlobalCompanyStore.getState().selectedGlobalCompany

      const {
        fullBookingQuery,
        togglePopoverView,
        toggleVoucherView,
        updateSelectedVoucher,
        updateSelectedSection,
        updateVoucherBookings,
        updateSelectedVoucherBooking
      } = this.props

      const { booking } = fullBookingQuery
      const preselectedDocuments = this.getSelectableDocuments(voucher)
      const sgcCompany = selectedGlobalCompany?.company

      const newVoucher = {
        uuid: uuidv4(),
        status: 'NEW',
        type: voucher.type,
        currency: sgcCompany?.currency,
        voucherItems: [],
        bookingDocuments: preselectedDocuments,
        transactionType: voucher.transactionType,
        transactionCategory: voucher.transactionCategory
      }

      if (voucher.transactionType === 'ACCREC') {
        newVoucher.customer = this.props.booking.billTo
      }

      const { term, paymentType } = this.getTermPaymentType(newVoucher)
      newVoucher.term = term
      newVoucher.paymentType = paymentType
      try {
        updateSelectedSection(section)
        updateSelectedVoucher(newVoucher)

        updateVoucherBookings([booking])
        updateSelectedVoucherBooking(booking.uuid)

        togglePopoverView(false)
        toggleVoucherView()
      } catch (error) {
        console.log('WithVouchers handleCreateClick error:', error)
      }
    }

    handleViewClick = (voucher, sectionVoucher) => {
      const { toggleVoucherView, togglePopoverView, updateSelectedVoucher, updateVoucherBookings } =
        this.props

      try {
        updateSelectedVoucher({
          ...voucher,
          transactionCategory: sectionVoucher.transactionCategory
        })
        updateVoucherBookings([])
        togglePopoverView(false)
        toggleVoucherView()
      } catch (error) {
        console.log('WithVouchers updateSelectedVoucher error:', error)
      }
    }

    render() {
      return (
        <WrappedComponent
          {...this.props}
          voucherTypes={voucherTypes}
          handleViewClick={this.handleViewClick}
          handleCreateClick={this.handleCreateClick}
        />
      )
    }
  }

  return compose(
    connect(
      state => ({ visible: state.voucher.showVoucher }),
      dispatch => ({
        dispatch,
        ...bindActionCreators({ ...voucherActions }, dispatch)
      })
    ),
    withBooking,
    fullBookingQuery,
    withState('showPopover', 'togglePopoverView', false),
    withState('showDeletedVouchers', 'toggleShowDeletedVouchers', false)
  )(WithVouchers)
}
