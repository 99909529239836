import { Component } from 'react'
import { Button, Popconfirm } from 'antd-v5'
import { compose } from 'recompose'

import ChangeDocumentCreatorStatusMutation from 'App/containers/documentCreator/mutation/changeDocumentCreatorStatus'
import respHandler from 'App/utils/responseHandler'

const enhance = compose(ChangeDocumentCreatorStatusMutation)

class ROTDeleteAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleConfirm = async () => {
    const {
      changeDocumentCreatorStatus,
      documentCreator: { uuid },
      onDelete
    } = this.props

    try {
      this.setState({
        loading: true
      })

      await changeDocumentCreatorStatus(uuid, 'VOID')

      if (onDelete) {
        onDelete()
      }
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    return (
      <Popconfirm
        title={'Confirm to delete this ROT?'}
        onConfirm={this.handleConfirm}
        okText="Yes"
        okType="danger"
        cancelText="No"
        arrow={false}
      >
        <Button danger loading={this.state.loading}>
          Delete
        </Button>
      </Popconfirm>
    )
  }
}

export default enhance(ROTDeleteAction)
