import type { Booking, Job, Maybe, User } from '@/types/graphql'
import type { TableProps } from 'antd-v5'
import { JobStatus } from '@/types/graphql'

import { Tooltip, Typography } from 'antd-v5'
import get from 'lodash/get'
import moment from 'moment'

import BookingStatusIcon from '@/components/Booking/ListView/BookingStatusIcon'
import UserIcon from '@/components/Booking/ListView/UserIcon'
import { getActTime } from '@/components/RelativeDateTime'
import config from '@/config'
import { getContainerStats } from '@/utils/booking'

export type BookingTableColumnProps = TableProps<Booking> & {
  fnc?: string
  convert?: Record<string, string>
  onCell?: (booking: Booking) => object
}

const transHlgStatus = {
  transHlgStart: 'Received',
  transHlgComplete: 'Complete',
  transHlgTransDelPlan: 'Planned',
  transHlgTransDelStart: 'Started',
  transHlgPendTransPodSubmitted: 'POD Req.',
  transHlgPendTransDeliveryComplete: 'Delivered'
}

const transCompArStatus = {
  transCompArComplete: 'Complete',
  transCompPendStartAr: 'Received',
  transCompPendDeliveryComplete: 'Delivered',
  transCompPendCustomerVoucherOprAaApproval: 'Invoiced',
  transCompPendOutstandingConsigneeInvoiceVoucher: 'Pending Approval'
}

const renderWithTooltip = (text: string, width: number) => {
  return (
    <Tooltip title={text} mouseEnterDelay={0.1}>
      <Typography.Text ellipsis style={{ width: width || undefined }}>
        {text}
      </Typography.Text>
    </Tooltip>
  )
}

export const getShortCntr = (record: Booking) => {
  const activeJobs = record?.jobs?.filter((j: Maybe<Job>) => j?.status !== JobStatus.Cancelled)
  let containerStats = getContainerStats(activeJobs)
    .map(s => `${s.count}x${s.size} ${s.type ? s.type : ''}`)
    .join(', ')
  containerStats = (record.type || record.details.shipmentType || '') + ' ' + containerStats
  return containerStats
}

export const getFormattedDateBookingList = (record: Record<string, unknown>, key: string) => {
  const data = get(record, key)
  return data ? moment(data).format('DD/MM/YY') : '-'
}

export const getNumJobTrip = (record: Record<string, unknown>) => {
  return Array.isArray(record.jobs) ? record.jobs.length : 0
}

const functions = {
  renderTooltip: (text: string, width: number) => renderWithTooltip(text, width),
  shortCntr: (record: Booking) => {
    const containerStats = getShortCntr(record)
    return renderWithTooltip(containerStats, 140)
  },
  jobRequestType: (_: string, record: Booking) => {
    return [record.no, record.details.requestNo, record.type].filter(Boolean).join(' ')
  },
  containerDetails: (_, record: Booking) => {
    const jd = record.jobs?.[0]?.details
    const trailerDetails = jd.trailerType === 'NORMAL' ? '' : ` [${jd.trailerType}]`
    return `${jd.containerNo} (${jd.containerSize}${jd.containerType})${trailerDetails}`
  },
  etaEtd: (_, record: Booking) => {
    const eta = getFormattedDateBookingList(record, 'estPick')
    const etd = getFormattedDateBookingList(record, 'estDrop')
    return renderWithTooltip(`${eta} / ${etd}`, 160)
  },
  date: (_, record) => {
    const dateCreated = getFormattedDateBookingList(record, 'createdAt')
    return renderWithTooltip(dateCreated, 90)
  },
  bookingStatus: (_, record: Booking) => {
    return (
      <>
        <BookingStatusIcon booking={record} />
        {record.assignees?.map(u => <UserIcon key={u?.user?.uuid} user={u?.user as User} />)}
      </>
    )
  },
  bookingStatusHlg: (text: string, record: Booking) => {
    const s = record.lStat
    return (
      <>
        <BookingStatusIcon booking={record} />
        <span style={{ marginLeft: '5px' }}>{s ? `${s.t} ${getActTime(s.ts)}` : ''}</span>
      </>
    )
  },
  stringArray: (text: string[]) => (Array.isArray(text) ? text.join(', ') : '')
}

const defaultCols: TableProps<Booking>['columns'] = [
  {
    key: 'no',
    title: 'No.',
    dataIndex: 'no',
    render: e => functions.renderTooltip(e, config.env.production ? 120 : 150)
  },
  {
    key: 'billTo',
    title: 'Bill To',
    dataIndex: ['billTo', 'name'],
    render: e => functions.renderTooltip(e, 140)
  },
  {
    key: 'shipperName',
    title: 'Shipper',
    dataIndex: ['shipper', 'name'],
    render: e => functions.renderTooltip(e, 140)
  },
  {
    key: 'consigneeName',
    title: 'Consignee',
    dataIndex: ['consignee', 'name'],
    render: e => functions.renderTooltip(e, 140)
  },
  {
    key: 'shortCntr',
    title: 'ShortCntr',
    render: functions.shortCntr
  },
  {
    key: 'etaEtd',
    title: 'ESTPick/ESTDrop',
    render: functions.etaEtd
  },
  {
    key: 'createdAt',
    title: 'Created',
    dataIndex: 'createdAt',
    render: functions.date
  },
  {
    key: 'transport',
    title: 'TransportStatus',
    dataIndex: 'transHlg',
    render: e => functions.renderTooltip(transHlgStatus[e], 90)
  },
  {
    key: 'invoice',
    title: 'InvoiceStatus',
    dataIndex: 'transCompAr',
    render: e => functions.renderTooltip(transCompArStatus[e], 90)
  },
  {
    key: 'status',
    title: 'Status',
    dataIndex: 'status',
    render: functions.bookingStatus
  }
]

export const getColumns = () => {
  // TODO: find a way to dyanmicly add columns and set column's width using antd-v5.
  return defaultCols
}
