import type { MenuProps } from 'antd-v5'

import { useCallback } from 'react'
import { MoreOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Button, Dropdown, Typography } from 'antd-v5'
import cloneDeep from 'lodash/cloneDeep'
import identity from 'lodash/identity'
import pickBy from 'lodash/pickBy'

import respHandler from '@/utils/responseHandler'
import {
  DELETE_USER_PREFERENCE_MUTATION,
  UPDATE_USER_PREFERENCE_MUTATION,
  USER_PREFERENCE_QUERY
} from './_shared/schema'

const defaultUserInputs = {
  type: 'NEWBOOKING',
  key: 'TRANSPORT'
}

const convertInputsToValues = (val: any) => {
  const clonedValues = {
    ...cloneDeep(val),
    jobs: val.jobs.map((job: any) => ({
      trips: job.trips,
      ...job,
      details: {
        ...pickBy(job.details, identity)
      }
    }))
  }

  delete clonedValues.uuid
  delete clonedValues.consigneeRequiredDate
  delete clonedValues.shipperRequiredDate
  delete clonedValues.billToUuid
  delete clonedValues.shipperUuid
  delete clonedValues.shipperAddressUuid
  delete clonedValues.consigneeUuid
  delete clonedValues.consigneeAddressUuid
  clonedValues.billTo = { uuid: val?.billToUuid }
  clonedValues.shipper = { uuid: val?.shipperUuid, addressUuid: val?.shipperAddressUuid }
  clonedValues.consignee = { uuid: val?.consigneeUuid, addressUuid: val?.consigneeAddressUuid }

  return clonedValues
}

const DefaultAction = (props: any) => {
  const { form } = props
  const { getFieldsValue } = form

  const [updateUserPreference] = useMutation(UPDATE_USER_PREFERENCE_MUTATION)
  const [deleteUserPreference] = useMutation(DELETE_USER_PREFERENCE_MUTATION)

  const handleSave = useCallback(() => {
    const val = getFieldsValue()
    const values = convertInputsToValues(val)

    updateUserPreference({
      variables: {
        ...defaultUserInputs,
        value: JSON.stringify(values)
      },
      refetchQueries: [
        {
          query: USER_PREFERENCE_QUERY,
          variables: {
            ...defaultUserInputs
          }
        }
      ]
    })

    respHandler('Successfully updated user preference for new transport bookings.', 'success')
  }, [getFieldsValue, updateUserPreference])

  const handleClear = useCallback(() => {
    deleteUserPreference({
      variables: {
        ...defaultUserInputs
      },
      refetchQueries: [
        {
          query: USER_PREFERENCE_QUERY,
          variables: {
            ...defaultUserInputs
          }
        }
      ]
    })

    respHandler('Successfully deleted user preference for new transport bookings.', 'success')
  }, [deleteUserPreference])

  const items: MenuProps['items'] = [
    {
      key: 'clearDefault',
      label: <Typography.Text onClick={handleClear}>Clear Default</Typography.Text>
    },
    {
      key: 'setDefault',
      label: <Typography.Text onClick={handleSave}>Set Default</Typography.Text>
    }
  ]

  return (
    <Dropdown menu={{ items }}>
      <Button type="dashed">
        <MoreOutlined />
      </Button>
    </Dropdown>
  )
}

export default DefaultAction
