import { ChargeItemStatus, GlCodeStatus } from 'App/types/graphql'

import { v4 as uuidv4 } from 'uuid'

import { logger } from 'App/utils/logger'
import { isUuid } from 'App/utils/u'
import { getGqlResponse } from '../helpers'
import {
  addChargeItemCategoryGql,
  addChargeItemCodeGql,
  chargeCategoriesGql,
  createEntityGql,
  currenciesGql,
  getDataGql,
  glCodesGql,
  taxesGql,
  updateChargeItemCodeGql,
  updateEntityGql
} from './schema'

type MutationResultsType = {
  glCode?: any
  chargeItem?: any
  chargeCategory?: any
}

const enumQueryString = `
  query enum1 ($name: String!) {
    __type(name: $name) {
      name
      enumValues {
        name
      }
    }
  }
`

const sampleData = [
  {
    no: 1,
    code: 'G-TPT-FT88',
    costCurrency: {
      code: 'MYR',
      name: 'Malaysian Ringgit',
      uuid: 'edac2598-fb86-4857-8e83-c26b32984db1'
    },
    costRate: '800',
    costTax: {
      code: 'SST',
      name: 'SST',
      percentage: '5',
      uuid: '593149e6-ac4c-430f-8445-0b86d86b6e70'
    },
    description: 'Testing purpose',
    expenseCode: {
      code: '703-000003',
      description: 'Expense Code',
      status: 'activated',
      uuid: '2e2d8798-edb9-4732-a403-e16c6d03ddcf'
    },
    name: 'TRANSPORTATION CHARGES OF HIRING 10FT TRUCK',
    rateType: 'FIXED',
    revenueCode: {
      code: '500-001001',
      description: 'Revenue Code',
      status: 'activated',
      uuid: 'a1df5f8c-ba81-4ad1-a328-95476c9f1ead'
    },
    sellCurrency: {
      code: 'MYR',
      name: 'Malaysian Ringgit',
      uuid: 'edac2598-fb86-4857-8e83-c26b32984db1'
    },
    sellRate: '1000',
    sellTax: {
      code: 'SST',
      name: 'SST',
      percentage: '5',
      uuid: '593149e6-ac4c-430f-8445-0b86d86b6e70'
    },
    sequence: '1',
    status: 'activated',
    tags: '["isBillingUnit"]',
    term: '0',
    unit: 'TRIP',
    uuid: 'b8100e5b-22ae-4472-b795-abc52c24acb1',
    categories_1: {
      code: 'TEST1',
      uuid: 'b9284d97-3f58-4da8-b548-b76b6f16ebff',
      // Add a dummy column at the end so that d.lastColumn won't become d["lastColumn "] when importing back
      zzz: ''
    }
  }
]

const remarks =
  '*Note: The required fields are: code, costCurrency.code, costRate, costTax.code, expenseCode.code, name, revenueCode.code, sellCurrency.code, sellRate, sellTax.code, and unit. ' +
  'If a tax/expenseCode/revenueCode does not exist, then please create one. ' +
  'For unit (i.e. Billing Unit), please enter one of the following: ATTAND, ATTEND, BAG, BL, CNTR, DG, DOC, EXSET, HOUR, KGS, M3, M3MT, MT, OT, OT2, PALLET, SET, SHPMT, SUBDOC, TRIP, or TEST. ' +
  'If you wish to import charge category for the charge item, the required field is categories_1.code, e.g. TEST1.'

const tableColumns = [
  {
    title: 'No.',
    dataIndex: 'key',
    key: 'key'
  },
  {
    title: 'Code*',
    dataIndex: 'code',
    key: 'code'
  },
  {
    title: 'Cost Currency*',
    dataIndex: 'costCurrency.code',
    key: 'costCurrency.code'
  },
  {
    title: 'Cost Rate*',
    dataIndex: 'costRate',
    key: 'costRate'
  },
  {
    title: 'Cost Tax*',
    dataIndex: 'costTax.code',
    key: 'costTax.code'
  },
  {
    title: 'Name',
    dataIndex: 'name',
    key: 'name'
  },
  {
    title: 'Sell Currency*',
    dataIndex: 'sellCurrency.code',
    key: 'sellCurrency.code'
  },
  {
    title: 'Sell Rate*',
    dataIndex: 'sellRate',
    key: 'sellRate'
  },
  {
    title: 'Sell Tax*',
    dataIndex: 'sellTax.code',
    key: 'sellTax.code'
  },
  {
    title: 'Status',
    dataIndex: 'status',
    key: 'status'
  },
  {
    title: 'Billing Unit*',
    dataIndex: 'unit',
    key: 'unit'
  },
  {
    title: 'categories_1',
    dataIndex: 'categories[0].code',
    key: 'categories_1'
  },
  {
    title: 'glCode_1',
    dataIndex: 'codes[0].code',
    key: 'glCode_1'
  },
  {
    title: 'uuid',
    dataIndex: 'uuid',
    key: 'uuid',
    ellipsis: true
  },
  {
    title: 'Import',
    dataIndex: 'importStatus',
    key: 'importStatus'
  }
]

const cache: any = {
  uuid: {},
  code: {},
  currency: {
    uuid: {},
    code: {}
  },
  tax: {
    uuid: {},
    code: {}
  },
  glCode: {
    uuid: {},
    code: {}
  },
  chargeItemCodeTypes: [],
  category: {
    uuid: {},
    code: {}
  }
}

const findNullInputs = (chargeItem: any) => {
  if (!chargeItem.code) {
    return { nullError: 'Charge item code is required, e.g. G-TPT-FT28.' }
  }
  if (!chargeItem.costCurrency?.code) {
    return { nullError: 'costCurrency.code is required, e.g. MYR.' }
  }
  if (!chargeItem.costTax?.code) {
    return {
      nullError:
        'costTax.code is required, e.g. SST. If it does not exist, then please create it in the Taxes page.'
    }
  }
  if (!chargeItem.expenseCode?.code) {
    return {
      nullError:
        'expenseCode.code is required. If it does not exist, then please create it in the GL Codes page.'
    }
  }
  if (!chargeItem.name) {
    return {
      nullError: 'Charge item name is required, e.g. TRANSPORTATION CHARGES OF HIRING 10FT TRUCK.'
    }
  }
  if (!chargeItem.revenueCode?.code) {
    return {
      nullError:
        'revenueCode.code is required. If it does not exist, then please create it in the GL Codes page.'
    }
  }
  if (!chargeItem.sellCurrency?.code) {
    return { nullError: 'sellCurrency.code is required, e.g. MYR.' }
  }
  if (!chargeItem.sellTax?.code) {
    return {
      nullError:
        'sellTax.code is required, e.g. SST. If it does not exist, then please create it in the Taxes page.'
    }
  }
  if (
    chargeItem.status &&
    chargeItem.status !== ChargeItemStatus.Activated &&
    chargeItem.status !== ChargeItemStatus.Deleted
  ) {
    return { nullError: 'Status is required, e.g. activated or deleted.' }
  }
  if (!chargeItem.unit) {
    return { nullError: 'Billing unit is required, e.g. TRIP.' }
  }
}

const findNullCategoriesInput = (category: any) => {
  if (!category.code) {
    return { nullError: 'Charge category code is required.' }
  }
}

const findNullGlCodesInput = (glCode: any) => {
  if (!glCode.code) {
    return { nullError: 'Charge item glCode.code is required.' }
  }
  if (!glCode.type) {
    return { nullError: 'Charge item glCode.type is required.' }
  } else {
    const hasValidType = cache.chargeItemCodeTypes?.includes(glCode.type)
    if (!hasValidType) {
      return {
        nullError: `Charge item glCode.type should be one of the following: ${cache.chargeItemCodeTypes?.join(
          ', '
        )}.`
      }
    }
  }
}

const chargeItemKeys = [
  'costCurrency',
  'costTax',
  'expenseCode',
  'revenueCode',
  'sellCurrency',
  'sellTax'
]

const getSellCostUuids = async (selectedGlobalCompany: any, chargeItem: any) => {
  if (Object.keys(cache.currency.uuid)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, currenciesGql, { limit: 10000 })
      const currencies = results?.data?.currencies?.rows

      for (let i = 0; i < currencies?.length; i++) {
        cache.currency.uuid[currencies[i]?.uuid] = currencies[i]?.code
        cache.currency.code[currencies[i]?.code] = currencies[i]?.uuid
      }
    } catch (error) {
      logger.error('chargeItemsHelper cache currenciesGql error', error)
      return error
    }
  }

  if (Object.keys(cache.tax.uuid)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, taxesGql, { limit: 10000 })
      const taxes = results?.data?.taxes?.rows

      for (let i = 0; i < taxes?.length; i++) {
        cache.tax.uuid[taxes[i]?.uuid] = taxes[i]?.code
        cache.tax.code[taxes[i]?.code] = taxes[i]?.uuid
      }
    } catch (error) {
      logger.error('chargeItemsHelper cache taxesGql error', error)
      return error
    }
  }

  if (Object.keys(cache.glCode.uuid)?.length < 1) {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, glCodesGql, {
        limit: 10000,
        statuses: GlCodeStatus.Activated
      })
      const glCodes = results?.data?.glCodes?.rows

      for (let i = 0; i < glCodes?.length; i++) {
        cache.glCode.uuid[glCodes[i]?.uuid] = glCodes[i]?.code
        cache.glCode.code[glCodes[i]?.code] = glCodes[i]?.uuid
      }
    } catch (error) {
      logger.error('chargeItemsHelper cache glCodesGql error', error)
      return error
    }
  }

  chargeItemKeys.forEach((key: string) => {
    if (key.includes('Currency')) {
      let currencyUuid: string = ''
      if (cache.currency.uuid[chargeItem[key].uuid]) {
        currencyUuid = chargeItem[key].uuid
      } else if (cache.currency.code[chargeItem[key].code]) {
        currencyUuid = cache.currency.code[chargeItem[key].code]
      }
      chargeItem[`${key}Uuid`] = currencyUuid
    }

    if (key.includes('Tax')) {
      let taxUuid: string = ''
      if (cache.tax.uuid[chargeItem[key].uuid]) {
        taxUuid = chargeItem[key].uuid
      } else if (cache.tax.code[chargeItem[key].code]) {
        taxUuid = cache.tax.code[chargeItem[key].code]
      }
      chargeItem[`${key}Uuid`] = taxUuid
    }

    if (key.includes('Code')) {
      let glCodeUuid: string = ''
      if (cache.glCode.uuid[chargeItem[key].uuid]) {
        glCodeUuid = chargeItem[key].uuid
      } else if (cache.glCode.code[chargeItem[key].code]) {
        glCodeUuid = cache.glCode.code[chargeItem[key].code]
      }
      chargeItem[`${key}Uuid`] = glCodeUuid
    }
  })

  const {
    costCurrency,
    costTax,
    expenseCode,
    revenueCode,
    sellCurrency,
    sellTax,
    ...objWithUuids
  } = chargeItem

  return objWithUuids
}

const populateChargeItemsObj = (obj: any, chargeItemUuid: string, mode: string) => {
  const chargeItemObj: any = {
    code: obj?.code?.toString()?.trim(),
    costCurrencyUuid: obj?.costCurrencyUuid,
    costRate: obj?.costRate,
    costTaxUuid: obj?.costTaxUuid,
    description: obj?.description,
    expenseCodeUuid: obj?.expenseCodeUuid,
    name: obj?.name,
    rateType: obj?.rateType,
    revenueCodeUuid: obj?.revenueCodeUuid,
    sellCurrencyUuid: obj?.sellCurrencyUuid,
    sellRate: obj?.sellRate,
    sellTaxUuid: obj?.sellTaxUuid,
    sequence: obj?.sequence,
    tags: obj?.tags,
    term: obj?.term,
    unit: obj?.unit,
    uuid: chargeItemUuid?.trim()
  }

  if (mode === 'update') {
    chargeItemObj.status = obj?.status || ChargeItemStatus.Activated
  } else if (mode === 'create') {
    chargeItemObj.status = ChargeItemStatus.Activated
  }

  return chargeItemObj
}

const findGqlError = (gqlMutationResult: any) => {
  for (const key in gqlMutationResult) {
    if (gqlMutationResult[key]?.message) {
      return gqlMutationResult[key]
    }
  }
}

const chargeItemsHelper = {
  queryName: 'chargeItems',

  getExportData: async (selectedGlobalCompany: any) => {
    try {
      const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
      const chargeItems = results?.data?.chargeItems?.rows?.map((row: any, i: number) => ({
        no: i + 1,
        ...row
      }))

      const formattedData = chargeItems?.map((item: any) => {
        if (item.categories?.length === 0) {
          // Add a dummy column at the end so that entity?.lastColumn won't become entity["lastColumn "] when importing back
          item.zzz = ''
        }

        if (item.codes?.length > 0) {
          let codesCount = 0

          item.codes.forEach((code: any) => {
            code.zzz = ''
            codesCount += 1
            item[`glCodes_${codesCount}`] = code
          })
        }
        delete item?.codes

        if (item.categories?.length > 0) {
          let categoriesCount = 0

          item.categories.forEach((cat: any) => {
            cat.zzz = ''
            categoriesCount += 1
            item[`categories_${categoriesCount}`] = cat
          })
        }
        delete item?.categories

        return item
      })

      return formattedData
    } catch (error) {
      logger.error('chargeItemsHelper getExportData error', error)
      return error
    }
  },

  handleImportData: async (chargeItemData: any, selectedGlobalCompany: any) => {
    const mutationResults: MutationResultsType = {}
    const { importStatus, key, ...chargeItem } = chargeItemData

    const nullInputError = findNullInputs(chargeItem)
    if (nullInputError) return nullInputError

    if (Object.keys(cache.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, getDataGql, { limit: 10000 })
        const chargeItems = results?.data?.chargeItems?.rows

        for (let i = 0; i < chargeItems?.length; i++) {
          cache.uuid[chargeItems[i]?.uuid] = chargeItems[i]
          cache.code[chargeItems[i]?.code] = chargeItems[i]?.uuid
        }
      } catch (error) {
        logger.error('chargeItemsHelper cache getDataGql error', error)
        return error
      }
    }

    // Check chargeItemUuid
    let chargeItemUuid: string = ''
    if (cache.uuid[chargeItem?.uuid]) {
      chargeItemUuid = chargeItem?.uuid
    } else if (cache.code[chargeItem?.code]) {
      chargeItemUuid = cache.code[chargeItem?.code]
    }

    // Get uuids of currencies, taxes and glCodes
    const objWithUuids = await getSellCostUuids(selectedGlobalCompany, chargeItem)
    for (const key in objWithUuids) {
      if (key.includes('Uuid')) {
        if (!objWithUuids[key] || !isUuid(objWithUuids[key])) {
          return {
            nullError: `${key} is not found. Please create this ${key.replace(/uuid/gi, '')} first.`
          }
        }
      }
    }

    // Update charge item
    if (chargeItemUuid?.length > 0) {
      try {
        const chargeItemObj = populateChargeItemsObj(objWithUuids, chargeItemUuid, 'update')
        mutationResults.chargeItem = await getGqlResponse(selectedGlobalCompany, updateEntityGql, {
          input: chargeItemObj
        })
      } catch (error) {
        logger.error('companyHelper handleImportData updateEntityGql error', error)
        return error
      }
    } else {
      // Create new charge item
      try {
        chargeItemUuid = uuidv4()
        const chargeItemObj = populateChargeItemsObj(objWithUuids, chargeItemUuid, 'create')
        mutationResults.chargeItem = await getGqlResponse(selectedGlobalCompany, createEntityGql, {
          input: chargeItemObj
        })
      } catch (error) {
        logger.error('companyHelper handleImportData createEntityGql error', error)
        return error
      }
    }

    if (Object.keys(cache.category.uuid)?.length < 1) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, chargeCategoriesGql, {
          limit: 10000
        })
        const chargeCategories = results?.data?.chargeCategories?.rows

        for (let i = 0; i < chargeCategories?.length; i++) {
          cache.category.uuid[chargeCategories[i]?.uuid] = chargeCategories[i]?.code
          cache.category.code[chargeCategories[i]?.code] = chargeCategories[i]?.uuid
        }
      } catch (error) {
        logger.error('chargeItemsHelper cache chargeCategoriesGql error', error)
        return error
      }
    }

    if (!cache.chargeItemCodeTypes?.length) {
      try {
        const results = await getGqlResponse(selectedGlobalCompany, enumQueryString, {
          name: 'ChargeItemCodeType'
        })
        cache.chargeItemCodeTypes = results?.data?.__type?.enumValues?.map((e: any) => e.name)
      } catch (error) {
        logger.error('chargeItemsHelper cache chargeCategoriesGql error', error)
        return error
      }
    }

    if (chargeItem?.codes?.length > 0) {
      for (let i = 0; i < chargeItem?.codes?.length; i++) {
        if (!chargeItem?.codes?.[0]?.code) {
          continue
        }

        try {
          const nullError = findNullGlCodesInput(chargeItem?.codes?.[i])
          if (nullError) {
            return nullError
          }

          let glCodeUuid: string = ''
          if (cache.glCode.code[chargeItem?.codes?.[i]?.code]) {
            glCodeUuid = cache.glCode.code[chargeItem?.codes?.[i]?.code]
          } else {
            return {
              nullError: `The provided glCodes_${i + 1}.code="${
                chargeItem?.codes?.[i]?.code
              }" is not found. Please create it in the GL Codes page.`
            }
          }

          const foundGlCode = cache.uuid[chargeItemUuid]?.codes?.find((c: any) => {
            return (
              c.uuid === chargeItem?.codes?.[i]?.uuid || c.code === chargeItem?.codes?.[i]?.code
            )
          })

          // Update chargeItem.glCode
          if (foundGlCode) {
            const chargeItemCodeInput = {
              input: {
                uuid: foundGlCode.uuid,
                type: chargeItem?.codes?.[i]?.type,
                glCodeUuid
              }
            }
            mutationResults.glCode = await getGqlResponse(
              selectedGlobalCompany,
              updateChargeItemCodeGql,
              chargeItemCodeInput
            )
          } else {
            // Add chargeItem.glCode
            const chargeItemCodeInput = {
              input: {
                chargeItemUuid,
                glCodeUuid,
                type: chargeItem?.codes?.[i]?.type
              }
            }
            mutationResults.glCode = await getGqlResponse(
              selectedGlobalCompany,
              addChargeItemCodeGql,
              chargeItemCodeInput
            )
          }
        } catch (error) {
          logger.error('chargeItemsHelper addChargeItemCodeGql error', error)
          return error
        }
      }
    }

    if (chargeItem?.categories?.length > 0) {
      for (let i = 0; i < chargeItem?.categories?.length; i++) {
        if (!chargeItem?.categories?.[0]?.code) {
          continue
        }

        try {
          const nullCategoriesError = findNullCategoriesInput(chargeItem?.categories?.[i])
          if (nullCategoriesError) {
            return nullCategoriesError
          }

          let chargeCategoryUuid: string = ''
          if (cache.category.uuid[chargeItem?.categories?.[i]?.uuid]) {
            chargeCategoryUuid = chargeItem?.categories?.[i]?.uuid
          } else {
            if (cache.category.code[chargeItem?.categories?.[i]?.code]) {
              chargeCategoryUuid = cache.category.code[chargeItem?.categories?.[i]?.code]
            } else {
              return {
                nullError: `The provided categories_${i + 1}.code="${
                  chargeItem?.categories?.[i]?.code
                }" is not found, please create it in the Charge Categories page.`
              }
            }
          }

          const chargeCategoryInput = {
            chargeItemUuid,
            chargeCategoryUuid
          }
          mutationResults.chargeCategory = await getGqlResponse(
            selectedGlobalCompany,
            addChargeItemCategoryGql,
            chargeCategoryInput
          )
        } catch (error) {
          logger.error('chargeItemsHelper addChargeItemCategoryGql error', error)
          return error
        }
      }
    }

    const errorObj = findGqlError(mutationResults)
    if (errorObj) {
      return errorObj
    }

    return mutationResults
  },

  mapFromCsv: (csvData: any) => {
    if (!csvData || csvData.length <= 0) {
      return []
    }

    for (let i = 0; i < csvData.length; i++) {
      csvData[i].code = csvData[i].code?.toString()?.trim()
      csvData[i].costRate = csvData[i].costRate && Number.parseFloat(csvData[i].costRate)
      csvData[i].description = csvData[i].description?.toString()?.trim()
      csvData[i].name = csvData[i].name?.toString()?.trim()
      csvData[i].rateType = csvData[i].rateType?.toString()?.trim()
      csvData[i].sellRate = csvData[i].sellRate && Number.parseFloat(csvData[i].sellRate)
      csvData[i].sequence = csvData[i].sequence?.toString()?.trim()
      csvData[i].status = csvData[i].status?.toString()?.trim()
      csvData[i].term = csvData[i].term && Number.parseInt(csvData[i].term, 10)
      csvData[i].unit = csvData[i].unit?.toString()?.trim()

      chargeItemKeys.forEach((key: string) => {
        csvData[i][key] = {
          code: csvData[i][key]?.code?.toString()?.trim(),
          uuid: csvData[i][key]?.uuid?.toString()?.trim()
        }
      })

      if (csvData[i].glCodes_1) {
        csvData[i].codes = []

        let codesCount = 0

        Object.keys(csvData[i]).forEach((k: any, index: number) => {
          if (k.includes('glCodes_')) {
            codesCount += 1
          }
        })

        for (let j = 0; j < codesCount; j++) {
          if (!csvData[i][`glCodes_${j + 1}`]) {
            break
          } else {
            csvData[i][`glCodes_${j + 1}`].code = csvData[i][`glCodes_${j + 1}`].code
              ?.toString()
              ?.trim()
            csvData[i][`glCodes_${j + 1}`].type = csvData[i][`glCodes_${j + 1}`].type
              ?.toString()
              ?.trim()
            csvData[i][`glCodes_${j + 1}`].uuid = csvData[i][`glCodes_${j + 1}`].uuid
              ?.toString()
              ?.trim()
            csvData[i].codes.push(csvData[i][`glCodes_${j + 1}`])

            delete csvData[i][`glCodes_${j + 1}`]
          }
        }
      }

      if (csvData[i].categories_1) {
        csvData[i].categories = []

        let categoriesCount = 0

        Object.keys(csvData[i]).forEach((k: any, index: number) => {
          if (k.includes('categories_')) {
            categoriesCount += 1
          }
        })

        for (let j = 0; j < categoriesCount; j++) {
          if (!csvData[i][`categories_${j + 1}`]) {
            break
          } else {
            csvData[i][`categories_${j + 1}`].code = csvData[i][`categories_${j + 1}`].code
              ?.toString()
              ?.trim()
            csvData[i][`categories_${j + 1}`].uuid = csvData[i][`categories_${j + 1}`].uuid
              ?.toString()
              ?.trim()
            csvData[i].categories.push(csvData[i][`categories_${j + 1}`])

            delete csvData[i][`categories_${j + 1}`]
          }
        }
      }

      csvData[i].importStatus = 'pending'
      csvData[i].key = i + 1
    }

    return csvData
  },

  sampleData,

  remarks,

  tableColumns
}

export default chargeItemsHelper
