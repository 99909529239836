import styled from 'styled-components'

export const Separator = styled.div`
  height: 5px;
`
export const QuickAddDiv = styled.div`
  line-height: 10px;
  font-size: 0.85em;
  text-align: right;

  span {
    color: gray;
    cursor: pointer;

    :hover {
      color: #40a9ff;
    }

    .anticon {
      margin-right: 4px;
    }
  }
`
