import type { TaxesSelector1Query } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { gql } from '@/types'

import { useCallback, useState } from 'react'
import { LoadingOutlined } from '@ant-design/icons'
import { useQuery } from '@apollo/client'
import { Select } from 'antd-v5'
import debounce from 'lodash/debounce'

import TaxDocumentViewer from '@/components/Manage/Companies/ChargeItemMisc/ModalView/TaxDocumentViewer'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const TAXES_SELECTOR_QUERY = gql(`
  query taxesSelector1($showAll: Boolean, $q: String) {
    taxes(showAll: $showAll, q: $q) {
      rows {
        uuid
        name
        code
        exemptionDocumentUuid
      }
    }
  }
`)

type TaxSelectProps = SelectProps & {
  showAll?: boolean
}

function TaxSelect(props: TaxSelectProps) {
  const { showAll = false, ...selectProps } = props
  const { value, onChange } = selectProps

  const [taxes, setTaxes] = useState<
    NonNullable<NonNullable<TaxesSelector1Query>['taxes']>['rows']
  >([])
  const [searchInput, setSearchInput] = useState('')
  const [toggleModal, setToggleModal] = useState(false)

  const { error, loading } = useQuery(TAXES_SELECTOR_QUERY, {
    variables: {
      q: searchInput || '',
      showAll
    },
    onCompleted: data => {
      setTaxes(data?.taxes?.rows)
    },
    fetchPolicy: 'cache-and-network'
  })

  const handleSearch = useCallback(
    debounce(value => {
      try {
        setSearchInput(value)
      } catch (error) {
        logger.error('TaxSelect handleSearch error', error)
      }
    }, 500),
    []
  )

  if (error) {
    logger.error('TaxSelect error', error)
    responseHandler(error, 'error')
  }

  const handleChange = (value, option) => onChange?.(value, option.props.tax)

  const currentTax = taxes?.find(tax => tax?.uuid === value)
  const taxExempted = !!currentTax?.exemptionDocumentUuid

  const options = taxes?.map(tax => ({
    label: `${tax?.code} - ${tax?.name}`,
    value: tax?.uuid
  }))

  return (
    <>
      <Select
        {...selectProps}
        id={'taxCode-selector'}
        showSearch
        filterOption
        optionFilterProp="children"
        placeholder="Select a tax..."
        notFoundContent={loading ? <LoadingOutlined /> : 'Not found'}
        getPopupContainer={trigger => trigger.parentNode}
        onSearch={handleSearch}
        onChange={handleChange}
        options={options}
      />

      {currentTax && (
        <TaxDocumentViewer
          uuid={currentTax.exemptionDocumentUuid}
          setToggleModal={setToggleModal}
          toggleModal={toggleModal}
        />
      )}

      {taxExempted && currentTax && (
        <div onClick={() => setToggleModal(true)}>
          Tax exempted for <a>{currentTax.code}</a>
        </div>
      )}
    </>
  )
}

export default TaxSelect
