import { memo } from 'react'
import { useLocation } from 'react-router-dom'
import { Flex, Typography } from 'antd-v5'

interface PageHeaderProps {
  title: string
  subtitle?: string
  type?: 'normal' | 'small'
}

const PageHeader = ({ title, subtitle, type = 'normal' }: PageHeaderProps) => {
  const { pathname } = useLocation()
  const testId = pathname.split('manage/')?.[1]?.replace('/', '-')

  return (
    <Flex vertical style={{ marginTop: type === 'normal' ? '30px' : '0' }}>
      <Typography.Title level={type === 'normal' ? 2 : 4} id={testId}>
        {title}
      </Typography.Title>
      {subtitle && <Typography.Text type="secondary">{subtitle}</Typography.Text>}
    </Flex>
  )
}

export default memo(PageHeader)
