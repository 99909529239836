import type { Trailer } from '@/types/graphql'
import type { SelectProps } from 'antd-v5'
import { TrailerStatus } from '@/types/graphql'

import { useCallback, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useQuery } from '@apollo/client'
import { Select, Typography } from 'antd-v5'
import { isObject } from 'lodash'
import debounce from 'lodash/debounce'

import { getLabelExpiry } from '@/components/Transport/Components/IsExpFilterSelector'
import { TRAILERS_QUERY } from '@/components/Transport/Schemas/schema'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

type TrailerSelectorProps = SelectProps & {
  statuses?: Array<TrailerStatus>
  returnObject?: boolean
  showExpiry?: boolean
}

const TrailerSelector = (props: TrailerSelectorProps) => {
  const {
    statuses = [TrailerStatus.Active],
    returnObject = false,
    showExpiry = false,
    ...selectProps
  } = props
  const { value, onChange, style } = selectProps

  const { t } = useTranslation()

  const [trailers, setTrailers] = useState<Trailer[]>([])
  const [searchInput, setSearchInput] = useState<string>('')

  const getInputValue = useMemo(() => {
    return isObject(value) ? (value as { uuid: string })?.uuid : value || searchInput || undefined
  }, [value, searchInput])

  const { error, loading, refetch } = useQuery(TRAILERS_QUERY, {
    variables: {
      q: getInputValue || '',
      statuses,
      limit: 20
    },
    fetchPolicy: 'cache-first',
    onCompleted: data => {
      const rows = data?.trailers?.rows
      if (rows?.length) {
        setTrailers(rows)
        if (rows.length === 1) {
          handleChange(returnObject ? rows[0] : rows[0].uuid)
        }
      }
    }
  })

  if (error) {
    logger.error('TrailerSelector TRAILERS_QUERY error', error)
    responseHandler(error, 'error')
  }

  const handleSearch = useCallback(
    debounce((value: string) => {
      try {
        setSearchInput(value)
        refetch()
      } catch (error) {
        console.error(error)
      }
    }, 500),
    [refetch]
  )

  const handleChange = useCallback(
    (selectedValue: any) => {
      if (!returnObject) {
        onChange?.(selectedValue)
        return
      }

      if (!selectedValue) {
        onChange?.(null)
        return
      }

      const selectedTrailer = trailers?.find((t: any) => t?.uuid === selectedValue)
      onChange?.(selectedTrailer || selectedValue)
    },
    [onChange, returnObject, trailers]
  )

  const options = trailers?.map(t => ({
    label: showExpiry ? (
      getLabelExpiry(t)
    ) : (
      <Typography.Text>
        {t?.code} - {t?.registration}
      </Typography.Text>
    ),
    value: t?.uuid
  }))

  return (
    <Select
      id="trailer-selector"
      allowClear
      showSearch
      style={{ width: '100%', ...style }}
      loading={loading}
      onChange={handleChange}
      filterOption={false}
      onSearch={handleSearch}
      value={getInputValue || undefined}
      placeholder={t('common.selectATrailer')}
      notFoundContent={loading ? t('common.searching') : t('common.notFound')}
      options={options}
      {...selectProps}
    />
  )
}

export default TrailerSelector
