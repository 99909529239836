import { gql } from '@/types'

export const CREATE_CATEGORY = gql(`
  mutation createNewCategoryAss($createCategoryInput: CategoryInput!) {
    createCategory(input: $createCategoryInput) {
      _id
    }
  }
`)

export const PAGINATED_CATEGORY = gql(`
  query paginatedCategoryAss($limit: Int, $offset: Int, $q: String, $model: CategoryModel, $statuses: [ActiveStatus]) {
    categories(limit: $limit, offset: $offset, q: $q, model: $model, statuses: $statuses) {
      rows {
        _id
        model
        name
        code
        sort
        options
        description
        values
        status
        createdAt
        updatedAt
        deletedAt
      }
      pageInfo {
        limit
        offset
        count
      }
    }
  }
`)

export const CATEGORY = gql(`
  query categoryByIdAss($_id: ObjectId!) {
    category(_id: $_id) {
      _id
      name
      code
      description
      model
      sort
      options
      values
      status
      createdAt
      updatedAt
      deletedAt
    }
  }
`)

export const UPDATE_CATEGORY = gql(`
  mutation updateCategoryDetailsAss($categoryId: ObjectId!, $updateCategoryInput: CategoryInput!) {
    updateCategory(_id: $categoryId, input: $updateCategoryInput) {
      _id
    }
  }
`)

export const DELETE_CATEGORY = gql(`
  mutation sofDeleteCategoryAss($deleteCategoryId: ObjectId!) {
    deleteCategory(_id: $deleteCategoryId) {
      success
      message
    }
  }
`)

export const UNDELETE_CATEGORY = gql(`
  mutation undeleteCategoryAss($undeleteCategoryId: ObjectId!) {
    undeleteCategory(_id: $undeleteCategoryId) {
      success
      message
    }
  }
`)
