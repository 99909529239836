import { useState } from 'react'
import { CloseCircleOutlined, PlusOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Col, Flex, Input } from 'antd-v5'
import debounce from 'lodash/debounce'
import styled from 'styled-components'

const ActionWrapper = styled.div`
  padding: 0px 0 10px;
  text-align: right;

  .ant-input,
  .ant-select {
    width: 100%;
  }

  .ant-btn {
    font-size: 13px;
    height: 30px;
    line-height: 28px;

    .anticon {
      font-size: 14px;
      vertical-align: -0.175em;
    }
  }
`

const TableViewActions = props => {
  const { updateSearchKeyword, updatePageSize } = props
  const [keyword, setKeyword] = useState('')

  const handleSearch = debounce((value: any) => {
    updateSearchKeyword(value)
    updatePageSize(0)
  }, 300)

  const handleInput = value => {
    setKeyword(value)
    handleSearch(value)
  }

  const suffix = keyword ? <CloseCircleOutlined onClick={() => handleInput('')} /> : null

  return (
    <ActionWrapper>
      <Flex justify="space-between" align="top" gap={24}>
        <Col span={18}>
          <Input
            value={keyword}
            suffix={suffix}
            prefix={<SearchOutlined />}
            placeholder="Search here..."
            onChange={e => handleInput(e.target.value)}
          />
        </Col>
        <Col span={6} style={{ display: 'flex', flexDirection: 'row', gap: 5 }}>
          <Button href="/manage/feature-flags/create">
            <PlusOutlined />
            Create
          </Button>
        </Col>
      </Flex>
    </ActionWrapper>
  )
}

export default TableViewActions
