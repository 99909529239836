import type { ColumnProps } from 'antd/lib/table'
import { FeatureFlagStatus } from '@/types/graphql'

import { Flex } from 'antd-v5'
import styled from 'styled-components'

import { GET_FEATURE_FLAGS } from '@/components/Manage/FeatureFlags/schema'
import ToggleFlag from '@/components/Manage/FeatureFlags/TableView/ToggleFlag'
import ToggleFlagCompanies from '@/components/Manage/FeatureFlags/TableView/ToggleFlagCompanies'
import StatusFilter from '@/components/Manage/Shared/CrudType/TableView/StatusFilter'
import Link from 'App/components/Link'
import HelpIcon from 'App/components/Shared/HelpIcon'
import TableView from 'App/components/TableView'
import { NormalText, StyledAnchor } from 'App/components/TableView/Styled'
import WithSearchAndPagination from 'App/components/TableView/WithSearchAndPagination'
import PageHeader from '../../PageHeader'
import { PageView, ViewContent } from '../../Styled'
import TableActions from './Actions'

const StyledTableDiv = styled.div`
  .ant-table-thead > tr > th {
    padding: 16px 4px;
  }

  .ant-table-tbody > tr > td {
    padding: 16px 4px;
  }
`

const FeatureFlagsTableView = props => {
  const columns = (): ColumnProps<any>[] => [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`/manage/feature-flags/${record.uuid}`} target={undefined}>
          <StyledAnchor>{record.name}</StyledAnchor>
        </Link>
      )
    },
    {
      title: 'On/Off',
      key: 'isEnabled',
      dataIndex: 'isEnabled',
      render: (text, record) => <ToggleFlag featureFlag={record} />
    },
    {
      title: 'Base Companies',
      key: 'baseCompanies',
      dataIndex: 'baseCompanyUuids',
      align: 'center',
      render: (text, record) => <ToggleFlagCompanies featureFlag={record} />
    },
    {
      title: 'Description',
      key: 'description',
      dataIndex: 'description',
      render: text => <NormalText>{text}</NormalText>
    },

    {
      ...StatusFilter({
        status: FeatureFlagStatus
      })
    }
  ]

  return (
    <WithSearchAndPagination>
      {({ currentSize, updateSize, searchKeyword, updateSearchKeyword }) => (
        <PageView>
          <Flex align="center">
            <PageHeader title="Feature Flags" />
            <HelpIcon
              style={{ marginTop: 20, marginLeft: 10 }}
              articleUrl="https://shipxhelp.freshdesk.com/support/solutions/articles/47001266510-feature-flag"
            />
          </Flex>
          {/*@ts-ignore*/}
          <ViewContent fullWidth>
            <TableActions updatePageSize={updateSize} updateSearchKeyword={updateSearchKeyword} />
            <StyledTableDiv>
              <TableView
                currentSize={currentSize}
                searchKeyword={searchKeyword}
                updatePageSize={updateSize}
                renderColumns={columns}
                dataPropKey="featureFlags"
                queryProps={{
                  query: GET_FEATURE_FLAGS,
                  fetchPolicy: 'cache-and-network',
                  variables: {
                    input: {
                      q: searchKeyword,
                      offset: currentSize * 20,
                      limit: 20
                    }
                  }
                }}
              />
            </StyledTableDiv>
          </ViewContent>
        </PageView>
      )}
    </WithSearchAndPagination>
  )
}

export default FeatureFlagsTableView
