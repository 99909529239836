import { memo, useState } from 'react'
import { Flex, Row, Skeleton } from 'antd-v5'

import NoAccess from 'App/components/Shared/NoAccess'
import usePermissionIsAllowed from 'App/hooks/usePermissionIsAllowed'
import PageHeader from '../PageHeader'
import TableViewActions from './Actions'
import TableView from './TableView'

const Companies = () => {
  const [currentSize, updateSize] = useState(0)
  const [searchKeyword, updateSearchKeyword] = useState('')

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'company',
    permission: 'index'
  })

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) return <NoAccess />

  return (
    <Flex vertical gap="large">
      <PageHeader title="Companies" />
      <TableViewActions
        searchKeyword={searchKeyword}
        updatePageSize={updateSize}
        updateSearchKeyword={updateSearchKeyword}
      />
      <TableView
        currentSize={currentSize}
        searchKeyword={searchKeyword}
        updatePageSize={updateSize}
      />
    </Flex>
  )
}

export default memo(Companies)
