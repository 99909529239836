import { Table } from 'antd-v5'
import moment from 'moment'

import { voucherTypes } from 'App/utils/labelMap'
import CustomText from '../CustomText'
import { TableWrapper } from '../Styled'
import VoucherModalInvoiceNumber from './VoucherModalInvoiceNumber'

const getColumns = () => {
  return [
    {
      title: 'Voucher No.',
      dataIndex: 'voucherNumber',
      key: 'voucherNumber',
      render: (text, record) => <VoucherModalInvoiceNumber voucher={record} />
    },
    {
      title: 'Date',
      dataIndex: 'issueDate',
      key: 'issueDate',
      render: (text, record) => <CustomText text={moment(record.issueDate).format('ll')} />
    },
    {
      title: 'Voucher Type',
      key: 'type',
      render: (text, record) => {
        const label = (voucherTypes[record.type] && voucherTypes[record.type].text) || record.type

        return <CustomText text={label} />
      }
    },
    {
      title: 'Invoice No.',
      dataIndex: 'invoiceNumber',
      key: 'invoiceNumber',
      render: (text, record) => <CustomText text={record.invoiceNumber} />
    },
    {
      title: 'Gross Total',
      key: 'grossTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.subTotal} isNumeric />
    },
    {
      title: 'Tax Total',
      key: 'taxTotal',
      align: 'right',
      render: (text, record) => <CustomText text={record.taxTotal} isNumeric />
    },
    {
      title: 'Total',
      key: 'total',
      align: 'right',
      render: (text, record) => <CustomText text={record.total} isNumeric />
    }
  ]
}

const rowKey = voucher => voucher.uuid

const ConsolVoucherVouchersTable = props => {
  const { vouchers = [] } = props

  return (
    <TableWrapper>
      <Table dataSource={vouchers} rowKey={rowKey} columns={getColumns(props)} />
    </TableWrapper>
  )
}

export default ConsolVoucherVouchersTable
