import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Table } from 'antd-v5'
import maxBy from 'lodash/maxBy'
import PropTypes from 'prop-types'

import RelativeDateTime from 'App/components/RelativeDateTime'
import HaulageJobLink from 'App/components/Workflow/HaulageJobLink'
import { getContainerStats } from 'App/utils/booking'
import JobModalAction from '../JobModalAction'
import LegDateAction from './LegDateAction'

const indexTitleMap = ['Del.', 'Col.', 'T. Delivery']

const getMaxTripCounts = jobs => {
  const maxTripJob = maxBy(jobs, j => j.trips && j.trips.length)
  return maxTripJob ? maxTripJob.trips.length : 0
}

const getColumns = props => {
  const { jobs, onJobChanged } = props
  const maxTripCount = getMaxTripCounts(jobs)
  const tripColumns = []

  for (let i = 0; i < maxTripCount; i++) {
    const tripTitle = indexTitleMap[i] || `Trip ${i + 1}`

    tripColumns.push(
      {
        key: `trip-${i}-start`,
        title: `${tripTitle} Start`,
        className: 'xs-font',
        render: job => {
          if (job.trips && job.trips.length > 0) {
            const trip = (job.trips && job.trips[i]) || {}
            const leg = (trip && trip.legs && trip.legs[0]) || {}

            return <LegDateAction leg={leg} onChange={onJobChanged} />
          }
        }
      },
      {
        key: `trip-${i}-end`,
        title: `${tripTitle} End`,
        className: 'xs-font',
        render: job => {
          if (job.trips && job.trips.length > 0) {
            const trip = (job.trips && job.trips[i]) || {}
            const leg = (trip && trip.legs && trip.legs.slice(-1)[0]) || {}

            return <LegDateAction leg={leg} onChange={onJobChanged} />
          }
        }
      }
    )

    if (i === 0) {
      tripColumns.push({
        key: 'rfc-date',
        title: 'RFC Date',
        className: 'xs-font',
        render: job => {
          return <RelativeDateTime value={job.rfcDate} />
        }
      })
    }
  }

  return [
    {
      key: 'no',
      title: 'No',
      dataIndex: 'no',
      className: 'xs-font'
    },
    {
      key: 'size',
      title: 'Size',
      className: 'xs-font',
      render: (text, record) => {
        const stats = getContainerStats([record])
        const type = stats[0].type
        const size = stats[0].size

        return `${size}${type}`
      }
    },
    // {
    //   key: 'lastTripStatus',
    //   title: 'Last Trip Status',
    //   className: 'xs-font',
    //   render: (job) => {
    //     if (job.trips && job.trips.length > 0) {
    //       return job.trips.slice(-1)[0].status
    //     }
    //   }
    // },
    ...tripColumns,
    {
      title: 'Status',
      className: 'xs-font',
      render: (text, record) => {
        if (record.status === 'CANCELLED') {
          return <CloseCircleOutlined title="CANCELLED" style={{ color: 'red' }} />
        }

        return <CheckCircleOutlined title="ACTIVE" style={{ color: 'green' }} />
      }
    },
    {
      key: 'actions',
      title: '',
      className: 'xs-font',
      render: job => {
        return (
          <div style={{ textAlign: 'right' }}>
            <HaulageJobLink job={job} />
            <JobModalAction job={job} onChange={onJobChanged} />
          </div>
        )
      }
    }
  ]
}

const JobTable = props => {
  const columns = getColumns(props)

  return (
    <Table
      dataSource={props.jobs}
      rowKey={job => job.uuid}
      columns={columns}
      size="small"
      rowClassName="xs-font"
    />
  )
}

JobTable.propTypes = {
  jobs: PropTypes.array
}

export default JobTable
