import type { FeatureFlag } from '@/types/graphql'

import { useState } from 'react'
import { useMutation } from '@apollo/client'
import { Checkbox, Col, notification } from 'antd-v5'
import { pick, sortBy } from 'lodash'

import { UPDATE_FEATURE_FLAG } from '@/components/Manage/FeatureFlags/schema'
import useGlobalCompanyStore from '@/store/globalCompany'
import { logger } from '@/utils/logger'

type ToggleFlagCompaniesProps = {
  featureFlag: FeatureFlag
}

const ToggleFlagCompanies = (props: ToggleFlagCompaniesProps) => {
  const { featureFlag } = props
  const [baseCompanies, setBaseCompanies] = useState(featureFlag.baseCompanyUuids || [])
  const globalCompanies = useGlobalCompanyStore.use.globalCompanies()

  const [updateFeatureFlag] = useMutation(UPDATE_FEATURE_FLAG, {
    onError: error => {
      logger.error('ViewFeatureFlag FeatureFlagUpdate Mutation error', error)
      notification.error({
        message: 'Error',
        description: error.message.replace('GraphQL error: ', '')
      })
    },
    onCompleted: data => {
      if (data.updateFeatureFlag?.uuid) {
        notification.success({
          message: 'Success',
          description: 'FeatureFlag has been updated successfully.'
        })

        setBaseCompanies(data.updateFeatureFlag?.baseCompanyUuids || [])
      }
    }
  })

  const update = async (baseCompanyUuids: any[]) => {
    await updateFeatureFlag({
      variables: {
        input: {
          ...pick(featureFlag, ['uuid', 'name', 'description', 'isEnabled']),
          baseCompanyUuids
        }
      }
    })
  }

  return (
    <Checkbox.Group
      onChange={update}
      style={{ width: '100%' }}
      value={baseCompanies || featureFlag.baseCompanyUuids}
    >
      {sortBy(globalCompanies || [], a => a.name)?.map(field => (
        <Col key={field.uuid} span={8}>
          <Checkbox value={field.uuid} style={{ display: 'flex' }}>
            {field.name}
          </Checkbox>
        </Col>
      ))}
    </Checkbox.Group>
  )
}

export default ToggleFlagCompanies
