import { Component } from 'react'
import { Button, Popconfirm } from 'antd-v5'
import { compose } from 'recompose'

import ChangeDocumentCreatorStatusMutation from 'App/containers/documentCreator/mutation/changeDocumentCreatorStatus'
import respHandler from 'App/utils/responseHandler'

const enhance = compose(ChangeDocumentCreatorStatusMutation)

class ROTCancelAction extends Component {
  constructor(props) {
    super(props)
    this.state = {
      loading: false
    }
  }

  handleConfirm = async () => {
    const {
      changeDocumentCreatorStatus,
      documentCreator: { uuid },
      onCancelROT = () => {}
    } = this.props

    try {
      this.setState({
        loading: true
      })

      await changeDocumentCreatorStatus(uuid, 'CANCELLED')

      onCancelROT()
    } catch (error) {
      console.log(error)
      respHandler(error, 'error')
    } finally {
      this.setState({
        loading: false
      })
    }
  }

  render() {
    return (
      <Popconfirm
        arrow={false}
        title="Confirm to cancel this ROT?"
        onConfirm={this.handleConfirm}
        okText="Yes"
        okType="danger"
        cancelText="No"
      >
        <Button danger loading={this.state.loading}>
          Cancel ROT
        </Button>
      </Popconfirm>
    )
  }
}

export default enhance(ROTCancelAction)
