import { useState } from 'react'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import styled from 'styled-components'

import useCheckingEffect from 'App/hooks/useCheckingEffect'
import useDownloadEffect from 'App/hooks/useDownloadEffect'
import useQueueEffect from 'App/hooks/useQueueEffect'
import useGlobalCompanyStore from 'App/store/globalCompany'
import respHandler from 'App/utils/responseHandler'

const useAsyncPdfDownload = (voucher, type, selectedGlobalCompany) => {
  const [state, setState] = useState({
    status: 'none', // [none, generating, checking, downloading, downloaded]
    pdfUuid: null
  })

  useQueueEffect(state, setState, voucher, type, selectedGlobalCompany)
  useCheckingEffect(state, setState)
  useDownloadEffect(state, setState, voucher)

  const trigger = () => {
    respHandler('Downloading PDF, hang on...', 'load')
    setState({ status: 'queueing', pdfUuid: null })
  }

  return [state, trigger]
}

const StyledAnchor = styled.a`
  color: rgba(0, 0, 0, 0.65);
`

const VoucherPdfAsyncDownloadAction = props => {
  const { voucher, type, text } = props

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const [state, trigger] = useAsyncPdfDownload(voucher, type, selectedGlobalCompany)

  return (
    <div onClick={trigger}>
      <StyledAnchor>
        {state.status === 'generating' || state.status === 'checking' ? (
          <LoadingOutlined className="mr-2" />
        ) : (
          <DownloadOutlined className="mr-2" />
        )}
        {text}
      </StyledAnchor>
    </div>
  )
}

export default VoucherPdfAsyncDownloadAction
