import { Form } from 'antd'
import { Col, Flex, InputNumber } from 'antd-v5'

const FormItem = Form.Item

const ApprovedCountFilterFormItem = (props: any) => {
  const {
    form: { getFieldDecorator },
    value
  } = props

  return (
    <FormItem label="Approved Count">
      <Flex gap={8} justify="flex-start">
        <Col>
          <FormItem>
            {getFieldDecorator('approvedCount', {
              initialValue: value
            })(<InputNumber placeholder="Count" min={0} />)}
          </FormItem>
        </Col>
      </Flex>
    </FormItem>
  )
}

export default ApprovedCountFilterFormItem
