import type { MenuProps } from 'antd-v5'

import { memo, useState } from 'react'
import { MoreOutlined, UploadOutlined } from '@ant-design/icons'
import { Button, Dropdown, Typography } from 'antd-v5'

import Export from '@/components/Shared/ImportExport/Export'
import ImportFormModal from '@/components/Shared/ImportExport/ImportFormModal'
import useGlobalCompanyStore from '@/store/globalCompany'
import impExpUtil from '@/utils/importExport'

const ImportExport = ({ style, selectedEntity, filter = {} }: any) => {
  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const [isImportModalOpen, setIsImportModalOpen] = useState(false)

  const handleClickImport = () => {
    setIsImportModalOpen(true)
  }

  const handleCloseImport = () => {
    setIsImportModalOpen(false)
  }

  const items: MenuProps['items'] = [
    {
      key: 'export',
      label: (
        <Export
          selectedGlobalCompany={selectedGlobalCompany}
          filter={filter}
          impExpUtils={impExpUtil[selectedEntity]}
        />
      )
    },
    {
      key: 'import',
      label: (
        <Typography.Text onClick={handleClickImport}>
          <UploadOutlined /> Import
        </Typography.Text>
      )
    }
  ]

  return (
    <>
      <Dropdown menu={{ items }} trigger={['hover']}>
        <Button
          size="small"
          icon={<MoreOutlined className="text-xs pr-1" />}
          loading={!selectedGlobalCompany?.uuid}
          style={style}
        >
          Import / Export
        </Button>
      </Dropdown>
      <ImportFormModal
        open={isImportModalOpen}
        handleClose={handleCloseImport}
        onCancel={handleCloseImport}
        selectedGlobalCompany={selectedGlobalCompany}
        impExpUtils={impExpUtil[selectedEntity]}
      />
    </>
  )
}

export default memo(ImportExport)
