import { CheckCircleOutlined, CheckOutlined, WarningOutlined } from '@ant-design/icons'

const documentStates = {
  VERIFIED: 'check',
  UPLOADED: 'warning'
}

const StatusIcon = ({ uploadedDoc }) => {
  if (uploadedDoc.approvals?.length > 0)
    return uploadedDoc.approvals.map(a => <CheckCircleOutlined key={a.uuid} />)
  return uploadedDoc.status === documentStates.UPLOADED ? <WarningOutlined /> : <CheckOutlined />
}

export default StatusIcon
