import type { PortalCompany as PortalCompanyType } from '@/types/graphql'

import { useCallback, useEffect, useState } from 'react'
import { useQuery } from '@apollo/client'
import { Flex, Row, Skeleton, Table, Typography } from 'antd-v5'
import debounce from 'lodash/debounce'

import PageHeader from '@/components/Manage/PageHeader'
import { PORTAL_COMPANIES_QUERY } from '@/components/Manage/PortalCompany/schema'
import TableActions from '@/components/Manage/PortalCompany/Table/TableActions'
import HelpIcon from '@/components/Shared/HelpIcon'
import NoAccess from '@/components/Shared/NoAccess'
import usePermissionIsAllowed from '@/hooks/usePermissionIsAllowed'
import { logger } from '@/utils/logger'
import responseHandler from '@/utils/responseHandler'

const PortalCompany = () => {
  const [currentSize, setCurrentSize] = useState<number>(0)
  const [searchKeyword, setSearchKeyword] = useState<string>('')
  const [portalCompanies, setPortalCompanies] = useState<PortalCompanyType[]>([])

  const { loading: permLoading, hasPermission } = usePermissionIsAllowed({
    resource: 'portalCompany',
    permission: 'index'
  })

  const { loading, data, error, refetch } = useQuery(PORTAL_COMPANIES_QUERY, {
    variables: { query: searchKeyword || '', offset: currentSize * 20, limit: 20 },
    fetchPolicy: 'cache-and-network'
  })

  useEffect(() => setPortalCompanies(data?.portalCompanies?.rows), [data])

  const columns = [
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text: string, record: PortalCompanyType) => (
        <Typography.Link strong href={`/manage/portal-company/view?uuid=${record.uuid}`}>
          {text}
        </Typography.Link>
      )
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status'
    }
  ]

  const handleSearch = useCallback(
    debounce((e: React.ChangeEvent<HTMLInputElement>) => {
      setSearchKeyword(e.target.value)
      setCurrentSize(0)
      refetch()
    }, 300),
    []
  )

  if (permLoading) {
    return (
      <Row style={{ marginTop: '30px' }}>
        <Skeleton active />
      </Row>
    )
  }

  if (!hasPermission) return <NoAccess />

  if (error) {
    logger.error('Failed to load Portal Companies: ', error)
    responseHandler(error, 'error')
  }

  return (
    <Flex vertical gap="middle">
      <Flex align="center">
        <PageHeader title="Portal Companies" />
        <HelpIcon
          style={{ marginTop: 20, marginLeft: 10 }}
          articleUrl="https://shipxhelp.freshdesk.com/support/solutions/articles/47001209649-portal-company-admin"
        />
      </Flex>
      <TableActions isLoading={loading} handleSearch={handleSearch} />
      <Table
        columns={columns}
        loading={loading}
        dataSource={portalCompanies}
        rowKey={record => record.uuid}
      />
    </Flex>
  )
}

export default PortalCompany
