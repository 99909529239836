import { gql } from '@/types'

export const JOBS_SEARCH_QUERY = gql(`
  query jobsSearch($input: JobsSearchInput) {
    jobsSearch(input: $input) {
      rows {
        uuid
        trips {
          uuid
          legs {
            uuid
          }
        }
      }
    }
  }
`)

export const UPDATE_LEG_TIMING = gql(`
  mutation updateLegTiming($input: UpdateLegTimingInput!) {
    updateLegTiming(input: $input) {
      success
      message
    } 
  }
`)

export const UPDATE_JOB = gql(`
  mutation updateJob($input: UpdateJobInput) {
    updateJob(input: $input) {
      uuid
    }
  }
`)

export const TRANSPORT_JOBS = gql(`
  query transportJobsUuid($input: TransportJobQuery!) {
    transportJobs(input: $input) {
      rows {
        uuid
      }
    }
  }
`)

export const JOBS_SEARCH_JSON = gql(`
  query jobsSearchJson($input: JobsSearchInput) {
    jobsSearchJson(input: $input) {
      rows
      pageInfo {
        count
        limit
        offset
      }
    }
  }
`)
