import type { ApolloError } from '@apollo/client'
import type { PermissionProps } from './Permission'

import { LoadingOutlined } from '@ant-design/icons'
import { useMutation } from '@apollo/client'
import { Checkbox } from 'antd-v5'

import {
  CREATE_PERMISSION,
  DELETE_PERMISSION,
  PERMISSION_QUERY
} from '@/components/Manage/Permissions/TableView/schema'
import notify from '@/utils/notify'

type PermissionCheckBoxProps = PermissionProps & {
  displayName?: string
  isAllowed?: boolean
}

const PermissionCheckBox = (props: PermissionCheckBoxProps) => {
  const { displayName, isAllowed, resourceName, resourceType, selectedRole } = props

  const handleCompleted = () => {
    notify(
      {
        title: `Permission ${isAllowed ? 'Revoked' : 'Created'}`,
        description: `Permission ${resourceType} in ${resourceName} has been ${
          isAllowed ? 'revoked' : 'created'
        }.`
      },
      'success'
    )
  }

  const handleError = (error: ApolloError) => {
    console.error(error.stack)
    notify(error.graphQLErrors[0]?.message ?? 'sss', 'error')
  }

  const [mutatePermission, { loading }] = useMutation(
    isAllowed ? DELETE_PERMISSION : CREATE_PERMISSION,
    {
      onCompleted: handleCompleted,
      onError: handleError,
      refetchQueries: [{ query: PERMISSION_QUERY, variables: { role: selectedRole } }]
    }
  )

  const handleCheck = () => {
    if (!selectedRole) {
      notify('Role is required to change permissions', 'info')
      return
    }

    mutatePermission({
      variables: {
        role: selectedRole,
        resource: resourceName,
        permission: resourceType
      }
    })
  }

  if (loading) return <LoadingOutlined />

  return (
    <Checkbox checked={isAllowed} onChange={handleCheck}>
      {displayName}
    </Checkbox>
  )
}

export default PermissionCheckBox
