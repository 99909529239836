import { Table } from 'antd-v5'
import moment from 'moment'

import ROTEditAction from '@/components/Booking/General/ROTEditAction'
import CompanyLabel from '@/components/Company/CompanyLabel'
import { getContainerStats } from '@/utils/booking'

const getColumns = props => {
  const { onChange } = props

  return [
    {
      title: 'Submitted Date',
      dataIndex: 'data.submittedDate',
      className: 'xs-font',
      render: (text, record) => {
        const {
          data: { submittedDate }
        } = record
        if (!submittedDate) {
          return ''
        }

        return moment(submittedDate).format('ll')
      }
    },
    {
      title: 'Required Date',
      dataIndex: 'data.requiredDate',
      className: 'xs-font',
      render: (text, record) => {
        const {
          data: { requiredDate }
        } = record
        if (!requiredDate) {
          return ''
        }

        return moment(requiredDate).format('ll')
      }
    },
    {
      title: 'Haulier',
      className: 'xs-font',
      render: (text, record) => {
        return <CompanyLabel company={record.data.haulier} />
      }
    },
    {
      title: 'Jobs',
      className: 'xs-font',
      render: (text, record) => {
        const {
          data: { jobs }
        } = record
        const containerStats = getContainerStats(jobs)
          .map(s => `${s.count}x${s.size} ${s.type}`)
          .join(', ')

        return containerStats
      }
    },
    {
      title: 'Status',
      dataIndex: 'status',
      className: 'xs-font'
    },
    {
      title: 'ROT No.',
      dataIndex: 'data.requestNo',
      className: 'xs-font',
      render: text => {
        return text || '-'
      }
    },
    {
      title: '',
      className: 'xs-font',
      render: (text, record) => {
        return <ROTEditAction documentCreator={record} onChange={onChange} />
      }
    }
  ]
}

const rowKey = record => {
  return record.uuid
}

const rowClassName = () => {
  return 'xs-font'
}

const ROTTable = props => {
  const { documentCreators } = props

  return (
    <Table
      columns={getColumns(props)}
      dataSource={documentCreators}
      rowKey={rowKey}
      rowClassName={rowClassName}
      size="small"
    />
  )
}

export default ROTTable
