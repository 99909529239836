import { Component } from 'react'
import { EditOutlined } from '@ant-design/icons'
import { Button, Col, Modal, Row } from 'antd-v5'
import moment from 'moment'

import ConsolVoucherChangeStatusButton from 'App/components/Voucher/Management/ConsolVoucherChangeStatusButton'
import ConsolVoucherVouchersTable from 'App/components/Voucher/Management/ConsolVoucherVouchersTable'
import Address from 'App/components/Voucher/Management/View/Address'
import LabelValue from 'App/components/Voucher/Management/View/LabelValue'
import { StyledButton } from '../../ButtonWrapper'

const ConsolVoucherModalButton = class extends Component {
  constructor(props) {
    super(props)
    this.state = {
      visible: false
    }
  }

  handleClick = () => {
    this.setState({
      visible: true
    })
  }

  handleOnSuccess = () => {
    const { onChange } = this.props

    this.setState({
      visible: false
    })

    if (onChange) {
      onChange()
    }
  }

  renderModalFooterDraft = () => {
    const { consolVoucher } = this.props

    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleModalCancel}>Cancel</Button>
          <ConsolVoucherChangeStatusButton
            consolVoucher={consolVoucher}
            buttonProps={{ type: 'danger' }}
            status="CANCELLED"
            onSuccess={this.handleOnSuccess}
          />
          <ConsolVoucherChangeStatusButton
            consolVoucher={consolVoucher}
            buttonProps={{ type: 'primary' }}
            status="SUBMITTED"
            onSuccess={this.handleOnSuccess}
          />
        </Col>
      </Row>
    )
  }

  renderModalFooterSubmitted = () => {
    const { consolVoucher } = this.props

    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleModalCancel}>Cancel</Button>
          <ConsolVoucherChangeStatusButton
            consolVoucher={consolVoucher}
            buttonProps={{ type: 'primary' }}
            status="APPROVED"
            onSuccess={this.handleOnSuccess}
          />
        </Col>
      </Row>
    )
  }

  renderModalFooterApproved = () => {
    const { consolVoucher } = this.props

    return (
      <Row>
        <Col span={24}>
          <Button onClick={this.handleModalCancel}>Cancel</Button>
          <ConsolVoucherChangeStatusButton
            consolVoucher={consolVoucher}
            buttonProps={{ type: 'primary' }}
            status="VOID"
            onSuccess={this.handleOnSuccess}
          />
        </Col>
      </Row>
    )
  }

  handleModalCancel = () => {
    this.setState({
      visible: false
    })
  }

  statusModalFooterRender = {
    DRAFT: this.renderModalFooterDraft,
    SUBMITTED: this.renderModalFooterSubmitted,
    APPROVED: this.renderModalFooterApproved
  }

  renderModalFooter = () => {
    const { consolVoucher } = this.props

    const render = this.statusModalFooterRender[consolVoucher.status]

    if (!render) {
      return null
    }

    return render()
  }

  render() {
    const { consolVoucher } = this.props
    const firstVoucher = consolVoucher.vouchers[0]
    const transactionType = firstVoucher.transactionType
    const company = transactionType === 'ACCREC' ? firstVoucher.customer : firstVoucher.vendor

    return (
      <>
        <StyledButton onClick={this.handleClick}>
          <EditOutlined />
        </StyledButton>
        <Modal
          width={800}
          destroyOnClose
          title="Consolidated Voucher"
          visible={this.state.visible}
          footer={this.renderModalFooter()}
          onCancel={this.handleModalCancel}
        >
          <LabelValue label="Issue Date" value={moment(consolVoucher.issueDate).format('ll')} />
          <LabelValue label="Reference" value={consolVoucher.reference} />
          <LabelValue label="Type" value={consolVoucher.type} />
          <LabelValue label="Status" value={consolVoucher.status} />
          <LabelValue label="Company" value={company.name} />
          <LabelValue label="Address" value={<Address address={firstVoucher.address} />} />
          <ConsolVoucherVouchersTable vouchers={consolVoucher.vouchers} />
        </Modal>
      </>
    )
  }
}

export default ConsolVoucherModalButton
