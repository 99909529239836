import { Component } from 'react'
import { Form } from 'antd'
import { Col, Row } from 'antd-v5'

import DocumentSelector from 'App/components/DocumentSelector'
import Selectables from 'App/components/Voucher/Attachments/Selectables'
import { SectionHeader } from './Container'

const FormItem = Form.Item

const AttachmentsSection = class extends Component {
  handleSelectDocument = document => {
    const { value, onChange } = this.props
    onChange([...value, document.uuid])
  }

  handleRemoveDocument = document => () => {
    const { value, onChange } = this.props
    const newValue = value.filter(v => v !== document.uuid)
    onChange(newValue)
  }

  render() {
    const { selectable = true, removable = true, selectableDocuments, value } = this.props
    const selectedDocuments = selectableDocuments.filter(d => value.includes(d.uuid))

    return (
      <>
        <SectionHeader>
          Attachments
          <span style={{ marginLeft: '10px' }}>
            <Selectables
              selectable={selectable}
              selectableDocuments={selectableDocuments}
              selectedDocuments={selectedDocuments}
              onSelectSingleDocument={this.handleSelectDocument}
            />
          </span>
        </SectionHeader>
        <FormItem>
          {selectedDocuments.length === 0 && <span>No documents attached.</span>}
          {selectedDocuments.length > 0 && (
            <Row>
              {selectedDocuments.map(doc => {
                return (
                  <Col key={doc.uuid} span={6}>
                    <DocumentSelector
                      hrefUrl={`/booking/document/${doc.uuid}`}
                      uploadedDoc={doc}
                      onRemove={this.handleRemoveDocument(doc)}
                      removable={removable}
                    />
                  </Col>
                )
              })}
            </Row>
          )}
        </FormItem>
      </>
    )
  }
}

export default AttachmentsSection
