import { gql } from '@apollo/client'

export const ADDRESS_UPDATE = gql`
  mutation updateAddress2($input: UpdateAddressInput!) {
    updateAddress(input: $input) {
      name
    }
  }
`

export const ADDRESS_CREATE = gql`
  mutation addAddress1($input: AddAddressInput!) {
    addAddress(input: $input) {
      uuid
    }
  }
`

export const ADDRESS_DELETE = gql`
  mutation deleteAddress2($uuid: UUID) {
    deleteAddress(uuid: $uuid) {
      success
    }
  }
`
