import type { ChargeItem, Currency } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { ChargeRateType } from '@/types/graphql'

import { useState } from 'react'
import { connect } from 'react-redux'
import { useApolloClient } from '@apollo/client'
import { Form } from 'antd'
import { Input, InputNumber } from 'antd-v5'
import numeral from 'numeral'
import { compose } from 'recompose'
import { bindActionCreators } from 'redux'

import BillingUnitSelect from '@/components/Select/BillingUnitSelect'
import CurrencySelect from '@/components/Select/CurrencySelect'
import ChargeItemSelect from '@/components/Select/TypeToFetch/ChargeItemSelect'
import multiExchangeRatesQuery from '@/containers/bulk/multiExchangeRates/schema'
import exchangeRatesQuery from '@/containers/exchangeRates/schema/exchangeRates'
import * as chargeItemActions from '@/states/reducers/chargeItem'
import useGlobalCompanyStore from '@/store/globalCompany'
import handleResp from '@/utils/responseHandler'
import { estimateCostItemQuery, quotationFromBookingQuery } from './schemas'
import { StyledForm, StyledLegend } from './Styled'

const { TextArea } = Input
const FormItem = Form.Item

const enhance = compose<CostItemFormProps, any>(
  connect(
    state => ({
      currencies: state.currency.currencies,
      selectedChargeItem: state.chargeItem.selectedChargeItem
    }),
    // @ts-expect-error leave for now
    dispatch => ({ dispatch, ...bindActionCreators({ ...chargeItemActions }, dispatch) })
  )
)

const formItemLayout = {
  labelCol: { span: 8 },
  wrapperCol: { span: 16 }
}

type CostItemFormProps = {
  form: WrappedFormUtils
  costItem: any
  currencies: Currency[]
  mode: 'add' | 'edit'
  selectedChargeItem: ChargeItem
  updateSelectedChargeItem: (chargeItem: ChargeItem) => void
}

const CostItemForm = (props: CostItemFormProps) => {
  const { form, costItem, currencies, mode, selectedChargeItem, updateSelectedChargeItem } = props
  const { getFieldDecorator } = form

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()

  const client = useApolloClient()

  const [state, setState] = useState({
    chargeItem: null,
    quotation: null
  })

  const fetchExchangeRate = async (from, to) => {
    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: exchangeRatesQuery,
        variables: {
          toUuid: to,
          fromUuid: from,
          date: new Date()
        }
      })

      const exchangeRate = data && data.exchangeRates && data.exchangeRates.rows[0]

      if (data.exchangeRates.rows.length === 0) {
        handleResp(
          'No exchange rate found. Rate of 0 was set, please change accordingly.',
          'warning'
        )
      }

      return (exchangeRate && exchangeRate.rate) || 0
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  const fetchMultiExchangeRates = async (sellFrom, sellTo, costFrom, costTo) => {
    try {
      const { data } = await client.query({
        fetchPolicy: 'network-only',
        query: multiExchangeRatesQuery,
        variables: {
          sellFromUuid: sellFrom,
          sellToUuid: sellTo,
          costFromUuid: costFrom,
          costToUuid: costTo,
          date: new Date()
        }
      })

      return data || {}
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  const fetchQuotation = async () => {
    try {
      const { data } = await client.query({
        query: quotationFromBookingQuery,
        variables: { uuid: costItem.bookingUuid }
      })

      return data?.booking?.quotation
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  const estimateCostItem = async chargeItemUuid => {
    try {
      const { data } = await client.query({
        query: estimateCostItemQuery,
        variables: {
          chargeItemUuid,
          bookingUuid: costItem.bookingUuid
        }
      })

      return data?.estimateCostItem
    } catch (error) {
      handleResp(error, 'error')
      return null
    }
  }

  const handleCurrencyChange = source => async value => {
    const sgcCompany = selectedGlobalCompany

    if (sgcCompany?.currency?.uuid !== value) {
      const exchangeRate = await fetchExchangeRate(value, sgcCompany?.currency?.uuid)

      form.setFieldsValue({
        [source]: exchangeRate
      })
    } else {
      form.setFieldsValue({
        [source]: 1
      })
    }
  }

  const setChargeItemValues = async (sgcCompany, chargeItem, updateSelectedChargeItem, form) => {
    const quotation = await fetchQuotation()
    const estimatedCostItem = await estimateCostItem(chargeItem.uuid)
    setState({ quotation, chargeItem })

    // Find matching quotation item
    const quotationItems = quotation?.quotationItems
    const unit = form.getFieldValue('unit')
    const size = form.getFieldValue('size')
    let quoteItem

    if (quotationItems?.length) {
      quoteItem =
        quotationItems.find(
          qi => qi.chargeItem.uuid === chargeItem.uuid && qi.size === size && qi.unit === unit
        ) ||
        quotationItems.find(qi => qi.chargeItem.uuid === chargeItem.uuid && qi.unit === unit) ||
        quotationItems.find(qi => qi.chargeItem.uuid === chargeItem.uuid)
    }

    const sellFrom = quoteItem?.sellCurrency?.uuid || chargeItem.sellCurrency?.uuid
    const sellTo = sgcCompany?.currency?.uuid
    const costFrom = quoteItem?.costCurrency?.uuid || chargeItem.costCurrency?.uuid
    const costTo = sgcCompany?.currency?.uuid
    const isFixed = quoteItem?.rateType
      ? quoteItem.rateType === ChargeRateType.Fixed
      : chargeItem.rateType === ChargeRateType.Fixed
    const nonFixedSellRate = numeral(
      estimatedCostItem?.costRate || quoteItem?.costRate || chargeItem?.costRate
      // @ts-expect-error leave for now
    ).multiply(quoteItem?.costTax?.percentage || chargeItem?.costTax?.percentage)?._value

    form.setFieldsValue({
      sellBaseRate: isFixed
        ? estimatedCostItem?.sellRate || quoteItem?.sellRate || chargeItem.sellRate
        : nonFixedSellRate,
      sellCurrencyUuid: chargeItem.sellCurrency?.uuid,
      costBaseRate: estimatedCostItem?.costRate || quoteItem?.costRate || chargeItem.costRate,
      costCurrencyUuid: chargeItem.costCurrency?.uuid,
      sellExchangeRate: 1,
      costExchangeRate: 1
    })

    if (sellFrom !== sellTo && costFrom !== costTo) {
      const exchangeRates = await fetchMultiExchangeRates(sellFrom, sellTo, costFrom, costTo)

      if (exchangeRates) {
        form.setFieldsValue({
          sellExchangeRate: exchangeRates.sellExchangeRate && exchangeRates.sellExchangeRate.rate,
          costExchangeRate: exchangeRates.costExchangeRate && exchangeRates.costExchangeRate.rate
        })
      }
    } else if (sellFrom !== sellTo) {
      const exchangeRate = await fetchExchangeRate(sellFrom, sellTo)

      form.setFieldsValue({
        sellExchangeRate: exchangeRate.rate
      })
    } else if (costFrom !== costTo) {
      const exchangeRate = await fetchExchangeRate(costFrom, costTo)

      form.setFieldsValue({
        costExchangeRate: exchangeRate.rate
      })
    }

    if (!unit) {
      form.setFieldsValue({
        unit: chargeItem.unit
      })
    }

    updateSelectedChargeItem(chargeItem)
  }

  const handleUnitSizeChange = async () => {
    const sgcCompany = selectedGlobalCompany

    if (!state?.chargeItem) {
      return
    }

    const { chargeItem } = state

    await setChargeItemValues(sgcCompany, chargeItem, updateSelectedChargeItem, form)
  }

  const handleOnChargeItemChange = async (value, selectedOption) => {
    const sgcCompany = selectedGlobalCompany
    const chargeItem = selectedOption

    await setChargeItemValues(sgcCompany, chargeItem, updateSelectedChargeItem, form)
  }

  const currencySelectables = currencies.map(currency => ({
    text: currency.code,
    value: currency.uuid
  }))

  const sellDetails = {
    sellTaxUuid: costItem.sellTax && costItem.sellTax.uuid,
    sellBaseRate: costItem.sellBaseRate,
    sellCurrencyUuid: costItem.sellCurrency && costItem.sellCurrency.uuid,
    sellExchangeRate: costItem.sellExchangeRate
  }

  const costDetails = {
    costTaxUuid: costItem.costTax && costItem.costTax.uuid,
    costBaseRate: costItem.costBaseRate,
    costCurrencyUuid: costItem.costCurrency && costItem.costCurrency.uuid,
    costExchangeRate: costItem.costExchangeRate
  }

  return (
    <StyledForm>
      {costItem.uuid &&
        getFieldDecorator('uuid', {
          initialValue: costItem.uuid
        })(<input type="hidden" />)}
      <div style={{ marginBottom: '16px' }}>
        <FormItem label="Charge Item" {...formItemLayout}>
          {mode === 'add' &&
            getFieldDecorator('chargeItemUuid', {
              initialValue: costItem.chargeItem && costItem.chargeItem.uuid,
              rules: [
                {
                  required: true,
                  message: 'Field required.'
                }
              ]
            })(
              <ChargeItemSelect
                name={costItem.chargeItem && costItem.chargeItem.code}
                onChange={handleOnChargeItemChange}
              />
            )}
          {mode === 'edit' && (
            <ChargeItemSelect
              name={costItem.chargeItem?.code}
              value={costItem.chargeItem?.uuid}
              disabled
            />
          )}
        </FormItem>
        <FormItem label="Quantity" {...formItemLayout}>
          {getFieldDecorator('quantity', {
            initialValue: costItem.quantity || 1,
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Unit" {...formItemLayout}>
          {getFieldDecorator('unit', {
            initialValue:
              costItem.unit ||
              (costItem.chargeItem && costItem.chargeItem.unit) ||
              selectedChargeItem.unit
          })(<BillingUnitSelect onChange={handleUnitSizeChange} />)}
        </FormItem>
        <FormItem label="Size" {...formItemLayout}>
          {getFieldDecorator('size', {
            initialValue: costItem.size
          })(<InputNumber onChange={handleUnitSizeChange} />)}
        </FormItem>
        <FormItem label="Description" {...formItemLayout}>
          {getFieldDecorator('description', {
            initialValue: costItem.description
          })(<TextArea />)}
        </FormItem>
      </div>
      <fieldset style={{ marginBottom: '16px' }}>
        <StyledLegend>Sell</StyledLegend>
        <FormItem label="Sell Currency" {...formItemLayout}>
          {getFieldDecorator('sellCurrencyUuid', {
            initialValue:
              sellDetails.sellCurrencyUuid ||
              (currencySelectables.length && currencySelectables[0].value),
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<CurrencySelect onChange={handleCurrencyChange('sellExchangeRate')} />)}
        </FormItem>
        <FormItem label="Sell Exchange Rate" {...formItemLayout}>
          {getFieldDecorator('sellExchangeRate', {
            initialValue: sellDetails.sellExchangeRate,
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Sell Base Rate" {...formItemLayout}>
          {getFieldDecorator('sellBaseRate', {
            initialValue: sellDetails.sellBaseRate,
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<InputNumber />)}
        </FormItem>
      </fieldset>
      <fieldset>
        <StyledLegend>Cost</StyledLegend>
        <FormItem label="Cost Currency" {...formItemLayout}>
          {getFieldDecorator('costCurrencyUuid', {
            initialValue:
              costDetails.costCurrencyUuid ||
              (currencySelectables.length && currencySelectables[0].value),
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<CurrencySelect onChange={handleCurrencyChange('costExchangeRate')} />)}
        </FormItem>
        <FormItem label="Cost Exchange Rate" {...formItemLayout}>
          {getFieldDecorator('costExchangeRate', {
            initialValue: costDetails.costExchangeRate,
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<InputNumber />)}
        </FormItem>
        <FormItem label="Cost Base Rate" {...formItemLayout}>
          {getFieldDecorator('costBaseRate', {
            initialValue: costDetails.costBaseRate,
            rules: [
              {
                required: true,
                message: 'Field required.'
              }
            ]
          })(<InputNumber />)}
        </FormItem>
      </fieldset>
    </StyledForm>
  )
}

export default enhance(CostItemForm)
