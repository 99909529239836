import type { Company } from '@/types/graphql'
import type { TableColumnsType } from 'antd-v5'
import { CompanyStatus, CompanyType } from '@/types/graphql'

import { LinkOutlined } from '@ant-design/icons'
import { Table, Typography } from 'antd-v5'
import join from 'lodash/join'

const TableViewData = ({ items, pagination }) => {
  const { onSizeChange } = pagination

  const dataSource = items?.map(item => ({ ...item, key: item.uuid }))

  const columns: TableColumnsType<Company> = [
    {
      title: 'Name',
      key: 'name',
      dataIndex: 'name',
      render: (name, record) => {
        const baseCompany = record.ownerUuid === record.accountUuid
        return (
          <Typography.Link strong href={`/manage/companies/view?uuid=${record.uuid}`}>
            {baseCompany ? (
              <>
                <LinkOutlined />
                {name}
              </>
            ) : (
              <>{name}</>
            )}
          </Typography.Link>
        )
      }
    },
    {
      title: 'Code',
      key: 'code',
      dataIndex: 'code',
      render: code => <Typography.Text>{code || '-'}</Typography.Text>
    },
    {
      title: 'Debtor',
      key: 'debtorCode',
      dataIndex: 'debtorCode',
      render: debtorCode => <Typography.Text>{debtorCode || '-'}</Typography.Text>
    },
    {
      title: 'Creditor',
      key: 'creditorCode',
      dataIndex: 'creditorCode',
      render: creditorCode => <Typography.Text>{creditorCode || '-'}</Typography.Text>
    },
    {
      title: 'Types',
      key: 'types',
      dataIndex: 'types',
      filters: Object.values(CompanyType).map(type => ({ text: type, value: type })),
      onFilter: (value, record) => record.types?.includes(value as CompanyType) ?? false,
      render: types => (
        <Typography.Text type="secondary">{join(types, ', ') || '-'}</Typography.Text>
      )
    },
    {
      title: 'Country',
      key: 'country',
      dataIndex: 'country',
      render: country => <Typography.Text>{country?.name || '-'}</Typography.Text>
    },
    {
      title: 'Status',
      key: 'status',
      dataIndex: 'status',
      filters: Object.values(CompanyStatus).map(status => ({
        text: status,
        value: status
      })),
      onFilter: (value, record) => record.status === value,
      render: (status: CompanyStatus) => (
        <Typography.Text strong type="secondary" className="text-xxs">
          {status.toUpperCase()}
        </Typography.Text>
      )
    }
  ]

  return (
    <Table
      // size="small"
      columns={columns}
      dataSource={dataSource}
      locale={{ emptyText: 'No items found.' }}
      pagination={{
        total: pagination?.total,
        simple: true,
        current: pagination?.offset / pagination.limit + 1,
        pageSize: pagination?.limit,
        onChange: page => onSizeChange(page - 1)
      }}
    />
  )
}

export default TableViewData
