import type { CollectedFileMetaData } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import type { Booking, MakeBookingInput, MutationUploadBookingDocumentArgs } from '@/types/graphql'
import type { WrappedFormUtils } from 'antd/lib/form/Form'
import { DynamicFieldControl } from '@/types/graphql'

import { memo, useCallback, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { Form } from 'antd'
import { Button, Checkbox, Flex, Typography } from 'antd-v5'
import { groupBy, sortBy } from 'lodash'

import ConfirmationSubForm from '@/components/Booking/NewBookingForm/ConfirmationSubForm'
import DefaultActions from '@/components/Booking/NewBookingForm/DefaultAction'
import FormSteps from '@/components/Booking/NewBookingForm/FormSteps'
import HiddenFieldsSubForm from '@/components/Booking/NewBookingForm/HiddenFieldsSubForm'
import ShipperConsigneeSubForm from '@/components/Booking/NewBookingForm/ShipperConsigneeSubForm'
import SubmitAction from '@/components/Booking/NewBookingForm/SubmitAction'
import { handleBookingDocumentUpload } from '@/components/Booking/NewBookingForm/SubmitAction/helper'
import TransportDetailsSubForm from '@/components/Booking/NewBookingForm/TransportDetailsSubForm'
import VisibilityDiv from '@/components/Booking/NewBookingForm/VisibilityDiv'
import useJobTypes from '@/hooks/useJobTypes'
import { PluginComponentLocation } from '@/plugins/options'
import PluginLoader from '@/plugins/PluginLoader'
import { useBookingStore } from '@/store/booking'
import useGlobalCompanyStore from '@/store/globalCompany'

const getTripsErrorMessage = errors => {
  const messages: string[] = []

  errors.forEach(subArr => {
    subArr.forEach(item => {
      if (item) {
        Object.values(item).forEach((errorObj: any) => {
          if (errorObj.errors && errorObj.errors.length > 0) {
            messages.push(errorObj.errors[0].message)
          }
        })
      }
    })
  })
  return messages
}

const getErrorMessages = errors => {
  const messages: string[] = []

  const traverseErrors = obj => {
    for (const key in obj) {
      const error = obj[key]
      if (error?.errors && error?.errors?.length > 0) {
        messages.push(error.errors?.[0].message)
      } else if (typeof error === 'object') {
        traverseErrors(error)
      }
    }
  }
  traverseErrors(errors)
  return messages
}

const CreateBkErr = ({ jobErr }) => <Typography.Text type="danger">{jobErr}</Typography.Text>

type NewBookingTransportFormProps = {
  form: WrappedFormUtils
  duplicateBookingObject: Booking
}

const NewBookingTransportForm = (props: NewBookingTransportFormProps) => {
  const { form, duplicateBookingObject } = props

  const navigate = useNavigate()
  const { jobTypes, requestJobTypes } = useJobTypes()

  const [step, setStep] = useState(0)
  const [jobErr, setJobErr] = useState('')
  const [showJobErr, setShowJobErr] = useState(false)
  const [extraItems, setExtraItems] = useState<{ title: string }[]>([])
  const [isOverrideBlockDuplicate, setIsOverrideBlockDuplicate] = useState(false)
  const [makeBookingResponse, setMakeBookingResponse] = useState<null | Booking[]>(null)

  const selectedGlobalCompany = useGlobalCompanyStore.use.selectedGlobalCompany()
  const resetSelectedBillToCompany = useBookingStore.use.resetSelectedBillToCompany()

  const onBack = useCallback(() => {
    if (step) setStep(step - 1)
  }, [step])

  useEffect(() => {
    requestJobTypes()
    return () => resetSelectedBillToCompany()
  }, [])

  const checkRequiredFields = () => {
    if (!jobTypes.length) requestJobTypes()

    if (step === 0) {
      form.validateFields(err => {
        if (err) {
          setShowJobErr(true)
          const errorMessages = getErrorMessages(err).join(', ')
          setJobErr(errorMessages)
        } else {
          setShowJobErr(false)
          setStep(step + 1)
        }
      })
    }

    form.getFieldValue('jobs').forEach(async (job, jobIndex) => {
      if (step === 1) {
        const checkRequiredFields = jobTypes?.find(type => type?.code === job?.type)

        if (!checkRequiredFields) {
          setShowJobErr(true)
          return setJobErr(`Job #${jobIndex + 1}:Type is required`)
        }

        const defaultRequiredFields = ['type']
        const fieldsNeedToValidate = ['requiredFields', 'dynamicFields', 'tripDynamicFields']

        const jobInputFields = fieldsNeedToValidate
          .flatMap(field =>
            checkRequiredFields[field]
              .filter(f => f.control === 'REQUIRED')
              .map(f => f.key || f.name)
          )
          .concat(defaultRequiredFields)

        // get job details error messages
        const validateDetailJob = Object.entries(job.details || {}).map(([key, value]) => {
          if (jobInputFields.includes(key) && !value) {
            return { [key]: { errors: [{ message: `Job #${jobIndex + 1}:${key} is required` }] } }
          }
          return null
        })

        const tripsInputFields = ['fromCompanyUuid', 'fromUuid', 'toCompanyUuid', 'toUuid']
        const tripDynamicFields = checkRequiredFields?.tripDynamicFields
          ?.filter(f => f?.control === DynamicFieldControl.Required)
          .map(f => f?.key)

        // get job trips errror messages
        const validateTrips = job.trips.map((trip, tripIndex) => {
          const missingFields = tripsInputFields.filter(field => !Object.keys(trip).includes(field))
          const missingFieldsErrors = missingFields.map(field => ({
            [field as string]: {
              errors: [{ message: `Trip #${tripIndex + 1}: ${field} is required` }]
            }
          }))
          const missingTripDynamicFields = tripDynamicFields?.filter(f => f && !trip.details[f])
          const missingTripDynamicFieldsErrors = missingTripDynamicFields?.map(field => ({
            [field as string]: {
              errors: [{ message: `Trip #${tripIndex + 1}: ${field} is required` }]
            }
          }))

          const fieldErrors = Object.entries(trip).map(([key, value]) => {
            if (tripsInputFields.includes(key) && !value) {
              return {
                [key]: { errors: [{ message: `Trip #${tripIndex + 1}: ${key} is required` }] }
              }
            }
            return null
          })

          return [...missingFieldsErrors, ...fieldErrors, ...(missingTripDynamicFieldsErrors || [])]
        })

        const detailErrorMessage = getErrorMessages(validateDetailJob)
        const tripsErrorMessage = getTripsErrorMessage(validateTrips)

        if (detailErrorMessage.length || tripsErrorMessage.length) {
          setShowJobErr(true)
          const errorMessages = [...detailErrorMessage, ...tripsErrorMessage].join(', ')
          return setJobErr(errorMessages)
        }
        setShowJobErr(false)
        setStep(step + 1)
      }
    })
  }

  const postSubmitAction = useCallback(
    async (res, files: CollectedFileMetaData[], input: MakeBookingInput) => {
      const bookings: Booking[] = res?.data?.makeBooking || res?.makeBooking

      if (bookings?.length) {
        const toUpload: any[] = []
        const groupedFilesByLevel = groupBy(files, 'level')

        const addToUpload = (files: File[], input: MutationUploadBookingDocumentArgs) => {
          toUpload.push(handleBookingDocumentUpload(selectedGlobalCompany.uuid, files, input))
        }

        const bookingLvlFiles = sortBy(groupedFilesByLevel['booking'] || [], 'index')
        const jobLvlFiles = sortBy(groupedFilesByLevel['job'] || [], 'index')
        const tripLvlFiles = sortBy(groupedFilesByLevel['trip'] || [], 'index')

        // when Plugin enabled
        if (input?.jobs?.length === bookings.length) {
          for (let b = 0; b < bookings.length; b++) {
            const bk = bookings[b]
            const job = bk.jobs?.[0]
            const trips = job?.trips

            for (const f of bookingLvlFiles) {
              addToUpload(f.files, {
                type: f.key,
                uuid: bk.uuid
              })
            }

            const currentJobFiles = jobLvlFiles.filter(f => f.index === b)

            for (const f of currentJobFiles) {
              addToUpload(f.files, {
                type: f.key,
                uuid: bk.uuid,
                jobUuid: job?.uuid
              })
            }
            const currentJobUuids = currentJobFiles.map(jf => jf.jobUuid)
            const currentTripFiles = tripLvlFiles.filter(f => currentJobUuids.includes(f.jobUuid))

            for (let t = 0; t < currentTripFiles.length; t++) {
              const tf = currentTripFiles[t]
              addToUpload(tf.files, {
                type: tf.key,
                uuid: bk.uuid,
                jobUuid: job?.uuid,
                tripUuid: trips?.[t]?.uuid
              })
            }
          }
        }
        // if Plugin disabled
        else {
          for (const bk of bookings) {
            for (const f of bookingLvlFiles) {
              addToUpload(f.files, {
                type: f.key,
                uuid: bk.uuid
              })
            }
            bk?.jobs?.forEach(job => {
              for (const f of jobLvlFiles) {
                addToUpload(f.files, {
                  type: f.key,
                  uuid: bk.uuid,
                  jobUuid: job?.uuid
                })
              }
              job?.trips?.forEach(trip => {
                for (const f of tripLvlFiles) {
                  addToUpload(f.files, {
                    type: f.key,
                    uuid: bk.uuid,
                    jobUuid: job.uuid,
                    tripUuid: trip?.uuid
                  })
                }
              })
            })
          }
        }

        await Promise.all(toUpload)
      }

      if (bookings.length === 1) {
        return navigate(`/bookings/${bookings[0].no}`)
      }

      setExtraItems([{ title: 'Split Jobs' }])

      setTimeout(() => {
        setStep(prev => prev + 1)
        setMakeBookingResponse(bookings)
      }, 1000)
    },
    [setExtraItems, setMakeBookingResponse, setStep, navigate, selectedGlobalCompany.uuid]
  )

  const handleOverrideBlockDuplicate = e => setIsOverrideBlockDuplicate(e.target.checked)

  const onPluginsNotFound = () => navigate('/bookings')

  const StepButtons = ({ actionButton = false }) => (
    <Flex className="my-2" justify="flex-end" align="center" gap={10}>
      {showJobErr && <CreateBkErr jobErr={jobErr} />}

      {actionButton && <DefaultActions form={form} />}

      {step !== 0 && (
        <Button onClick={onBack}>
          <LeftOutlined />
          Back
        </Button>
      )}

      <Button id="create-booking-stepper-button" type="primary" onClick={checkRequiredFields}>
        Next <RightOutlined />
      </Button>
    </Flex>
  )

  return (
    <Flex vertical style={{ paddingBottom: 50 }}>
      <FormSteps currentStep={step} extraItems={extraItems} />

      <Form>
        <HiddenFieldsSubForm form={form} />

        <VisibilityDiv visible={step === 0}>
          <StepButtons />
          <ShipperConsigneeSubForm form={form} duplicateBookingObject={duplicateBookingObject} />
          <StepButtons actionButton />
        </VisibilityDiv>

        <VisibilityDiv visible={step === 1}>
          <StepButtons />
          <TransportDetailsSubForm form={form} duplicateBookingObject={duplicateBookingObject} />
          <StepButtons actionButton />
        </VisibilityDiv>

        <VisibilityDiv visible={step === 2}>
          {step === 2 && (
            <>
              <ConfirmationSubForm form={form} step={step} />

              <Flex
                gap={5}
                align="center"
                justify="flex-end"
                style={{ marginTop: 80, cursor: 'pointer' }}
                onClick={() => setIsOverrideBlockDuplicate(prev => !prev)}
              >
                Override Duplicate Booking{' '}
                <Checkbox
                  checked={isOverrideBlockDuplicate}
                  value={isOverrideBlockDuplicate}
                  onChange={handleOverrideBlockDuplicate}
                />
              </Flex>

              <Flex className="my-2" justify="flex-end" align="center" gap={10}>
                <Button onClick={onBack}>Back</Button>
                <SubmitAction
                  form={form}
                  postSubmitAction={postSubmitAction}
                  isOverrideBlockDuplicate={isOverrideBlockDuplicate}
                />
              </Flex>
            </>
          )}
        </VisibilityDiv>

        <VisibilityDiv visible={step === 3}>
          {step === 3 && (
            <PluginLoader
              moduleLocations={[PluginComponentLocation.AssetsBookingNewPost]}
              componentProps={{ bookings: makeBookingResponse }}
              onPluginsNotFound={onPluginsNotFound}
            />
          )}
        </VisibilityDiv>
      </Form>
    </Flex>
  )
}

export default Form.create<NewBookingTransportFormProps>()(memo(NewBookingTransportForm))
