import { Input } from 'antd-v5'

import FormItemMapper from 'App/components/Manage/FormItemMapper'
import CompanySelect from 'App/components/Select/TypeToFetch/CompanySelect'

const defaultMessage = 'Field required.'

const BulkInvoiceByCustomerForm = (props: any) => {
  const { item, initialValue, types, handleCompanyChange } = props
  const { getFieldDecorator } = props.form

  const fields = [
    {
      full: true,
      cols: [
        {
          label: 'Company',
          value: 'companyUuid',
          input: (
            <CompanySelect
              types={types || []}
              onChange={(value: any, company: any) => handleCompanyChange(company)}
              name={item?.name}
            />
          ),
          attributes: {
            initialValue: initialValue?.company?.company?.name,
            rules: [
              {
                required: true,
                message: defaultMessage
              }
            ]
          }
        },
        {
          label: 'Filter',
          value: 'filter',
          input: <Input autoComplete="off" />,
          attributes: {
            initialValue: initialValue?.facets
          }
        }
      ]
    }
  ]

  return <FormItemMapper fields={fields} getFieldDecorator={getFieldDecorator} />
}

export default BulkInvoiceByCustomerForm
