import { useEffect } from 'react'
import { Outlet, useLocation } from 'react-router-dom'
import { ErrorBoundary } from '@sentry/react'
import { Flex, Spin } from 'antd-v5'
import { startCase } from 'lodash'

import Header from '@/components/Layouts/header'
import LeftMenu from '@/components/Layouts/Manage/LeftMenu'
import FeatureGuardUrl from '@/components/Shared/FeatureGuard/Url'
import NoAccess from '@/components/Shared/NoAccess'
import ErrorFallback from '@/ErrorFallback'
import base from '@/hocs/base'
import useProcessPortalUser from '@/hooks/useProcessPortalUser'
import useUserStore from '@/store/user'

const ManageLayout = () => {
  const location = useLocation()
  const user = useUserStore.use.loggedInUser()

  const { loading, baseCompanyUuids } = useProcessPortalUser(user || undefined)

  useEffect(() => {
    const title = location.pathname.split('/')?.[2]?.replace('-', ' ')
    document.title = `ShipX ${!title ? '' : '|'}  ${startCase(title)}`
  }, [location])

  return (
    <>
      <Header />
      <Flex justify="center" className="w-full">
        {loading ? (
          <Spin className="flex justify-center items-center max-w-7xl" />
        ) : baseCompanyUuids?.length ? (
          <Flex justify="center" flex={1} className="max-w-7xl">
            <Flex vertical className="scrollbar-none overflow-y-auto h-[95vh]">
              <LeftMenu />
            </Flex>
            <div className="flex-1 overflow-y-auto scrollbar-none h-[95vh]">
              <ErrorBoundary fallback={<ErrorFallback />}>
                <FeatureGuardUrl>
                  <Outlet />
                </FeatureGuardUrl>
              </ErrorBoundary>
            </div>
          </Flex>
        ) : (
          <NoAccess />
        )}
      </Flex>
    </>
  )
}

export default base(ManageLayout)
